import React from "react";
import { useState } from "react";
import { useStyles } from "../styles/DataFormCss";
import "../styles/custom.css";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import { SuccessSnackbar, ErrorSnackbar } from "./Snackbar";
import { Checkbox } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";



const UpdateCard = (props) => {
  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  const { openForm, setOpenForm, getUserData } = props; //To open the form Dialog
  const classes = useStyles(); //For styles
  const [loading, setLoading] = useState(false); //for loading until data is uploaded
  const [users, setUsers] = React.useState([]); //To get all the users
 
  const [openSuccessAlert, setopenSuccessAlert] = React.useState(false);
  const [openErrorAlert, setopenErrorAlert] = React.useState(false);
  const { canvasData } = props;
  const typedata=JSON.parse(localStorage.getItem('static')).Type

 const [customdata,setcustomdata]=useState({});

 const [customformdata,setcustomformdata]=useState([{}])

  // typedata.map((x)=>{

  //   if(x.name === props.loaded.crdType)
  //   {
  //     setcustomformdata(x.customform);
  //   }
  // })
  
  const prioritydata=JSON.parse(localStorage.getItem('static')).Priority

  const [formData, setData] = useState(
    {
  
      crdAssignedUser: props.loaded.crdAssignedUser,
      crdStatus: props.loaded.crdStatus,
    
      crdName: props.loaded.crdName,
      crdDescription: props.loaded.crdDescription,
      crdDueDate: props.loaded.crdDueDate,
      crdBusinessValue: props.loaded.crdBusinessValue,
      crdEmergency: props.loaded.crdEmergency,
      crdPriority: props.loaded.crdPriority,
      crdParentID:props.loaded.crdParentID,
      crdType: props.loaded.crdType,
      crdAcceptanceCriteria: props.loaded.crdAcceptanceCriteria,
      Withdraw: 0,
      crdComment: props.loaded.crdComment,
      crdUpdateSubscriptions:props.loaded.crdUpdateSubscriptions,
      customdata:props.loaded.customdata,
   }
  );

  const clearState = () => {
    setData(
      
      {
  
        crdAssignedUser: "",
        crdStatus: "To-Do",
      
        crdName: "",
        crdDescription: "",
        crdDueDate: "",
        crdBusinessValue: "",
        crdEmergency: 0,
        crdPriority: "",
        crdUpdateSubscriptions:[],
        crdType: "",
        crdAcceptanceCriteria: "",
        Withdraw: 0,
        crdComment: "",


     }
      
    );
  };

  const handleFormClose = () => {
    setOpenForm(false);
    setLoading(false);
    clearState();
  };

  const handleCardTypeChange=(event)=>{

    const target = event.target;
    const value = target.value;
    const name = target.name;

   

    

    setData({ ...formData, [name]: value });






  }

  //Any new changes in form
  const handleChange = (event) => {
    console.log("Before change formdata:-",formData);

    setData({ ...formData, [event.target.name]: event.target.value });
    console.log("This is formdata",formData);
  };

  const handlecustomChange=(event)=>{
             
    setcustomdata({ ...customdata, [event.target.name]: event.target.value });
    


  }

  // const handleAssignedBoardChange = (event) => {
  //   console.log("Before change formdata:-",formData);

  //   setData({ ...formData, [event.target.name]: event.target.value });
  //   console.log("This is formdata",formData);
  // };

  const handleAssignedUserChange=(event)=>{

    const value=event.target.value
    var res;
    users.map((x)=>{

      if(x.usrName === value)
      {
        res=x.usrEmailID;
      }
    })
    setData({ ...formData, [event.target.name]: res });

  }
  // const handleChange1 = (event) => {
  //   formData.CanvasId = event.target.value;
  // };

  //Current Date
  const today = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    //Handles Update Function
    return yyyy + "-" + mm + "-" + dd;
  };

  //Assigns the old data which needs to be updated

  // const assignAll = (loaded) => {
    
  //     //setData({...formData, "Status": props.sts});
  //     console.log(formData);

  //     formData.crdStatus = props.sts;
      
  //     formData.crdAcceptanceCriteria = loaded.crdAcceptanceCriteria;
  //     formData.crdAssignTo = loaded.crdAssignTo;
  //     formData.crdType = loaded.crdType;
  //     formData.crdName = loaded.crdName;
  //     formData.crdDescription = loaded.crdDescription;
  //     formData.crdBusinessValue = loaded.crdBusinessValue;
  //     formData.crdEmergency = loaded.crdEmergency;
  //     formData.crdPriority = loaded.crdPriority;
  //     formData.crdcrdAssignedUser = sessionStorage.getItem("email");
     
  //     formData.crdDueDate = loaded.crdDueDate;
  //     formData.Withdraw = 0;
  //     formData.crdComment = loaded.crdComment;
    
  // };

  const sendmail=(sentdata)=>{


    var data={

      Subject:"Update Notification",
      From:localStorage.getItem("email"),
      To:"",
      CC:[...sentdata.crdUpdateSubscriptions,sentdata.crdAssignedUser,sentdata.crdReporter],
      Content:`Card ${sentdata.DynamicID} updated`,
    }

    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    const options = {
      method: "POST",
      headers: newHeaders,
      body: JSON.stringify(data),
    };

    fetch(baseUrl+"/sendmail",options).then(
      (res)=>{



        res.json().then((data)=>{
          console.log(data);
       
         
        })


      }
    )

      

  }
  const handleUpdate = () => {
    console.log("Res Data", formData);
    formData.customdata=customdata;
    submitUpdates(formData);
  };

  const componentreturn=(data)=>{

    console.log(customdata);
    console.log(customdata[data.label]);

    if(data.cname === "TextField")
   { 

        return (<TextField  label={data.label} name={data.label} defaultValue={customdata[data.label]} onChange={handlecustomChange}></TextField>)
     }
     else if(data.cname=="Dropdown")
     {

      return(<div  >

                   <InputLabel
                       id="select-priot"
                         style={{ marginTop: "10px", width: "100%" }}
                      >
                        {data.label}
                      </InputLabel>
                      <Select
                        style={{ width: "100%" }}
                        name={data.label} 
                        defaultValue={customdata[data.label]}
                        onChange={handlecustomChange}
                      >
                        {data.values.split(",").length > 0 ? (
                                data.values.split(",").map((usr) => (
                                  <MenuItem value={usr}>{usr}</MenuItem>
                                ))
                              ) : (
                                <MenuItem value={"0"}>N/A</MenuItem>
                        )}
      
    </Select>
      
      </div>)

     

       

    }
    else if(data.cname =="TextArea")
    {

    return( <TextField   label={data.label} multiline
      maxRows={4} name={data.label} defaultValue={customdata[data.label]} onChange={handlecustomChange} ></TextField>)




    }
    else if(data.cname == "Checkbox")
    {
       return(<FormGroup><FormControlLabel control={<Checkbox name={data.label} defaultValue={customdata[data.label]} onChange={handlecustomChange} />} label={data.label} /></FormGroup>)
      
    }
    else if(data.cname == "Date")
    {
                     return(    <TextField
              id="date"
              type="date"
              name={data.label} 
              defaultValue={customdata[data.label]}
              onChange={handlecustomChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: `${today()}`,
              }}
            />)


    }
    else
    {
      return(<div>

      </div>)
    }






               


  }


  //Submit the updated Form
  const submitUpdates = (data) => {
    
    setLoading(true);
    const apiURL = baseUrl;
    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    const options = {
      method: "PUT",
      headers: newHeaders,
      body: JSON.stringify(data),
    };
    fetch(apiURL+"/update?Id="+props.loaded.DynamicID, options)
      .then((res) => {
        if (res.status === 200) console.log("Res", res);
        setopenSuccessAlert(true);
        //props.getUserData();

        if (props.open !== undefined) {
          props.setOpen(false);
        }
        sendmail(data);
        props.getData(props.canvasId);
      })

      .catch((error) => {
        setLoading(false);
        console.log(error);
        setopenErrorAlert(true);
      })
      .finally(() => handleFormClose());
  };

  

  //To get All the users
  const getUsers = () => {
    const mainUrl = process.env.REACT_APP_HEROKU_URL;
    fetch(mainUrl + "/user/list")
      .then((response) =>
        response.json().then((data) => {
          console.log(data);
          console.log("Called Users Now");
          data.sort((a,b) => (a.usrName > b.usrName) ? 1 : ((b.usrName > a.usrName) ? -1 : 0))
          console.log(data);
          setUsers(data)
         // getcurrentuserName();
        })
      )
      .catch((err) => console.log(err));
  };

  const getcurrentuserName=()=>{
    var x
    users?.map((usr)=>{
        if(usr.usrEmailID===props.loaded.crdAssignedUser)
        {
          
          x=usr.usrName;

        }

    }
    )
    
    
    return x;
  
  }

  //To set button enable disable
  const isEnabled = () => {
    var obj = formData[0];

    for (var key in obj) {
      console.log("FormData Value", key, obj[key]);
      if (
        obj[key] === "" &&
        (key === "crdName" || key === "crdStatus" || key === "crdEmergency")
      )
        return false;
    }
    return true;
  };

  var flag = isEnabled();
  //console.log("FormData Flag", flag);

  //To run after loading the view
  React.useEffect(() => {
    console.log("Here are the",props);
    getUsers();
    typedata.map((x)=>{

       if(x.name === props.loaded.crdType)
         {
           setcustomformdata(x.customform);
         }
       })

       console.log(props.loaded.customdata);

      setcustomdata(props.loaded.customdata)
      console.log(customformdata);
      console.log(customdata);

    
   
    // formData.crdStatus = "To-Do";
    // formData.crdBusinessValue = 0;
    //  formData.crdEmergency = 0;
  }, []);

  return (
    <div>
      <Dialog
        className={classes.dialogStyle}
        maxWidth="lg"
        open={openForm}
        onClose={handleFormClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle>
          <div className={classes.formTitle}>Edit</div>

          <div className={classes.datePicker}>
            <span style={{ fontSize: "18px" }}>Due Date :</span> &nbsp;
            <TextField
              id="date"
              type="date"
              name="crdDueDate"
              defaultValue={props.loaded.crdDueDate}
              onChange={(e)=>handleChange(e)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: `${today()}`,
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            {props.loaded !== undefined ? (
              <React.Fragment>
                {/* {assignAll(props.loaded)} */}
                <Card className="card-class">
                  <Typography component="h2" variant="h5"></Typography>
                  <CardContent>
                    <form>
                      <div>
                        <div className="Issue">
                          <TextField
                            variant="outlined"
                            margin="normal"
                            disabled
                            id="standard-text-input"
                            label="Issue ID"
                            type="text"
                            name="Issue ID"
                            defaultValue={props.loaded.DynamicID}
                          />
                        </div>
                        {/* <div className="board">
                          <InputLabel style={{ margin: 9 }}>Board</InputLabel>
                          <Select
                            defaultValue={props.canvasId}
                            id="demo-customized-select-native"
                            className={classes.select}
                            name="CanvasId"
                            onChange={handleChange1}
                            style={{ margin: 5 }}
                            value={formData.CanvasId}
                          >
                            {canvasData.length > 0 &&
                              canvasData.map((canvas, index) => (
                                <option key={index} value={canvas.CanvasId}>
                                  {canvas.CanvasName}
                                </option>
                              ))}
                          </Select>
                        </div> */}

                        {props.loaded.crdStatus !== "Done" ? (
                          <div className={classes.status + " sts"}>
                            <InputLabel id="selectop">Status</InputLabel>
                            <Select
                              className={classes.selectOpt}
                              autoFocus
                              name="crdStatus"
                              defaultValue={props.sts}
                             
                              
                              onChange={(e)=>handleChange(e)}
                            >
                              <MenuItem value={"To-Do"}>To-Do</MenuItem>
                              <MenuItem value={"Ready"}>Ready</MenuItem>
                              <MenuItem value={"In-Progress"}>
                                In-Progress
                              </MenuItem>
                              <MenuItem value={"Under Review"}>
                                Under Review
                              </MenuItem>
                              <MenuItem value={"Done"}>Done</MenuItem>
                              {/* <MenuItem value={"Withdrawn"}>Withdrawn</MenuItem> */}
                            </Select>
                          </div>
                        ) : (
                          <div className={classes.status + " sts"}>
                            <InputLabel id="selectop">Status</InputLabel>
                            <Select
                              className={classes.selectOpt}
                              autoFocus
                              name="crdStatus"
                              disabled
                              defaultValue={props.sts}
                              
                              
                              onChange={(e)=>handleChange(e)}
                            >
                              <MenuItem value={"To-Do"}>To-Do</MenuItem>
                              <MenuItem value={"Ready"}>Ready</MenuItem>
                              <MenuItem value={"In-Progress"}>
                                In-Progress
                              </MenuItem>
                              <MenuItem value={"Under Review"}>
                                Under Review
                              </MenuItem>
                              <MenuItem value={"Done"}>Done</MenuItem>
                              {/* <MenuItem value={"Withdrawn"}>Withdrawn</MenuItem> */}
                            </Select>
                          </div>
                        )}

                        <div className="typ">
                          <InputLabel id="select-priot">Type</InputLabel>
                          <Select
                            className={classes.selectOpt}
                            name="crdType"
                            defaultValue={props.loaded.crdType}
                            disabled
                           
                            onChange={(e)=>handleCardTypeChange(e)}
                          >
                            {typedata?.length > 0 ? (
                            typedata?.map((usr) => (
                              <MenuItem value={usr.name}>{usr.name}</MenuItem>
                            ))
                          ) : (
                            <MenuItem value={"0"}>N/A</MenuItem>
                          )}
                          </Select>
                        </div>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="standard-text-input"
                          label="Summary"
                          type="text"
                          name="crdName"
                          defaultValue={props.loaded.crdName}
                          
                          onChange={(e)=>handleChange(e)}
                          multiline
                          rows={2}
                          autoComplete="current-text"
                        />
                        <br></br>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="standard-text-input"
                          label="Description"
                          type="text"
                          name="crdDescription"
                          multiline
                          defaultValue={props.loaded.crdDescription}
                          
                          onChange={(e)=>handleChange(e)}
                          rows={3}
                          autoComplete="current-text"
                        />
                        <br></br>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="standard-text-input"
                          label="Acceptancy Criteria"
                          name="crdAcceptanceCriteria"
                          defaultValue={props.loaded.crdAcceptanceCriteria}
                         
                          onChange={(e)=>handleChange(e)}
                          type="text"
                          multiline
                          rows={2}
                          autoComplete="current-text"
                        />
                        <br></br>

                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="standard-text-input"
                          label="Comments"
                          name="crdComment"
                          defaultValue={props.loaded.crdComment}
                          
                          onChange={(e)=>handleChange(e)}
                          type="text"
                          multiline
                          rows={3}
                          autoComplete="current-text"
                        />
                        <br></br>

                        <div className={classes.formTitle}>
                          <b>Default Section</b>
                        </div>
                        <br></br>
                        <br></br>
                        <div>
                          <div className={classes.emergency}>
                            <InputLabel id="select-emert">Emergency</InputLabel>
                            <Select
                              className={classes.selectOpt}
                              name="crdEmergency"
                              defaultValue={props.loaded.crdEmergency}
                              
                              onChange={(e)=>handleChange(e)}
                              id="emergency"
                            >
                              <MenuItem value={0}>0</MenuItem>
                              <MenuItem value={1}>1</MenuItem>
                            </Select>
                          </div>
                          <div className="prt">
                            <InputLabel id="select-priot">Priority</InputLabel>
                            <Select
                              className={classes.selectOpt}
                              name="crdPriority"
                              defaultValue={props.loaded.crdPriority}
                              
                              onChange={handleChange}
                            >
                               <MenuItem value={"High"}>High</MenuItem>
                              <MenuItem value={"Medium"}>Medium</MenuItem>
                              <MenuItem value={"Low"}>Low</MenuItem> 

                                {/* {priority.map((usr) => (
                                  <MenuItem value={usr}>
                                    {usr}
                                  </MenuItem>
                                ))
                               } */}
                            </Select>
                          </div>
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <div>
                          <div className={classes.value}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              label="Estimation"
                              type="Number"
                              name="crdBusinessValue"
                              defaultValue={props.loaded.crdBusinessValue}
                              
                              onChange={handleChange}
                            />
                          </div>
                          <div className={classes.assignTo}>
                            {/* <InputLabel
                              id="select-priot"
                              style={{ marginTop: "10px", width: "100%" }}
                            >
                              Assigned To
                            </InputLabel> */}
                            {/* <Select
                             
                              autoWidth
                              className={classes.selectOpt}

                              name="crdAssignedUser"
                             
                              value={formData.crdAssignedUser}
                              defaultValue={props.loaded.crdAssignedUser}
                              onChange={handleAssignedUserChange}
                              

                              required
                            >
                             
                             
                            </Select> */}
                            <InputLabel id="select-priot">Assigned To</InputLabel>
                            <Select
                              style={{ width: "100%" }}
                              name="crdAssignedUser"
                              
                              onChange={handleAssignedUserChange}
                              defaultValue={getcurrentuserName}
                              required
                            >
                              {users.length > 0 ? (
                                users.map((usr) => (
                                  <MenuItem value={usr.usrName}>
                                    {usr.usrName}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value={"0"}>0</MenuItem>
                              )}
                            </Select>
                            <InputLabel id="select-priot" style={{top:10}}>Assign Board</InputLabel>
                            <Select
                              style={{ width: "100%",top:10 }}
                              name="crdParentID"
                              
                              onChange={handleChange}
                             
                              required
                            >
                              {canvasData.length > 0 ? (
                                canvasData.map((can) => (
                                  <MenuItem value={can.DynamicID}>
                                    {can.crdName}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value={"0"}>0</MenuItem>
                              )}
                            </Select>
                          </div>
                        </div>

                        <br></br>
                      </div>
                    </form>
                  </CardContent>
                </Card>
              </React.Fragment>
            ) : (
              <span></span>
            )}
          </div>
          {/* <form>
                  {
                    customformdata?.map(

                      (data)=>{

                        if(data.cname === "TextField")
                        {

                            <TextField label={data.label}></TextField>
                        }
                        else if(data.cname=="Dropdown")
                        {

                          <>

                                        <InputLabel
                                            id="select-priot"
                                            style={{ marginTop: "10px", width: "100%" }}
                                          >
                                            {data.label}
                                          </InputLabel>
                                          <Select
                                            style={{ width: "100%" }}
                                           
                                           
                                          >
                                            {data.values.split(",").length > 0 ? (
                                                    data.values.split(",").map((usr) => (
                                                      <MenuItem value={usr}>{usr}</MenuItem>
                                                    ))
                                                  ) : (
                                                    <MenuItem value={"0"}>N/A</MenuItem>
                                            )}
                          
                        </Select>
                          
                          </>

                         

                           

                        }
                        else if(data.cname =="TextArea")
                        {

                          <TextField label={data.label} multiline
                          maxRows={4} ></TextField>




                        }
                        else if(data.cname == "Checkbox")
                        {

                          <Checkbox label={data.label} />
                        }
                        else if(data.cname == "Date")
                        {
                                              <TextField
                                  id="date"
                                  type="date"
                                 
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    min: `${today()}`,
                                  }}
                                />


                        }




                      }
                    )




                  }


                </form> */}

                 <form>
                  <div>


                  
                  {
                    customformdata?.length > 0?(customformdata?.map(

                      (data,index)=>{

                       {

                         return( <div key={index}>
                            {componentreturn(data)}
                          </div>)
                     
                       }
                        

                      }
                    )):
                    (<div>

                    </div>)

                    // customformdata.length > 0 && {
                    //   returncomponent
                    // }




                  }
                </div>

                </form>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="Primary"
            //disabled={flag === false}
            onClick={handleUpdate}
          >
            {loading === true ? (
              <CircularProgress color="secondary" />
            ) : (
              <span>Update</span>
            )}
          </Button>
          &nbsp;
          <Button
            onClick={handleFormClose}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessSnackbar
        openSuccessAlert={openSuccessAlert}
        setopenSuccessAlert={setopenSuccessAlert}
        message="Card Added Successfully!"
        horizontal="top"
        vertical="center"
      />
      <ErrorSnackbar
        openErrorAlert={openErrorAlert}
        setopenErrorAlert={setopenErrorAlert}
        horizontal="top"
        vertical="center"
      />
    </div>
  );
};

export default UpdateCard;
