import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { v1 as uuid } from "uuid";
//MUI Stuffs
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles, useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grow from "@material-ui/core/Grow";
import Header from "./Header";
import AddCanvas from "./AddCanvas";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import { AppBar, Toolbar } from "@material-ui/core";
import { useStyles } from "../styles/MainBoardCss";
import Board from "../../assets/images/Board.png";
import omnivuelogo from "../../assets/images/omnivuelogo.jpg";
import { LocalGasStation } from "@material-ui/icons";

function MainBoard() {
  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  const [loading, setLoading] = React.useState(true);
  const [isData, setisData] = React.useState(false);

  const email = localStorage.getItem("email");
  const project_name = localStorage.getItem("PROJECT_NAME");

  const [canvasData, setCanvasData] = React.useState([]);
  const classes = useStyles();
  // Getting user data as soon as component is rendered
  React.useEffect(() => {
    fetch(baseUrl + "/static").then((res) => {
      res.json().then((data) => {
        console.log("static data :- ", data);

        localStorage.setItem("static", JSON.stringify(data));
        console.log(
          "Static data for adding:-",
          JSON.parse(localStorage.getItem("static"))
        );
      });
    });

    getUserData();
  }, []);

  const theme = useTheme();

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  // Getting User Data
  const getUserData = () => {
    const email = localStorage.getItem("email");
    let projectid = window.localStorage.getItem("PROJECT_ID");
    console.log(email);

    fetch(baseUrl + "/getallboard?id=" + email + "&prjId=" + projectid).then(
      (res) => {
        console.log(res);
        res.json().then((data) => {
          console.log(data);
          setCanvasData(data);
          setisData(true);
          setLoading(false);
        });
      }
    );
  };

  $(document).ready(function () {
    $("#search").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      $("#dataTable #tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
    });
  });

  return (
    <>
      <Header x="Boards" />
      <AppBar onClick={() => window.scroll(0,0)} position="sticky" className={classes.appbar}>
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}

          <Typography className={classes.title} variant="h6" noWrap>
            Boards
            <div>
              <p style={{ color: "red", fontSize: 12 }}>{project_name}</p>
            </div>
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              id="search"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div>
            <img src={omnivuelogo} className={classes.omni} />
          </div>
        </Toolbar>
      </AppBar>
      {loading ? (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            marginLeft: "-50px",
            marginTop: "-50px",
            padding: theme.spacing(1, 9),
          }}
        >
          <CircularProgress size={100} />
          <h5 style={{ fontSize: 20 }}> Loading..... </h5>
        </div>
      ) : (
        <>
          {!isData ? (
            <div>
              <h2 style={{ position: "absolute", top: "45%", left: "35%" }}>
                {" "}
                No Board Found! Create one.....
                <br />
                <AddCanvas parentId={0} />
              </h2>{" "}
            </div>
          ) : (
            <>
              <div style={{paddingLeft: 65}}>
                <Grid
                  id="dataTable"
                  container spacing={10}

                  className={classes.root}
                  
                >
                  {canvasData?.map(
                    (canvas) =>
                      canvas.crdParentID === "0" && (
                        <Grow id="tr" in={true} timeout={1200}>
                          <Grid style={{paddingBottom: 240, paddingRight: 150}} item xs={6} md={4}>
                            <div  className={classes.boardCard}>
                              {/* <Avatar className={classes.type}>
                                {/* <LightTooltip
                                  title={
                                    <p style={{ fontSize: 15 }}> {"Board"} </p>
                                  }
                                  placement="top-start"
                                >
                                  <img
                                    src={Board}
                                    style={{ height: "300px", width: "30px" }}
                                  />
                                </LightTooltip> */}
                              {/* </Avatar> */} 
                              <Typography
                                align="center"
                                variant="h6"
                                style={{
                                  marginTop: "30%",
                                  fontWeight: "bold",
                                  marginRight:"5%",
                                  fontSize: "25px",
                                  lineHeight: "36px",
                                  fontStyle: "normal",
                                  fontFamily: "Microsoft Sans Serif",
                                }}
                              >
                                {console.log("*************CanvasData",canvasData)}
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                    
                                  }}
                                  to={{
                                    pathname: `/board/${canvas.DynamicID}`,
                                    state: {
                                      canvases: canvasData,
                                    },
                                    key: uuid(),
                                  }}
                                >
                                  {canvas.crdName}{" "}
                                </Link>
                              </Typography>
                            </div>
                          </Grid>
                        </Grow>
                      )
                  )}
                </Grid>
              </div>
            </>
          )}
        </>
      )}
      )
    </>
  );
}

export default MainBoard;
