import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import "../styles/list.css";
import { useStyles } from "../styles/listCss";

const OverdueCard = () => {
  const [data, setData] = useState([]);
  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  const id = localStorage.getItem("PROJECT_ID");
  const classes = useStyles();
  // try {
  //     fetch(baseUrl + "/overdueProjects?prjId="+id).then((res) => res.json()).then(json => {
  //         setData({
  //             DynamicID:json[0].DynamicID,
  //             crdDueDate:json[0].crdDueDate,
  //             crdName:json[0].crdName,
  //             crdStatus:json[0].crdStatus
  //         })
  //     })
  // } catch (error) {
  //     console.log(error);
  // }

  // useEffect =
  //   (() => {
  //     const fetchData = async () => {
  //       const result = fetch(baseUrl + "/overdueProjects?prjId=" + id);
  //       console.log(result);
  //     };
  //     fetchData();
  //   },
  //   []);

  useEffect(() => {
    const fetchData = () => {
      fetch(baseUrl + "/overdueProjects?prjId=" + id)
        .then((res) => res.json())
        .then((json) => {
          setData(json);
          console.log(json);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchData();
  }, []);

  return (
    <div>
      <div>
        <h1 style={{paddingLeft:'5vw'}}>Overdue Cards are</h1>
      </div>
      {/* {
        data.map((val) => (
          <div>
            <div>{val.DynamicID}</div>
            <div>{val.crdDueDate}</div>
            <div>{val.crdAssignedUser}</div>
          </div>
        ))
      } */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ backgroundColor: "#e5f0ef" }}
                align="center"
                classes={{ root: classes.tableCell }}
              >
                Id
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#e5f0ef" }}
                align="center"
                classes={{ root: classes.tableCell }}
              >
                DueDate
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#e5f0ef" }}
                align="center"
                classes={{ root: classes.tableCell }}
              >
                Card Name
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#e5f0ef" }}
                align="center"
                classes={{ root: classes.tableCell }}
              >
                Status
              </TableCell>
              <TableCell
                style={{ backgroundColor: "#e5f0ef" }}
                align="center"
                classes={{ root: classes.tableCell }}
              >
                AssignedUser
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))} */}
            {data.map((val) => (
              <TableRow
                key={val.DynamicID}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className={classes.tableCell} align="center">
                  <p className="summary">{val.DynamicID}</p>
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <p className="summary">{val.crdDueDate}</p>
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <p className="summary">{val.crdName}</p>
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <p className="summary">{val.crdStatus}</p>
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <p className="summary">{val.crdAssignedUser}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OverdueCard;
