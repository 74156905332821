import React from "react";
import { useDrop } from "react-dnd";
import clsx from "clsx";
import { ErrorSnackbar } from "./Snackbar";
//MUI Stuffs
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import CanvasCard from "./CanvasCard";
import useLocalStorage from "react-use-localstorage";
import { useStyles } from "../styles/CanvasCss";
import { ItemTypes } from "../../utils/Items";
import { CardContext } from "../views/Canvas";
import "../styles/common.css";
function CanvasTable(props) {
  const [openErrorAlert, setopenErrorAlert] = React.useState(false);

  const status = JSON.parse(localStorage.getItem("static")).Status;

  React.useEffect(() => {
    console.log("We are in the table");
    console.log(props);
  });
  const classes = useStyles();
  const { CanvasId, targetId } = props;
  const { searchs, filterData } = props;
  const { canvasData } = props;
  const { getData } = props;
  const [ready, setReady] = useLocalStorage("ready");
  const [progress, setProgress] = useLocalStorage("progress");
  const [review, setReview] = useLocalStorage("review");

  // Getting markAsDone function of Canvas.js
  const { markAsDone } = React.useContext(CardContext);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  // Function taken from React DND documentation to perform operations after dropping card
  // const [{ isOver }, drop] = useDrop({
  //   accept: ItemTypes.CARD,
  //   drop: (item, monitor) => {
  //     if (item.LaneId != targetId)
  //       // Checking if the dragged lane and dropped lane is not same
  //       markAsDone(item.Card, item.col, item.CanvasId, targetId);
  //     //console.log(monitor);
  //   },
  //   collect: (monitor) => ({
  //     isOver: !!monitor.isOver(), // Monitoring whether card is dropped or in dragging state
  //   }),
  // });
  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "card",
    drop: (item) => onDrop(item.Card),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  const onDrop = (id) => {
    // console.log("*****************************"+id.DynamicID);
    // console.log("*****************************"+targetId);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${baseUrl}/dragStatusNext?id=${id.DynamicID}&targetId=${targetId}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        result === "error" ? setopenErrorAlert(true) : getData(CanvasId);
      })
      .catch((error) => console.log("error", error));
  };
  var color = isOver ? "#fff" : "#f0f2f5";
  var emergency = process.env.REACT_APP_EMERGENCY_LANE; // Getting emergency lane value from env file

  const disable = () => {
    document.getElementById("ready").disabled = true;
  };
  const enable = () => {
    document.getElementById("ready").disabled = false;
  };
  const validateReady = (e) => {
    var wipReady = document.getElementById("ready").value;
    if (wipReady < 0) {
      alert("Invalid value for limit");
    } else if (props.col.Cards.length > wipReady) {
      alert("WIP limit exceeded");
    }
    e.preventDefault();
  };
  const validateProgress = (e) => {
    var wipProgress = document.getElementById("progress").value;

    if (wipProgress < 0) {
      alert("Invalid value for limit");
    } else if (props.col.Cards.length > wipProgress) {
      alert("WIP limit exceeded");
    }
    e.preventDefault();
  };

  const validateReview = (e) => {
    var wipReview = document.getElementById("review").value;
    if (wipReview < 0) {
      alert("Invalid value for limit");
    } else if (props.col.Cards.length > wipReview) {
      alert("WIP limit exceeded");
    }
    e.preventDefault();
  };

  const validatelimit = (e) => {
    var wip = document.getElementById("limit").value;
    if (wip < 0) {
      alert("Invalid value for limit");
    } else if (props.col.Cards.length > wip) {
      alert("WIP limit exceeded");
    }
    e.preventDefault();
  };

  return (
    <div style={{ height: "100%" }}>
      <Table
        className={classes.table}
        ref={drop}
        style={{
          backgroundColor:
            (props.col.Status === "Ready" && props.col.TotalRowCount > ready) ||
            (props.col.Status === "In-Progress" &&
              props.col.TotalRowCount > progress) ||
            (props.col.Status === "Under Review" &&
              props.col.TotalRowCount > review)
              ? "#f7f4f7"
              : color,
          marginLeft: 10,
          width: "100%",
          scrollX: "auto",
          // height:"450px",
          borderRadius: "40px",
          scrollY: "auto",
          // border:props.col.Status==="Ready" && props.col.TotalRowCount > ready || props.col.Status==="In-Progress" && props.col.TotalRowCount > progress || props.col.Status==="Under Review" && props.col.TotalRowCount > review  ? "1px solid #faa167": "",
        }}
      >
        <TableRow>
          <TableCell align="center" className={classes.headerCell}>
            {props.col.Status}
            <IconButton
              title="More.."
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
            >
              <ExpandMoreIcon />
            </IconButton>
            {/* {props.col.Status==="Ready" ? (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
          <p className="expandtext">Rows:{props.col.TotalRowCount}</p>
          <br></br>
         
          <div>
        <label>
          WIP:{" "}
         
          <input
          style={{borderRadius:"5px",border:"1px solid  #c7dfe5"}}
            type="number"
            id="ready"
            placeholder="Limits for ready"
            value={ready}
            onClick={validateReady}
            onChange={e => setReady(e.target.value) }
          />
        </label>
        <input type="reset" className="btn" style={{width:"120px",height:"40px",fontSize:"13px"}} value="Set Default" onClick={e => setReady('10')}></input>

      </div>
        </Collapse>
        ):props.col.Status==="In-Progress" ? (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
          <p className="expandtext">Rows:{props.col.TotalRowCount}</p>
          <br></br>
          <div>
        <label>
          WIP:{" "}
          <input
          style={{borderRadius:"5px",border:"1px solid  #c7dfe5"}}
            type="number"
            id="progress"
            placeholder="limits for progress"
            value={progress}
            onClick={validateProgress}
            onChange={e => setProgress(e.target.value)}
          />
        </label>
        <input type="reset" value="Set Default" className="btn" style={{width:"120px",height:"40px",fontSize:"13px"}} onClick={e => setProgress('10')}></input>
      </div>
        </Collapse>
        ):props.col.Status==="Under Review" ?(
          <Collapse in={expanded} timeout="auto" unmountOnExit>
          <p className="expandtext">Rows:{props.col.TotalRowCount}</p>
          <br></br>
          <div>
        <label>
          WIP:{" "}
          <input
           style={{borderRadius:"5px",border:"1px solid  #c7dfe5"}}
            type="number"
            id="review"
            placeholder="Limits for review"
            value={review}
            onClick={validateReview}
            onChange={e => setReview(e.target.value)}
          />
        </label>
        <input type="reset" value="Set Default" className="btn" style={{width:"120px",height:"40px",fontSize:"13px"}} onClick={e => setReview('10')}></input>
      </div>
        </Collapse>
        ):(
          <Collapse in={expanded} timeout="auto" unmountOnExit>
          <p className="expandtext">Rows:{props.col.TotalRowCount}</p>
          <br></br>
         
        
        </Collapse>
        )} */}

            {
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <p className="expandtext">Rows:{props.col.TotalRowCount}</p>
                <br></br>
                <div>
                  <label>
                    WIP:{" "}
                    <input
                      style={{
                        borderRadius: "5px",
                        border: "1px solid  #c7dfe5",
                      }}
                      type="number"
                      id="limit"
                      placeholder="Limits for the column"
                      value={review}
                      onClick={validatelimit}
                      onChange={(e) => setReview(e.target.value)}
                    />
                  </label>
                  <input
                    type="reset"
                    value="Set Default"
                    className="btn"
                    style={{ width: "120px", height: "40px", fontSize: "13px" }}
                    onClick={(e) => setReview("10")}
                  ></input>
                </div>
              </Collapse>
            }
          </TableCell>
        </TableRow>
        {/* Showing Emergency Cards */}
        {emergency && props.col.Cards.length !== 0 && (
          props.col.Cards.filter((data) => {
            if (searchs.search == "" && filterData.length == 0) {
              return;
            }
            if (searchs.search !== "") {
              if (
                data.crdAssignedUser
                  .toLowerCase()
                  .includes(searchs.search.toLowerCase()) ||
                data.crdDescription
                  .toLowerCase()
                  .includes(searchs.search.toLowerCase()) ||
                data.crdPriority
                  .toLowerCase()
                  .includes(searchs.search.toLowerCase()) ||
                data.crdName
                  .toLowerCase()
                  .includes(searchs.search.toLowerCase()) ||
                data.crdDueDate
                  .toLowerCase()
                  .includes(searchs.search.toLowerCase()) ||
                data.crdType
                  .toLowerCase()
                  .includes(searchs.search.toLowerCase()) ||
                data.crdAcceptanceCriteria
                  .toLowerCase()
                  .includes(searchs.search.toLowerCase()) ||
                data.crdComment
                  .toLowerCase()
                  .includes(searchs.search.toLowerCase())
              ) {
                console.log(data);
                return data;
              }
            } else if (filterData.length !== 0) {
              if (filterData.length == 1) {
                if (data.crdType == filterData[0] || data.Type == "") {
                  return data;
                }
              }
              if (filterData.length == 2) {
                if (
                  data.crdType == filterData[0] ||
                  data.crdType == filterData[1] ||
                  data.crdType == ""
                ) {
                  return data;
                }
              }
              if (filterData.length == 3) {
                if (
                  data.crdType == filterData[0] ||
                  data.crdType == filterData[1] ||
                  data.crdType == filterData[2] ||
                  data.crdType == ""
                ) {
                  return data;
                }
              }
              if (filterData.length == 4) {
                if (
                  data.crdType == filterData[0] ||
                  data.crdType == filterData[1] ||
                  data.crdType == filterData[2] ||
                  data.crdType == filterData[3] ||
                  data.crdType == ""
                ) {
                  return data;
                }
              }
              if (filterData.length >= 5) {
                if (
                  data.crdType == filterData[0] ||
                  data.crdType == filterData[1] ||
                  data.crdType == filterData[2] ||
                  data.crdType == filterData[3] ||
                  data.crdType == filterData[4] ||
                  data.crdType == ""
                ) {
                  return data;
                }
              }
            }
          }).map((card, index) =>
            card.crdEmergency ? (
              <TableRow>
                <TableCell
                  key={index}
                  height="150px"
                  align="center"
                  className={classes.tableCell}
                > 
                  {/* Calling Canvas Card to generate a card of the details and show it in lanes */}
                  <CanvasCard
                    check={props.check}
                    key={index}
                    card={card}
                    col={props.col}
                    getData={props.getData}
                    refreshCanvas={props.refreshCanvas}
                    board={props.board}
                    CanvasId={CanvasId}
                    canvasData={canvasData}
                  />
                </TableCell>
              </TableRow>
            ) : (
              ""
            )
          ) 
         )} 

        {/* Showing Normal Cards */}
        <TableRow>
          <TableCell
            // key={index}
            align="center"
            className={classes.tableCell}
            style={{ height: 320 }}
          >
            {props.col.Cards.length !== 0
              ? props.col.Cards.filter((data) => {
                  if (searchs.search == "" && filterData.length == 0) {
                    return;
                  }
                  if (searchs.search !== "") {
                    if (
                      data.crdAssignedUser
                        .toLowerCase()
                        .includes(searchs.search.toLowerCase()) ||
                      data.crdDescription
                        .toLowerCase()
                        .includes(searchs.search.toLowerCase()) ||
                      data.crdPriority
                        .toLowerCase()
                        .includes(searchs.search.toLowerCase()) ||
                      data.crdSummary
                        .toLowerCase()
                        .includes(searchs.search.toLowerCase()) ||
                      data.crdDueDate
                        .toLowerCase()
                        .includes(searchs.search.toLowerCase()) ||
                      data.crdType
                        .toLowerCase()
                        .includes(searchs.search.toLowerCase()) ||
                      data.crdAcceptanceCriteria
                        .toLowerCase()
                        .includes(searchs.search.toLowerCase()) ||
                      data.crdComment
                        .toLowerCase()
                        .includes(searchs.search.toLowerCase())
                    ) {
                      return data;
                    }
                  } else if (filterData.length !== 0) {
                    if (filterData.length == 1) {
                      if (data.crdType == filterData[0] || data.crdType == "") {
                        return data;
                      }
                    }
                    if (filterData.length == 2) {
                      if (
                        data.crdType == filterData[0] ||
                        data.crdType == filterData[1] ||
                        data.crdType == ""
                      ) {
                        return data;
                      }
                    }
                    if (filterData.length == 3) {
                      if (
                        data.crdType == filterData[0] ||
                        data.crdType == filterData[1] ||
                        data.crdType == filterData[2] ||
                        data.crdType == ""
                      ) {
                        return data;
                      }
                    }
                    if (filterData.length == 4) {
                      if (
                        data.crdType == filterData[0] ||
                        data.crdType == filterData[1] ||
                        data.crdType == filterData[2] ||
                        data.crdType == filterData[3] ||
                        data.crdType == ""
                      ) {
                        return data;
                      }
                    }
                    if (filterData.length >= 5) {
                      if (
                        data.crdType == filterData[0] ||
                        data.crdType == filterData[1] ||
                        data.crdType == filterData[2] ||
                        data.crdType == filterData[3] ||
                        data.crdType == filterData[4] ||
                        data.crdType == ""
                      ) {
                        return data;
                      }
                    }
                  }
                }).map((card, index) =>
                  !card.crdEmergency ? (
                    <CanvasCard
                      check={props.check}
                      key={index}
                      card={card}
                      col={props.col}
                      getData={props.getData}
                      refreshCanvas={props.refreshCanvas}
                      board={props.board}
                      CanvasId={CanvasId}
                      canvasData={canvasData}
                    />
                  ) : (
                    ""
                  )
                )
              : ""}
          </TableCell>
        </TableRow>
      </Table>
      <ErrorSnackbar
        openErrorAlert={openErrorAlert}
        setopenErrorAlert={setopenErrorAlert}
        horizontal="top"
        vertical="center"
      />
    </div>
  );
}

export default CanvasTable;
