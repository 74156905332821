import React from "react";
import { Button } from "@material-ui/core";
import Parsing from "./Parsing";
import Header from "./Header";
import { useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import DehazeIcon from "@material-ui/icons/Dehaze";
import Menu from "@material-ui/core/Menu";
import { AppBar, Toolbar, InputBase } from "@material-ui/core";
import Users from "./Admin/Users";
import Settings from "./Admin/Settings";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { useStyles } from "../styles/CardCss";
import Canvases from "./Admin/Canvases";
import omnivuelogo from "../../assets/images/omnivuelogo.jpg";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Proj from "./ProjSettings";

//import ShowCards from "./Admin/ShowCards";
import $ from "jquery";
// Creating Tab Panel
import "../styles/custom.css";
import RuleBaseSettings from "./Admin/RuleBaseSettings";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Admin() {
  // Setting up some states to be used
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [users, setUsers] = React.useState([]);
  const [cards, setCards] = React.useState([]);
  const [canvas, setCanvas] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [open, setOpen] = React.useState(false);

  const handleClickToOpen = () => {
    setOpen(true);
  };

  const handleToClose = () => {
    setOpen(false);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Getting All Users
  const getAllUsers = () => {
    const apiURL = process.env.REACT_APP_HEROKU_URL;
    fetch(apiURL + "/user/list")
      .then((response) =>
        response.json().then((data) => {
          console.log("User", data);
          setUsers(data);
        })
      )
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  // Getting All Canvas
  const getAllCanvas = () => {
    const apiURL = process.env.REACT_APP_HEROKU_URL;
    //fetch( apiURL + "admin/getallboard")
    // .then((response) =>
    //    response.json().then((data) => {
    //      console.log("******************", data);
    //      setCanvas(data);
    //    })
    //  )
    //  .catch((err) => console.log(err));
    // };
    const email = localStorage.getItem("email");
    let projectid = window.localStorage.getItem("PROJECT_ID");
    console.log(email);

    fetch(apiURL + "/getallboard?id=" + email + "&prjId=" + projectid).then(
      (response) => {
        console.log(response);
        response.json().then((data) => {
          console.log("*******************" + data);
          setCanvas(data);
        });
      }
    );
  };

  // Calling functionas as soon as render happens
  React.useEffect(() => {
    console.log("In Admin part");
    getAllUsers();
    //getAllCards();
    getAllCanvas();
  }, []);

  $(document).ready(function () {
    $("#search").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      $("#sortTable #tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
    });
  });

  const project_name = localStorage.getItem("PROJECT_NAME");

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Tabs
        style={{ marginLeft: "30px", color: "black" }}
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label="Users" />

        <Tab label="Boards" />
        <Tab label="Settings" />
        {/* <Tab label="Project Settings" /> */}
      </Tabs>
    </Menu>
  );
  return (
    <>
      <Header />
      <AppBar container position="static" className={classes.appbar}>
        <Toolbar>
          <div>
            <p style={{ color: "red", fontSize: 20 }}>
              {"AA."} {project_name}
            </p>
          </div>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              id="search"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              disabled
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div className={classes.grow} />
          <div>
            <img src={omnivuelogo} className={classes.omni} />
          </div>
          <Button onClick={handleClickToOpen}>Import</Button>
          <Dialog open={open} onClose={handleToClose}>
            <DialogTitle>{"Import Csv"}</DialogTitle>
            <DialogContent>
              <Parsing />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleToClose} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <div className={classes.sectionDesktop}>
            <Tabs
              style={{ marginLeft: "30px", color: "black" }}
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Users" />
              <Tab label="Boards" />
              <Tab label="Project Settings" />
              <Tab label="Rule Settings" />
              {/* <Tab label="Project Settings" /> */}
            </Tabs>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              classname={classes.iconhidden}
              color="primary"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <DehazeIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}

      <div className="formControl"></div>
      <div style={{ marginLeft: "30px" }}>
        {!loading ? (
          <>
            <TabPanel value={value} index={0}>
              <Users
                style={{ padding: "theme.spacing(2,8)" }}
                users={users}
                setUsers={setUsers}
                canvas={canvas}
                getAllUsers={getAllUsers}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Canvases
                canvas={canvas}
                setCanvas={setCanvas}
                getAllCanvas={getAllCanvas}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Proj />
              <hr
                style={{
                  width: 750,
                  background: "black",
                  color: "black",
                  marginLeft: 45,
                  borderColor: "black",
                  height: 0.1,
                }}
              />
              <Box ml={30} pt={2} >
                <h3 style={{ paddingLeft: 10 }}>Project Theme</h3>
                <Settings></Settings>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Box pt={1} sx={{ mx: "auto", width: 249 }}>
                <h3 style={{ paddingLeft: 10 }}>Rule Settings</h3>
                <br></br>
              </Box>
              <div style={{ paddingLeft: "2vw" }}>
                <h3>Select a rule</h3>
                <div style={{paddingLeft: "4vw"}}>
                  <div style={{ borderStyle: "solid" }}>
                    <RuleBaseSettings></RuleBaseSettings>
                  </div>
                </div>
              </div>
            </TabPanel>
          </>
        ) : (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              marginLeft: "-50px",
              marginTop: "-50px",
            }}
          >
            <CircularProgress size={100} />
            <h5 style={{ fontSize: 20 }}> Loading..... </h5>
          </div>
        )}
      </div>
    </>
  );
}
