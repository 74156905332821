// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import omnivuelogo from "../../assets/images/omnivuelogo.jpg";
import { ResponsivePie } from "@nivo/pie";
import Header from "./Header";
import { useState, useEffect } from "react";
import Zoom from "@material-ui/core/Zoom";
import { Typography, AppBar, Toolbar } from "@material-ui/core";
import IconButton from '@mui/material/IconButton';
import Badge from "react-bootstrap/Badge";
import { ResponsiveStream } from "@nivo/stream";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import { useStyles } from "../styles/DashBoardCss.js";
import Dashboard from "../styles/Dashboard.css";
import SearchIcon from "@material-ui/icons/Search";
import { InputBase } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import RefreshIcon from '@mui/icons-material/Refresh';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Button from '@mui/material/Button';


import Bar_chart from "./All_charts/Barchart"
import StackBar from "./All_charts/StackBar"
import Pie_Chart from "./All_charts/PieChart"
import Streamchart from "./All_charts/Streamchart"
import CycleTime from "./All_charts/CycleTime"
import Realdashboard from "./All_charts/Dashboard/Realdashboard"

import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MyResponsivePie = (props) => {
  //const MyResponsivePie = ({ data /* see data tab */ }) => (
  const classes = useStyles();

  const project_name = localStorage.getItem("PROJECT_NAME");

  const [show_visualization, set_show_visualization] = useState("dashboard");
  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  const [boardnames, setboardNames] = useState([]);
  const [selectedboardName, setselectedboardName] = useState('');

  let usremail = window.localStorage.getItem("email");
  let projectid = window.localStorage.getItem("PROJECT_ID");


  const handleAlignment = (event, new_visual) => {
    set_show_visualization(new_visual);
  };

  const getBoardName = () => {
    // http://127.0.0.1:8000/dashboard?email=jagtapdevkinandan50@gmail.com&prjId=1
    fetch(baseUrl + "/dashboard?email=" + usremail + "&prjId=" + projectid)
      .then((response) =>
        response.json().then((data) => {
          data.unshift("All Board");
          setboardNames(data)
        })
      )
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    getBoardName();
  }, [0])

  function displayData(item) {
    return <MenuItem value={item} onClick={(e) => setselectedboardName(e.target.outerText)} >{item} </MenuItem>
  }

  return (
    <div className="wrapper">
      <div>
        <Header x="Dashboard" />
      </div>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}

          <Typography className={classes.title} variant="h6" noWrap>
            Project Dashboard
            <div>
              <p style={{ color: "red", fontSize: 12 }}>{project_name}</p>
            </div>
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            {/* <InputBase
              placeholder="Search…"
              id="search"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            /> */}
          </div>
          <div>
            <img src={omnivuelogo} className={classes.omni} />
          </div>
        </Toolbar>
      </AppBar>
      <div className="dash">
        <div style={{ margin: "3rem" }}>
          <div className="d-flex justify-content-between">
            <h2>
              <Button variant="outlined" onClick={() => { window.location.reload() }}>
                <IconButton >
                  <RefreshIcon color="primary" size="large" />
                </IconButton>
              </Button>


              {/* <ToggleButtonGroup
              color="primary"
              value={show_visualization}
              exclusive
              onChange={handleAlignment}
              aria-label="Platform"
            >
              <ToggleButton value="dashboard" aria-label="list" color="primary" size="large" style={{ margin: "5px 5px 5px 5px" }}>
                <DashboardCustomizeIcon />
              </ToggleButton>
              <ToggleButton value="piechart" aria-label="list" color="primary" size="large" style={{ margin: "5px 5px 5px 5px" }}>
                <PieChartIcon />
              </ToggleButton>
              <ToggleButton value="barchart" aria-label="list" color="primary" size="large" style={{ margin: "5px 5px 5px 5px" }}>
                <BarChartIcon />
              </ToggleButton>
              <ToggleButton value="stackedbarchart" aria-label="list" color="primary" size="large" style={{ margin: "5px 5px 5px 5px" }}>
                <StackedBarChartIcon />
              </ToggleButton>
              <ToggleButton value="streamchart" aria-label="list" color="primary" size="large" style={{ margin: "5px 5px 5px 5px" }}>
                <StackedBarChartIcon />
              </ToggleButton>
              <ToggleButton value="cycletimechart" aria-label="list" color="primary" size="large" style={{ margin: "5px 5px 5px 5px" }}>
                <StackedBarChartIcon />
              </ToggleButton>
            </ToggleButtonGroup> */}
            </h2>

            <h5>
              Select Board:
              <Select style={{ position: "relative", width: "250px", marginLeft: "0.5rem" }}>
                {
                  boardnames.map(displayData)
                }
              </Select>
            </h5>
          </div>

          {(() => {
            if (show_visualization == "dashboard") {
              return (
                <Realdashboard selectedboard={selectedboardName} />
              )
            } 
            // else if (show_visualization == "piechart") {
            //   return (
            //     <Pie_Chart selectedboard={selectedboardName} />
            //   )
            // } else if (show_visualization == "barchart") {
            //   return (
            //     <Bar_chart selectedboard={selectedboardName} />
            //   )
            // } else if (show_visualization == "streamchart") {
            //   return (
            //     <Streamchart selectedboard={selectedboardName} />
            //   )
            // } else if (show_visualization == "cycletimechart") {
            //   return (
            //     <CycleTime selectedboard={selectedboardName} />
            //   )
            // }
            // else {
            //   return (
            //     <StackBar selectedboard={selectedboardName} />
            //   )
            // }
          })()}


          {/* <Dropdown style={{ position: "relative", left: "30%" }}>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Boards
          </Dropdown.Toggle>

          <Dropdown.Menu>
          </Dropdown.Menu>
        </Dropdown> */}


          {/* <h2 style={{ "margin-top": "10px", "margin-left": "20px" }}>
          Cumulative chart for{" "}
          <Zoom in={true} timeout={1000}>
            <Badge variant="primary">Progress</Badge>
          </Zoom>
        </h2> */}
          {/* <ResponsiveStream
          data={data5}
          keys={["ToDo", "Ready", "InProgress", "UnderReview", "Withdrawn"]}
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendOffset: 36,
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendOffset: -40,
          }}
          curve="natural"
          offsetType="diverging"
          colors={{ scheme: "nivo" }}
          fillOpacity={0.85}
          borderColor={{ theme: "background" }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "#2c998f",
              size: 4,
              padding: 2,
              stagger: true,
            },
            {
              id: "squares",
              type: "patternSquares",
              background: "inherit",
              color: "#e4c912",
              size: 6,
              padding: 2,
              stagger: true,
            },
          ]}
          fill={[
            {
              match: {
                id: "Paul",
              },
              id: "dots",
            },
            {
              match: {
                id: "InProgress",
              },
              id: "squares",
            },
          ]}
          dotSize={8}
          dotColor={{ from: "color" }}
          dotBorderWidth={2}
          dotBorderColor={{ from: "color", modifiers: [["darker", 0.7]] }}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              translateX: 100,
              itemWidth: 80,
              itemHeight: 20,
              itemTextColor: "#999999",
              symbolSize: 12,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000000",
                  },
                },
              ],
            },
          ]}
        />
*/}
          {/* BarChart */}
          {/* <h2 style={{ "margin-top": "10px", "margin-left": "20px" }}>
          BarChart showing{" "}
          <Zoom in={true} timeout={1000}>
            <Badge variant="primary">Throughput</Badge>
          </Zoom>
        </h2>  */}
          {/* <ResponsiveBar
          data={bardata}
          keys={["To Do", "Ready", "In_Progress", "Under_Review", "Withdrawn"]}
          indexBy="country"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "nivo" }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "#38bcb2",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "#eed312",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          fill={[
            {
              match: {
                id: "Withdrawn",
              },
              id: "dots",
            },
            {
              match: {
                id: "In_Progress",
              },
              id: "lines",
            },
          ]}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "---Days---",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Number of cards",
            legendPosition: "middle",
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        /> */}
          {/* Linear graph */}
          {/*  <h2
          style={{
            "margin-top": "50px",
            "margin-left": "20px",
            marginBottom: "20px",
          }}
        >
          Line Graph showing{" "}
          <Zoom in={true} timeout={1000}>
            <Badge variant="primary">Average Lead and Cycle Time</Badge>
          </Zoom>
        </h2>
        <ResponsiveLine
          data={lineardata}
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: true,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "transportation",
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "count",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
        )
        <h2
          style={{
            "margin-top": "50px",
            "margin-left": "20px",
            marginBottom: "20px",
          }}
        >
          Table showing{" "}
          <Zoom in={true} timeout={1000}>
            <Badge variant="primary">Throughput</Badge>
          </Zoom>
        </h2> */}
          {/* <div
          style={{
            width: "500px",
            marginTop: "105px",
            position: "relative",
            left: "30%",
          }}
        >
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th> </th>
                <th>Today</th>
                <th>Last Week</th>
                <th>Last Month</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Average Cycle Time</td>
                <td>15</td>
                <td>52</td>
                <td>36</td>
              </tr>
              <tr>
                <td>Average Lead Time</td>
                <td>88</td>
                <td>47</td>
                <td>96</td>
              </tr>
            </tbody>
          </Table>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default MyResponsivePie;