import React, { CSSProperties } from 'react';

import { useCSVReader } from 'react-papaparse';

const styles = {
  csvReader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  } ,
  browseFile: {
    width: '20%',
  } ,
  acceptedFile: {
    border: '1px solid #ccc',
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: '80%',
  } ,
  remove: {
    borderRadius: 0,
    padding: '0 20px',
  } ,
  progressBarBackgroundColor: {
    backgroundColor: 'red',
  } ,
};

export default function Parsing() {
  const { CSVReader } = useCSVReader();
  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  return (
    <CSVReader
      onUploadAccepted={(results ) => {
        console.log('---------------------------');
        console.log(results.data);
        const locations = results.data.map(([ DynamicID ,Type, Withdraw ,crdAcceptanceCriteria ,crdAssignedUser ,crdBusinessValue,crdComment, crdDescription, crdDueDate ,crdEmergency, crdName ,crdParentID ,
        crdPriority,crdReporter, crdStatus, crdType ,crdUpdateSubscriptions, crdWithdraw]) => ({ DynamicID ,Type, Withdraw ,crdAcceptanceCriteria ,crdAssignedUser ,crdBusinessValue,crdComment, crdDescription, crdDueDate ,crdEmergency, crdName ,crdParentID ,
          crdPriority,crdReporter, crdStatus, crdType ,crdUpdateSubscriptions, crdWithdraw}));

        locations.shift()
        console.log(locations);
          locations.map((x)=>{
            x.Withdraw=parseInt(x.Withdraw)
            x.crdEmergency=parseInt(x.crdEmergency)
          })
        console.log(locations);
        const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    const options = {
      method: "POST",
      headers: newHeaders,
      body: JSON.stringify(locations),
    };

    fetch(baseUrl+"/import",options).then(
        (res)=>{



        
           res.json().then((data)=>{
             console.log(data);
             alert("Data Imported")
        
          
           })


        }
      )
         console.log('---------------------------');
      }}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
      }) => (
        <>
          <div style={styles.csvReader}>
            <button type='button' {...getRootProps()} style={styles.browseFile}>
              Browse file
            </button>
            <div style={styles.acceptedFile}>
              {acceptedFile && acceptedFile.name}
            </div>
            <button {...getRemoveFileProps()} style={styles.remove}>
              Remove
            </button>
          </div>
          <ProgressBar style={styles.progressBarBackgroundColor} />
        </>
      )}
    </CSVReader>
  );
}