import React, { useEffect, useState } from "react";

import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

const ShareCard = (props) => {
  const url = (window.location.pathname.split("/"))[3].slice(1)

  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  const [card, setCard] = useState([]);


  useEffect(() => {
    console.log("in effect");
    const getData = () => {
      fetch(`${baseUrl}/cardDetails?id=${url}`)
        .then((res) => res.json().then(data => {
            setCard(data)
        }))
        .catch((e) => console.log(e));
    };

    getData();
  }, []);

  return (
    <div style={{padding:"23% 25%",backgroundColor:"#495057"}}>
      <div
        style={{
          backgroundColor:"white"
        }}
      >
        <div>
        <div style={{backgroundColor:"#2583F0", padding:"1%"}}></div>
        {card.crdAssignedUser !== "" ? (
            <Avatar>
              <div style={{ fontSize: 15, color: "black", fontWeight: "bold" }}>
                {card.crdAssignedUser &&
                  card.crdAssignedUser.substring(0, 2).toUpperCase()}
              </div>
            </Avatar>
          ) : (
            ""
          )}
          
          <Typography variant="caption">
            <p style={{paddingLeft:"45%", fontSize: 17, fontWeight:"bold"}}>{card.crdName}</p>
          </Typography>
          <Typography variant="caption">
            <p style={{paddingLeft:"45%", fontSize: 17}}>{card.crdDescription}</p>
          </Typography>


          <Typography style={{ paddingLeft:"80%", fontSize: 17,fontWeight:"bold" }}>
            {card.DynamicID}
          </Typography>
          
        </div>
      </div>
    </div>
  );
};

export default ShareCard;
