import { GoogleLogin } from "react-google-login";
import Grow from "@material-ui/core/Grow";
import board from "../../assets/images/work.svg";
import "../styles/style.css";
import slider from "../../assets/images/slider.gif";
import logo from "../../assets/images/logo.png";
import { gapi } from "gapi-script";
const Home = (props) => {
  // Getting current time to store it as Last Login status of user
  const baseUrl = process.env.REACT_APP_HEROKU_URL;

  gapi.load("client:auth2", () => {
    gapi.client.init({
      clientId:
        "1008934119900-vh7mo5tt66s6s4jrv22piehs7na4s4jr.apps.googleusercontent.com",
      plugin_name: "chat",
    });
  });

  // const onFailure=(res)=>{


  //   console.log(res);
  // }
  

  // Getting response from Google Login
  const onSuccess = (res) => {
    console.log(res);
    // localStorage.setItem("userId", res.googleId);
    localStorage.setItem("email", res.profileObj.email);
    localStorage.setItem("name", res.profileObj.name);
    localStorage.setItem("token", res.tokenId);

    
    //  res.profileObj.email === "anirudhakulkarni771@gmail.com" &&
    //       localStorage.setItem("admin", true);

         fetch(baseUrl + "/user?email=" +res.profileObj.email).then((res)=>{
           res.json().then(
             (data)=>{
               console.log("To come for that ",data);
               if(data)
               {
                 
                 if(data.usrRole==="Admin")
                 {
                    localStorage.setItem("admin", true);
                 }

                 props.history.push("/dashboard");
               }
               else
               {

                alert("Unathorized access")
               }

                 

             }
           )
         })
         

    

    
  };
  // const onSuccess = (res) => {
  //   console.log("success: ",res.profileObj);
  // }

  const onFailure = (res) => {
    console.log("failure: ",res);
  }


  return (
    <>
      <div className="sign-up-mode">
        <img src={logo} className="logo" alt="logo" />
        <div className="forms-container">
          <div className="signinForm">
            <form>
              <img src={slider} alt="slider" className="slider" />
            </form>
          </div>
        </div>
        <div className="panels-container">
          <div className="panel left-container">
            <div className="content">
              <h3>Visualize your work with OmniVue</h3>
              <p>
                Login with your gmail id and start using OmniVue for better
                experience!
              </p>
              <GoogleLogin
                // clientId="595528007040-ig7l7s308pst1mb89g4jv566f88p55l5.apps.googleusercontent.com"
                clientId="1008934119900-vh7mo5tt66s6s4jrv22piehs7na4s4jr.apps.googleusercontent.com"
                // clientId="69570663529-kcipqdasof9jv3ft7oodivs81uhto2sm.apps.googleusercontent.com"
                //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
                buttonText="Login with Google"
                onSuccess={onSuccess}
                onFailure={onFailure}
                //onFailure={onFailure}
              />
            </div>
            <Grow in={true} timeout={1800}>
            <img src={board} className="image" />
            </Grow>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
