import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// import Canvas from "Canvas";

import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AddAdmin(props) {
  const { openForm, setOpenForm, setUsers , users} = props;
   
  const [selectAdmin, setSelectAdmin] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [openSuccessAlert, setopenSuccessAlert] = React.useState(false);
  const [openErrorAlert, setopenErrorAlert] = React.useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setopenSuccessAlert(false);
    setopenErrorAlert(false);
  };

  const baseUrl = process.env.REACT_APP_HEROKU_URL;

  

  const handleSelectChange = (event) => {
    setSelectAdmin(event.target.value);
  };

  const handleClose = () => {
    setOpenForm(false);
   
    setSelectAdmin("");
  };

  // const isEnabled = () => {
  //   var obj = data;

  //   for (var key in obj) {
  //     console.log("Value", key, obj[key]);
  //     if (obj[key] === "" && (key === "usrName" || key === "usrEmailID")) return true;
  //   }
  //   return true;
  // };

  // var flag = isEnabled();
  // console.log("flag", flag);

  const mergeInfo = () => {
    
      console.log(selectAdmin);
      addNewUser(selectAdmin)
    
  };

  const getAllUsers = () => {
    fetch(baseUrl + "/user/list")
      .then((response) =>
        response.json().then((data) => {
          //console.log("User", data);
          setUsers(data);
        })
      )
      .catch((err) => console.log(err));
  };

  const addNewUser = (data) => {
    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    const options = {
      method: "PUT",
      headers: newHeaders,
      body: JSON.stringify({usrRole:"Admin"}),
    };

    console.log("Options", options);
    fetch(baseUrl + "/user/update?email="+data, options)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        getAllUsers();
        setopenSuccessAlert(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setopenErrorAlert(true);
      })
      .finally(() => {
        handleClose();
      });
    console.log(data);
  };
// console.log()
  return (
    <div>
      <Dialog
        onClose={() => {
          handleClose();
        }}
        open={openForm}
      >
        <DialogTitle>
          <h3>Add New User</h3>
        </DialogTitle>
        <DialogContent>
         
          <div>
            <FormControl>
              <InputLabel id="demo-mutiple-name-label">Add Admin</InputLabel>
              <Select
                style={{ minWidth: 170, width: "auto" }}
                
                fullWidth
                name={selectAdmin}
                value={selectAdmin}
                onChange={handleSelectChange}
                input={<Input />}
                MenuProps={MenuProps}
              >
                {/* {canvas.length > 0 &&
                  canvas.map((canvas) => (
                    <MenuItem value={canvas.CanvasId}>
                      {canvas.CanvasName}
                    </MenuItem>
                  ))} */}
                  {users.length > 0 && 
                    users.map((usr)=>(
                        usr.usrRole !== "Admin"?(<MenuItem value={usr.usrEmailID}>{usr.usrName} {usr.usrEmailID}</MenuItem>):("")))}
              </Select>
            </FormControl>
          </div>
        </DialogContent>

        <DialogActions style={{ marginTop: 20, marginRight: 10 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={mergeInfo}
            // disabled={flag === false}
          >
            {loading === true ? (
              <CircularProgress color="secondary" />
            ) : (
              <span>Add New</span>
            )}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSuccessAlert}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success">
          Successfull...
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorAlert}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success">
          Something Went Wrong!.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AddAdmin;
