import React from "react";
import "../styles/list.css";
import { useStyles } from "../styles/listCss";
import { useTheme } from "@material-ui/core/styles";

import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import UpdateCard from "./UpdateCard";

import { SuccessSnackbar, ErrorSnackbar } from "./Snackbar";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

function ShowList(props) {
  const { card, board } = props;
  const [open, setOpen] = React.useState(false);
  const [openDel, setOpenDel] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);
  const [openSuccessAlert, setopenSuccessAlert] = React.useState(false);
  const [openErrorAlert, setopenErrorAlert] = React.useState(false);
  const [deleteSuccessAlert, setDeleteSuccessAlert] = React.useState(false);
  const [data, setData] = React.useState({
    crdAssignedUser: card.crdAssignedUser,
    crdStatus: card.crdStatus,
    crdName: card.crdName,
    crdDescription: card.crdDescription,
    crdDueDate: card.crdDueDate,
    crdBusinessValue: card.crdBusinessValue,
    crdEmergency: card.crdEmergency,
    crdPriority: card.crdPriority,
    crdType: card.crdType,
    crdAcceptanceCriteria: card.crdAcceptanceCriteria,
    Withdraw: 1,
   
  });

  const baseUrl = process.env.REACT_APP_HEROKU_URL;

  //To get all the users

  const classes = useStyles();

  const editContent = () => {
    //setDisable(!disable);
    setOpenForm(!openForm);
  };

  const deleteContent = () => {
    const newData = data;
    
    newData.crdStatus = "Withdrawn";
    setData(newData);

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    fetch(baseUrl+"/update?Id="+card.DynamicID, requestOptions)
      .then((response) =>
        response.ok
          ? response.json().then((data) => {
              console.log(data);
              setopenSuccessAlert(true);
              props.getData(props.canvasId);
            })
          : setopenErrorAlert(true)
      )
      .catch((err) => console.log(err));
  };

  const handleDialogOpen = () => {
    setOpenDel(true);
  };
  const handleDialogClose = () => {
    setOpenDel(false);
  };

  const deleteRecordPermanently = () => {
    

   
    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    const options = {
      method: "DELETE",
      headers: newHeaders,
     
    };

    console.log("Optn", options);
    fetch(baseUrl+"/delete?Id="+card.DynamicID, options)
      .then((response) =>
        response.ok
          ? response.json().then((data) => {
              console.log(data);
              setDeleteSuccessAlert(true);
              props.getData(props.canvasId);
            })
          : setopenErrorAlert(true)
      )
      .catch((error) => {
        console.log(error);
        setopenErrorAlert(true); 
      })
      .finally(() => handleDialogClose());
  };

  return (
    <>
      <TableCell className={classes.tableCell} align="center">
        <p className="summary">{card.DynamicID}</p>
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        <p className="summary">{card.crdStatus}</p>
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        <LightTooltip
          title={<Typography> {card.crdName} </Typography>}
          placement="top-start"
        >
          <p className="summary">{card.crdName}</p>
        </LightTooltip>
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        <LightTooltip
          title={<p style={{ fontSize: 15 }}> {card.crdDescription} </p>}
          placement="top-start"
        >
          <p className="summary">{card.crdDescription}</p>
        </LightTooltip>
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        <LightTooltip
          title={<p style={{ fontSize: 15 }}> {card.crdAcceptanceCriteria} </p>}
          placement="top-start"
        >
          <p className="summary">{card.crdAcceptanceCriteria}</p>
        </LightTooltip>
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        <p className="summary">{card.crdDueDate}</p>
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        <p className="summary">{card.crdPriority}</p>
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        <p className="summary">{card.crdAssignedUser}</p>
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        <LightTooltip
          title={<p style={{ fontSize: 15 }}> {card.crdComment} </p>}
          placement="top-start"
        >
          <p className="summary">{card.crdComment}</p>
        </LightTooltip>
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        <p className="summary">{card.crdType}</p>
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        <p className="summary">{card.crdBusinessValue}</p>
      </TableCell>

      <TableCell align="center">
        <IconButton>
          <Tooltip title="Edit record" placement="top">
            <CreateIcon onClick={editContent} />
          </Tooltip>
        </IconButton>
        <IconButton>
          <Tooltip title="Withdraw record" placement="top">
            <DeleteIcon onClick={deleteContent} />
          </Tooltip>
        </IconButton>
        {localStorage.getItem("admin") !== null &&
          (card.crdStatus === "Withdrawn" || card.crdStatus === "Done") && (
            <IconButton>
              <Tooltip title="Delete record" placement="top">
                <DeleteForeverIcon onClick={handleDialogOpen} />
              </Tooltip>
            </IconButton>
          )}
      </TableCell>
      {openForm && (
        <UpdateCard
          openForm={openForm}
          setOpenForm={setOpenForm}
          loaded={card}
          canvasData={board}
          sts={card.crdStatus}
          canvasId={props.canvasId}
          setOpen={setOpen}
          getData={props.getData}
        />
      )}
      <Dialog open={openDel}>
        <DialogContent>
          Are you sure you want to delete this record? This can't be undone!
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={deleteRecordPermanently}>
            {" "}
            Delete{" "}
          </Button>
          <Button color="primary" onClick={handleDialogClose}>
            {" "}
            Cancel{" "}
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessSnackbar
        openSuccessAlert={openSuccessAlert}
        setopenSuccessAlert={setopenSuccessAlert}
        message="Record Withdrawn Successfully!"
        horizontal="top"
        vertical="center"
      />
      <ErrorSnackbar
        openErrorAlert={openErrorAlert}
        setopenErrorAlert={setopenErrorAlert}
        horizontal="top"
        vertical="center"
      />
      <SuccessSnackbar
        openSuccessAlert={deleteSuccessAlert}
        setopenSuccessAlert={setDeleteSuccessAlert}
        message="Record Deleted Successfully!"
        horizontal="top"
        vertical="center"
      />
    </>
  );
}

export default ShowList;
