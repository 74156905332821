import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { SuccessSnackbar } from "../../Snackbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

const baseUrl = process.env.REACT_APP_HEROKU_URL;
let projectid = window.localStorage.getItem("PROJECT_ID");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Collection = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openSuccessAlert, setopenSuccessAlert] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);

  const [addData, setAddData] = React.useState({});
  let dummyAddData = {};
  const [rows, setRows] = React.useState([]);
  const [postData, setPostData] = React.useState({
    company_contact: "",
    company_name: "",
    desc: "",
    id: "",
    terms_remarks: "",
    type: props.option,
    subTotal: 0,
    discount: 0,
    total: 0,
    prjid: projectid,
  });
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  function generateString() {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < 7; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
  const calcSubTotal = () => {
    // rows.map((row) =>
    //   setPostData({ ...postData, subTotal: postData.subTotal + row.total })
    // );
    // return postData.subTotal
    let sum = 0;
    for (let index = 0; index < rows.length; index++) {
      sum += rows[index].total;
    }
    setPostData({ ...postData, subTotal: sum });
  };
  const calcTotal = () => {
    setPostData({
      ...postData,
      total: postData.subTotal - (postData.subTotal * postData.discount) / 100,
    });
  };
  useEffect(() => {
    console.log("addData", addData);
    if (addData.unitPrice !== undefined) setRows([...rows, addData]);
  }, [addData]);

  useEffect(() => {
    calcSubTotal();
  }, [rows]);

  useEffect(() => {
    calcTotal();
  }, [postData.subTotal]);

  const autoId = generateString();
  var date = new Date();
  const handleSubmit = () => {
    const data = {
      ...postData,
      id: autoId,
      currDate: date.toDateString(),
      item: [...rows],
    };
    console.log(data);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    fetch(`${baseUrl}/addAccounts`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        result === "success" && setopenSuccessAlert(true);
        result === "success" && setDisabled(false);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <TextField
          id="outlined-basic"
          label="Company Name"
          variant="outlined"
          onChange={(e) => {
            setPostData({ ...postData, company_name: e.target.value });
          }}
        />
        <TextField
          id="outlined-basic"
          label="Company Contact Details"
          variant="outlined"
          onChange={(e) => {
            setPostData({ ...postData, company_contact: e.target.value });
          }}
        />
        <TextField
          id="outlined-basic"
          label={props.option === 0 ? "Invoice To" : "Quotation from"}
          variant="outlined"
          onChange={(e) => {
            setPostData({ ...postData, desc: e.target.value });
          }}
        />
        <TextField
          id="outlined-basic"
          label="Terms & Conditions OR Remarks"
          variant="outlined"
          onChange={(e) => {
            setPostData({ ...postData, terms_remarks: e.target.value });
          }}
        />
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            paddingTop: "3rem",
          }}
        >
          <Button onClick={handleOpen}>
            <AddCircleOutlineIcon fontSize="large" />
          </Button>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Data
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Item Description"
                  variant="outlined"
                  // value={addData.itemDesc}
                  onChange={(e) =>
                    // setAddData({
                    //   itemDesc: e.target.value,
                    //   unitPrice: addData.unitPrice,
                    //   qty: addData.qty,
                    //   tax: addData.tax,
                    //   total: addData.total,
                    // })
                    (dummyAddData["itemDesc"] = e.target.value)
                  }
                />
                <TextField
                  type="number"
                  id="outlined-basic"
                  label="Unit price"
                  variant="outlined"
                  // value={addData.unitPrice}
                  onChange={(e) =>
                    // setAddData({
                    //   itemDesc: addData.itemDesc,
                    //   unitPrice: e.target.value,
                    //   qty: addData.qty,
                    //   tax: addData.tax,
                    //   total: addData.total,
                    // })
                    (dummyAddData["unitPrice"] = e.target.value)
                  }
                />
                <TextField
                  type="number"
                  id="outlined-basic"
                  label="Quantity"
                  variant="outlined"
                  // value={addData.qty}
                  onChange={(e) =>
                    // setAddData({
                    //   itemDesc: addData.itemDesc,
                    //   unitPrice: addData.unitPrice,
                    //   qty: e.target.value,
                    //   tax: addData.tax,
                    //   total: addData.total,
                    // })
                    (dummyAddData["qty"] = e.target.value)
                  }
                />

                <TextField
                  type="number"
                  id="outlined-basic"
                  label="Tax%"
                  variant="outlined"
                  // value={addData.tax}
                  onChange={(e) =>
                    // setAddData({
                    //   itemDesc: addData.itemDesc,
                    //   unitPrice: addData.unitPrice,
                    //   qty: addData.qty,
                    //   tax: e.target.value,
                    //   total: addData.total,
                    // })
                    (dummyAddData["tax"] = e.target.value)
                  }
                />
                {/* <TextField
                id="outlined-basic"
                label="Total"
                variant="outlined"
                value={addData.total}
                onChange={(e) =>
                  setAddData({
                    itemDesc: addData.itemDesc,
                    unitPrice: addData.unitPrice,
                    qty: addData.qty,
                    tax: addData.tax,
                    total: e.target.value,
                  })
                }
              /> */}
                <Button
                  onClick={() => {
                    let pri = dummyAddData.qty * dummyAddData.unitPrice;
                    let locTot = pri + pri * (dummyAddData.tax / 100);
                    console.log(locTot);
                    setAddData({ ...dummyAddData, total: locTot });
                    setOpen(false);
                    console.log(addData);
                  }}
                  variant="contained"
                  color="success"
                >
                  Add
                </Button>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
      <div style={{ paddingTop: "5rem" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell>Action</TableCell> */}
                <TableCell 
                align="right"
                >Sr#</TableCell>
                <TableCell align="right">Item Description</TableCell>
                <TableCell align="right">Unit Price</TableCell>
                <TableCell align="right">QTY.</TableCell>
                <TableCell align="right">Tax%</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, idx) => (
                <TableRow
                  key={row.sr}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {/* <TableCell component="th" scope="row">
                    {row.action}
                  </TableCell> */}
                  <TableCell align="right">{idx + 1}</TableCell>
                  <TableCell align="right">{row.itemDesc}</TableCell>
                  <TableCell align="right">{row.unitPrice}</TableCell>
                  <TableCell align="right">{row.qty}</TableCell>
                  <TableCell align="right">{row.tax}</TableCell>
                  <TableCell align="right">{row.total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            width: "95%",
            paddingLeft: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <label>SubTotal : {postData.subTotal}</label>
          <TextField
            type="number"
            id="outlined-basic"
            label="Discount%"
            variant="outlined"
            onChange={(e) => {
              setPostData({ ...postData, discount: e.target.value });
            }}
          />
          <label>
            Total :
            {postData.subTotal - (postData.subTotal * postData.discount) / 100}
          </label>
        </div>
      </div>
      {disabled === true && (
        <Button onClick={handleSubmit} variant="contained" color="warning">
          Submit
        </Button>
      )}

      <Link
        style={{
          textDecoration: "none",
          color: "black",
          paddingLeft: "3rem",
          fontWeight: "bolder",
        }}
        to={{
          pathname: `/view`,
          state: {
            data: {
              ...postData,
              id: autoId,
              currDate: date.toDateString(),
              item: [...rows],
            },
          },
        }}
      >
        {disabled === false && (
          <Button variant="contained" color="primary">
            Print
          </Button>
        )}
      </Link>
      <SuccessSnackbar
        openSuccessAlert={openSuccessAlert}
        setopenSuccessAlert={setopenSuccessAlert}
        message="Submitted successfully"
        horizontal="top"
        vertical="center"
      />
    </>
  );
};

export default Collection;
