import React, { useEffect, useState } from "react";
import Header from "./Header";
import { AppBar, Toolbar } from "@material-ui/core";
import { useStyles } from "../styles/MainBoardCss";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import omnivuelogo from "../../assets/images/omnivuelogo.jpg";
import { BiLockOpenAlt } from "react-icons/bi";
import $ from "jquery";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import ViewModuleIcon from '@mui/icons-material/ViewModule'

import ViewListIcon from '@mui/icons-material/ViewList';
import ToggleButton from '@mui/material/ToggleButton';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import TextField from "@material-ui/core/TextField";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveIcon from '@mui/icons-material/Remove';


import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Modal from '@material-ui/core/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import Alert from "react-bootstrap/Alert";
import { SuccessSnackbar, ErrorSnackbar } from "./Snackbar";
import Collapse from 'react-bootstrap/Collapse'
import { fontSize } from "@mui/system";

import "../styles/project_card.css";



export default function Select_project() {
    const baseUrl = process.env.REACT_APP_HEROKU_URL;
    const email = localStorage.getItem("email");
    const project_name = localStorage.getItem("PROJECT_NAME");
    const check_admin = localStorage.getItem("admin");
    var curr = new Date();
    curr.setDate(curr.getDate());
    var date = curr.toISOString().substring(0, 10);

    const classes = useStyles();


    // collapse form while data project
    const [toggle, setToggle] = useState(false);
    const toggleFunc = React.useCallback(() => setToggle(!toggle));

    // on click / select project 
    function handle_clickOnProject(event, user) {
        event.preventDefault();
        localStorage.setItem("PROJECT_ID", user.prjId);
        localStorage.setItem("PROJECT_NAME", user.prjName);
        window.location.href = "/dashboard";
    }

    // alert component
    const [show_alert, set_show_alert] = useState(false);
    const [alert_message, set_alert_message] = useState("");
    const [alert_type, set_alert_type] = useState("");


    function handle_close_alert(e) {
        e.preventDefault();
        set_show_alert(false);
        // window.location.reload();
    }


    // card and grid view
    const [check_listview, set_check_listview] = useState(false);

    const handle_gridview = (e) => {
        e.preventDefault();
        set_check_listview(false);
    }

    const handle_listview = (e) => {
        e.preventDefault();
        set_check_listview(true);
    }


    // fetch project data
    const [projectname, setprojectname] = useState([])

    useEffect(() => {
        fetch(baseUrl + `/projectsName?id=${email}`)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setprojectname(data)
            })
    }, [])



    // add project using api
    const [add_project_name, add_setproject_name] = useState("");
    const [add_project_prefix, add_setproject_prefix] = useState("");
    const [add_project_owner_name, add_setproject_owner_name] = useState(email);
    // const [add_project_ID, add_setproject_ID] = useState("");
    const [add_Project_start_Date, add_setProject_start_Date] = useState(date);
    const [add_Project_end_Date, add_setProject_end_Date] = useState("");
    const [add_Project_desc, add_setProject_desc] = useState("");
    const [add_Project_private, add_setProject_private] = useState("0");

    var startDate = localStorage.getItem("startDate");
    var endDate = localStorage.getItem("endDate");
    var dateSetting = localStorage.getItem("dateSetting");

    async function add_handleSubmit(event) {
        event.preventDefault();
        await fetch(baseUrl + `/admin/addProject?name=${add_project_name}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "isDeleted": '0',
                "prjVer": "1",
                // "prjId": `${add_project_ID}`,
                "prjName": `${add_project_name}`,
                "prjOwnerId": `${email}`,
                "prjOwnerName": `${add_project_owner_name}`,
                "prjPrefix": `${add_project_prefix}`,
                "prjPrivate": add_Project_private,
                "prjDesc": `${add_Project_desc}`,
                "prjUsers": [
                    `${email}`
                ],
                "prjstartDate": `${add_Project_start_Date}`,
                "prjenddate": `${add_Project_end_Date}`,
                "prjAudit": `${email}`
            }),
        })
            .then(response => response.json())
            .then(json => console.log(json));

        // alert("add data successfully")
        set_show_alert(true);
        set_alert_message("Project has been created successfully");
        set_alert_type("success");
        setTimeout(function () {
            window.location.reload();
        }, 1000);
    }



    // delete project
    function handle_delete(event, user) {
        event.preventDefault();
        // alert(`You Want to delete ${user} Project`);
        fetch(baseUrl + `/admin/deleteProject?name=${user}`, {
            method: 'DELETE'
        })
            .then(response => response.json())
            .then(data =>
                // this is the data we get after doing the delete request, do whatever you want with this data
                console.log(data)
            );
        // window.location.reload();
        set_show_alert(true);
        set_alert_message("Delete Project successfully");
        set_alert_type("success");
        setTimeout(function () {
            window.location.reload();
        }, 1000);
    }


    // update project
    const [modal_open, setmodal_Open] = React.useState(false);

    const handle_modal_Close = () => {
        setmodal_Open(false);
    };

    function handle_update(event, user) {
        localStorage.setItem("old_project_nameFor_update", user.prjName);
        event.preventDefault();
        update_setproject_ver(user.prjVer);
        update_setproject_name(user.prjName);
        update_setproject_owner_id(user.prjOwnerId);
        update_setproject_owner_name(user.prjOwnerName);
        update_setproject_ID(user.prjId);
        update_setProject_start_Date(user.prjstartDate);
        update_setProject_end_Date(user.prjenddate);
        update_setProject_desc(user.prjDesc);
        update_setProject_private(user.prjPrivate);
        setmodal_Open(true);
    }

    const [update_project_ver, update_setproject_ver] = useState("");
    const [update_project_name, update_setproject_name] = useState("");
    const [update_project_owner_id, update_setproject_owner_id] = useState("");
    const [update_project_owner_name, update_setproject_owner_name] = useState("");
    const [update_project_ID, update_setproject_ID] = useState("");
    const [update_Project_start_Date, update_setProject_start_Date] = useState("");
    const [update_Project_end_Date, update_setProject_end_Date] = useState("");
    const [update_Project_desc, update_setProject_desc] = useState("");
    const [update_Project_private, update_setProject_private] = useState("");


    async function onSubmit_update_form(e) {
        e.preventDefault();
        const varible = localStorage.getItem("old_project_nameFor_update");
        await fetch(baseUrl + `/admin/updateProject?name=${varible}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "isDeleted": '0',
                "prjVer": `${update_project_ver}`,
                "prjId": `${update_project_ID}`,
                "prjName": `${update_project_name}`,
                "prjOwnerId": `${update_project_owner_id}`,
                "prjOwnerName": `${update_project_owner_name}`,
                "prjPrefix": "",
                "prjPrivate": `${update_Project_private}`,
                "prjDesc": `${update_Project_desc}`,
                "prjUsers": [
                    `${email}`
                ],
                "prjDate": `${update_Project_start_Date}`,
                "prjenddate": `${update_Project_end_Date}`,
                "prjAudit": `${email}`,
            }),
        })
            .then(response => response.json())
            .then(json => console.log(json));

        localStorage.removeItem("old_project_nameFor_update");
        setmodal_Open(false);

        // window.location.reload();
        set_show_alert(true);
        set_alert_message("Update Project successfully");
        set_alert_type("success");
        setTimeout(function () {
            window.location.reload();
        }, 2000);
    }

    $(document).ready(function () {
        $("#search").on("keyup", function () {
          var value = $(this).val().toLowerCase();
          $("#dataTable #tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
          });
        });
      });

    return (
        <>
            {/* <Header x="Project Selection" /> */}
            <AppBar onClick={() => window.scroll(0,0)} position="sticky" className={classes.appbar}>
                <Toolbar>
                    <Typography className={classes.title} variant="h6" noWrap>
                        Select Project
                        <div>
                            <p style={{ color: "red", fontSize: 12 }}>{project_name}</p>
                        </div>
                    </Typography>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            id="search"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ "aria-label": "search" }}
                        />
                    </div>
                    <div>
                        <img src={omnivuelogo} className={classes.omni} />
                    </div>
                </Toolbar>
            </AppBar>

            <div style={{
                // marginLeft: "3.5rem",
                marginTop: "0.5rem",

                // height: "100rem",
            }}>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static"
                        style={{ background: '#fff' }} >
                        <Toolbar style={{ display: 'flex', justifyContent: "space-between", width: '98%' }}>

                            {check_admin ? (
                                <>


                                    <div>
                                        <Button variant="outlined" onClick={toggleFunc}><IconButton >
                                            {toggle ? (
                                                <RemoveIcon color="primary" size="large" />
                                            ) : (
                                                <AddIcon color="primary" size="large" />
                                            )}
                                        </IconButton></Button>
                                        {/* <button onClick={toggleFunc}>Toggle Collapse</button> */}
                                        <Collapse in={toggle}>
                                            <div style={{ color: "Black" }}>
                                                <form onSubmit={add_handleSubmit}>
                                                    <div className="container">
                                                        <div className="row">


                                                            {/* <label>Project Id:
                                                    <input
                                                        type="number"
                                                        value={add_project_ID}
                                                        onChange={(e) => add_setproject_ID(e.target.value)}
                                                        required
                                                    />
                                                </label> */}
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <TextField
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        required
                                                                        fullWidth
                                                                        // id="standard-text-input"
                                                                        label="Project Name"
                                                                        type="text"
                                                                        // name="crdName"
                                                                        value={add_project_name}
                                                                        onChange={(e) => add_setproject_name(e.target.value)}
                                                                        multiline
                                                                        rows={1}
                                                                        autoComplete="current-text"
                                                                    />
                                                                </div>


                                                                <div className="form-group">
                                                                    <TextField
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        required
                                                                        fullWidth
                                                                        label="Project Prefix"
                                                                        type="text"
                                                                        value={add_project_prefix}
                                                                        onChange={(e) => add_setproject_prefix(e.target.value)}
                                                                        multiline
                                                                        rows={1}
                                                                        minLength={2}
                                                                        maxLength={8}
                                                                        autoComplete="current-text"
                                                                    />
                                                                </div>



                                                                <div className="form-group">
                                                                    <TextField
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        required
                                                                        fullWidth
                                                                        // id="standard-text-input"
                                                                        label="Project Owner name"
                                                                        type="text"
                                                                        // name="crdName"
                                                                        value={add_project_owner_name}
                                                                        onChange={(e) => add_setproject_owner_name(e.target.value)}
                                                                        multiline
                                                                        rows={1}
                                                                        autoComplete="current-text"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">

                                                            <div className="form-group">
                                                                <label style={{ marginLeft: "15px" }}>Project Start Date:
                                                                    <TextField
                                                                        type="date"
                                                                        value={add_Project_start_Date}
                                                                        onChange={(e) => add_setProject_start_Date(e.target.value)}
                                                                        required
                                                                        style={{ marginLeft: "4px" }}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </label>
                                                            </div>

                                                            <div className="form-group">
                                                                <label style={{ marginLeft: "35px" }}>Project End Date:
                                                                    <TextField
                                                                        type="date"
                                                                        value={add_Project_end_Date}
                                                                        onChange={(e) => add_setProject_end_Date(e.target.value)}
                                                                        required
                                                                        style={{ marginLeft: "4px" }}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <TextField
                                                                multiline
                                                                rows={2}
                                                                maxRows={5}
                                                                variant="outlined"
                                                                margin="normal"
                                                                required
                                                                fullWidth
                                                                label="Project Description"
                                                                type="text"
                                                                value={add_Project_desc}
                                                                onChange={(e) => add_setProject_desc(e.target.value)}
                                                                autoComplete="current-text"
                                                            />
                                                        </div>

                                                        <div className="form-group">
                                                            <label>Private
                                                                <input
                                                                    type="checkbox"
                                                                    size="string"
                                                                    id="send_newsletter"
                                                                    value="1"
                                                                    onChange={(e) => add_setProject_private(e.target.value)}
                                                                    style={{ marginLeft: "4px" }}


                                                                />
                                                            </label>
                                                        </div>

                                                        <div className="d-flex justify-content-between">
                                                            <div></div>
                                                            <div>
                                                                <input type="submit" className="btn btn-success btn-sm" value={"Create"} />
                                                                <input type="reset" className="btn btn-danger btn-sm ml-1" value={"Reset"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </Collapse>
                                    </div>
                                </>
                            ) : (
                                <h4 style={{ color: "red" }}>Please Select project on which you want to work</h4>
                            )}

                            {check_listview ? (
                                <i onClick={handle_gridview}>
                                    <ToggleButton value="list" aria-label="list" color="primary" size="large">
                                        <ViewModuleIcon />
                                    </ToggleButton></i>

                            ) : (
                                <i onClick={handle_listview}>
                                    <ToggleButton value="list" aria-label="list" color="primary" size="large">
                                        <ViewListIcon />
                                    </ToggleButton></i>
                            )}

                        </Toolbar>
                    </AppBar>
                </Box>
                <SuccessSnackbar
                        openSuccessAlert={show_alert}
                        setopenSuccessAlert={set_show_alert}
                        message={alert_message}
                        horizontal="top"
                        vertical="center"
                    />

                {check_listview ? (
                    <div id="dataTable">
                        {projectname.length === 0 && 'No project to display'}
                        {projectname.length > 0 && (
                            <List sx={{
                                // width: '100%',

                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                // maxHeight: '100%',

                            }}  >
                                {projectname.map(user => (
                                    <ListItem id="tr" disablePadding >

                                        <ListItemButton onClick={(event) => { handle_clickOnProject(event, user) }}>
                                            <ListItemText primary={user.prjName} />
                                        </ListItemButton>
                                        <ListItemIcon>
                                            {check_admin ? (
                                                <>
                                                    <IconButton sx={{ mr: 3 }}>
                                                        <i onClick={(event) => { handle_update(event, user) }}><EditIcon /></i>
                                                    </IconButton>

                                                    <IconButton sx={{ mr: 3 }}>
                                                        <i onClick={(event) => { handle_delete(event, user.prjName) }}><DeleteIcon /></i>
                                                    </IconButton>
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                        </ListItemIcon>

                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </div>
                ) : (
                    <>
                        {/* <div>
                        {projectname.length === 0 && 'No project to display'}
                        {projectname.length > 0 && (
                            <>
                                {projectname.map(user => (
                                    <Card sx={{
                                        maxWidth: 345,
                                        borderRadius: 4,
                                        border: 2,
                                        margin: 10,
                                        // flex: 1,
                                        // flexDirection: 'row',
                                        // flexWrap: 'wrap',
                                    }} >

                                        <CardHeader
                                            action={
                                                <IconButton aria-label="settings">
                                                    <MoreVertIcon />
                                                </IconButton>

                                            }
                                            
                                            title= <i onClick={(event) => { handle_clickOnProject(event, user) }}>{user.prjName} </i>
                                        
                                        />
                                        <CardContent>
                                            <CardActions>
                                                <i onClick={(event) => { handle_clickOnProject(event, user) }}>
                                                    <Button size="small">Go to this Project</Button>
                                                </i>
                                                {user.prjenddate}
                                                {check_admin ? (
                                                    <>
                                                        <IconButton aria-label="Update">
                                                            <i onClick={(event) => { handle_update(event, user) }}><EditIcon /></i>
                                                        </IconButton>
                                                        <IconButton aria-label="Delete">
                                                            <i onClick={(event) => { handle_delete(event, user.prjName) }}><DeleteIcon /></i>
                                                        </IconButton>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                            </CardActions>
                                        </CardContent>
                                    </Card>
                                ))}
                            </>
                        )}
                    </div> */}
                    

                        {projectname.length === 0 && 'No project to display'}
                        {projectname.length > 0 &&(
                            <>
                                <div id="dataTable" className="container">
                                    {projectname.map(user => (
                                        <div id="tr">
                                            {dateSetting === "1" ? (Date.parse(startDate) < Date.parse(user.prjstartDate) && Date.parse(endDate) > Date.parse(user.prjenddate)) : true &&(
                                            <div className=" card_project">
                                                <div className="card p-3 mb-2">
                                                    <div  className="d-flex justify-content-between">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="icon"> <i className="bx bxl-mailchimp"></i> </div>
                                                            <div className="ms-2 c-details">
                                                                <h6 className="mb-0"><i onClick={(event) => { handle_clickOnProject(event, user) }} style={{color:"blue", cursor:'pointer'}}>{user.prjName} </i></h6> <span></span>
                                                            </div>
                                                        </div>
                                                        {user.prjPrivate ? (
                                                            <div className="badge_w"> <span>Private</span> </div>
                                                        ) : (
                                                            <div className="badge_s"> <span>Public</span> </div>
                                                        )}

                                                    </div>
                                                    <div className="mt-5">
                                                        <p className="heading">{user.prjDesc} </p>
                                                    </div>
                                                    <div className="mt-5">
                                                        <p className="heading">{user.prjstartDate} to <span><b>{user.prjenddate}</b></span> </p>
                                                    </div>
                                                    <div className="mt-3 d-flex justify-content-between">
                                                        {/* <i onClick={(event) => { handle_clickOnProject(event, user) }}><BiLockOpenAlt/></i> */}
                                                        {check_admin ? (
                                                            <>
                                                                <IconButton aria-label="Update">
                                                                    <i onClick={(event) => { handle_update(event, user) }}><EditIcon /></i>
                                                                </IconButton>
                                                                <IconButton aria-label="Delete">
                                                                    <i onClick={(event) => { handle_delete(event, user.prjName) }}><DeleteIcon /></i>
                                                                </IconButton>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {/* <i className="far fa-edit mx-2" onClick={() => { updateproj(proj) }}></i> */}
                                                        {/* <i className="far fa-trash-alt mx-2" onClick={() => { deleteProject(proj._id) }}></i> */}

                                                    </div>
                                                </div>
                                            </div>
                                            )}
                                        </div>

                                    ))}
                                </div>
                            </>
                        )}

                    </>





                )}
            </div>

            <Modal
                onClose={handle_modal_Close}
                open={modal_open}
                style={{
                    position: 'absolute',
                    backgroundColor: '#FFFDD0',
                    height: 280,
                    width: 800,
                    margin: '2rem auto'
                }}
            >
                <div style={{
                    position: 'absolute',
                    backgroundColor: '#FFFFFF',
                    border: '2px solid #000',
                    boxShadow: '2px solid black',
                    height: 'auto',
                    width: "110%",
                    margin: '2rem auto'
                }}>
                    <h2 style={{
                        textAlign: "center",
                    }}>Update Project Details</h2>
                    <form onSubmit={onSubmit_update_form}>

                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label>Project Version:
                                    <input type="number"
                                        className="form-control"
                                        value={update_project_ver}
                                        onChange={(e) => update_setproject_ver(e.target.value)}

                                    />
                                </label>
                            </div>

                            <div className="form-group col-md-4">
                                <label>Project Start Date:
                                    <input type="date"
                                        className="form-control"
                                        value={update_Project_start_Date}
                                        onChange={(e) => update_setProject_start_Date(e.target.value)}

                                    />
                                </label>
                            </div>

                            <div className="form-group col-md-4">
                                <label>Project End Date:
                                    <input type="date"
                                        className="form-control"
                                        value={update_Project_end_Date}
                                        onChange={(e) => update_setProject_end_Date(e.target.value)}

                                    />
                                </label>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label>Project Id:
                                    <input type="number"
                                        className="form-control"
                                        value={update_project_ID}
                                        onChange={() => alert("cannot update project id")}

                                    />
                                </label>
                            </div>



                            <div className="form-group col-md-4">
                                <label>Project Name:
                                    <input type="text"
                                        className="form-control"
                                        value={update_project_name}
                                        onChange={() => alert("cannot update project name")}

                                    />
                                </label>
                            </div>


                            <div className="form-group col-md-4">
                                <label>Project Owner Id:
                                    <input type="number"
                                        className="form-control"
                                        value={update_project_owner_id}
                                        onChange={(e) => update_setproject_owner_id(e.target.value)}

                                    />
                                </label>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label>Project Owner Name:
                                    <input type="text"
                                        className="form-control"
                                        value={update_project_owner_name}
                                        onChange={(e) => update_setproject_owner_name(e.target.value)}

                                    />
                                </label>

                                <label>Private:
                                    <input type="text"
                                        className="form-control"
                                        value={update_Project_private}
                                        onChange={(e) => update_setProject_private(e.target.value)}

                                    />
                                </label>
                            </div>



                            <div className="form-group col-md-4">

                                <label>Project Description:

                                    <textarea id="w3review" name="w3review" rows="4" cols="50" value={update_Project_desc}
                                        onChange={(e) => update_setProject_desc(e.target.value)}>
                                    </textarea>
                                </label>

                            </div>
                        </div>

                        {/* <div className="form-group">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="gridCheck" />
                                <label className="form-check-label" for="gridCheck">
                                    Check me out
                                </label>
                            </div>
                        </div> */}
                        <div className="form-row">
                            <button type="reset" className="btn btn-danger mx-2" onClick={handle_modal_Close}>Cancel</button>
                            <button type="submit" className="btn btn-primary mx-2">Update</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    )
}
