import { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import './AllWidgets.css'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const AllWidget = (props) => {
    const baseUrl = process.env.REACT_APP_HEROKU_URL;
    const [dataofwidgets, setdataofwidgets] = useState([]);

    let usremail = window.localStorage.getItem("email");
    let projectid = window.localStorage.getItem("PROJECT_ID");

    const getdataofWidgets = () => {
        // http://127.0.0.1:8000/getAllWidgetsData?boardname=cytime1&email=jagtapdevkinandan50@gmail.com&prjId=21
        fetch(baseUrl + "/getAllWidgetsData?boardname=" + props.board + "&email=" + usremail + "&prjId=" + projectid)
            .then((response) =>
                response.json().then((data) => {
                    setdataofwidgets(data);
                })
            )
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        getdataofWidgets();
    }, [props.board]);

    return (
        <>
            {props.board == "" ? (
                <></>
            ) : (
                <>
                    {/* {dataofwidgets.map(da => (
                        <>
                            <div className="bigbox1" id="bigbox1">
                                <p className="boxofwidgets"> Total Cards <br></br>{da.CountofCard} </p>
                                <p className="boxofwidgets">
                                    <div style={{ width: 100, height: 100 }}>
                                        <CircularProgressbar value={da.DoneCard * 100 / da.CountofCard} text={`${Math.round(da.DoneCard * 100 / da.CountofCard)}%`} />
                                    </div>
                                </p>
                                <p className="boxofwidgets"> Total Done Cards <br></br>{da.DoneCard} </p>
                            </div>
                        </>
                    ))} */}

                    {dataofwidgets.map(da => (
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row justify-content-between">
                                        <div className="c-dashboardInfo col-lg-3 col-md-6">
                                            <div className="wrap">
                                                <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Total Project Items <br></br>Count</h4>
                                                <span className="hind-font caption-12 c-dashboardInfo__count">{da.CountofCard}</span>
                                            </div>
                                        </div>

                                        <div className="c-dashboardInfo col-lg-3 col-md-6">
                                            <div className="wrap">
                                                <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Total Project Items <br></br> Completed</h4>
                                                <span className="hind-font caption-12 c-dashboardInfo__count">{da.DoneCard}</span>
                                            </div>
                                        </div>

                                        <div className="c-dashboardInfo col-lg-3 col-md-6">
                                            <div className="wrap">
                                                <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">New Project Items Added in last 2 days</h4>
                                                <span className="hind-font caption-12 c-dashboardInfo__count">{da.CountofNewCard}</span>
                                            </div>
                                        </div>

                                        <div className="c-dashboardInfo col-lg-3 col-md-6">
                                            <div className="wrap">
                                                <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Updated Project Items in last 2 days</h4>
                                                <span className="hind-font caption-12 c-dashboardInfo__count">{da.CountofLastUpdate}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
                </>
            )
            }
        </>
    )
}
export default AllWidget