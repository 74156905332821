import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import Paper from "@material-ui/core/Paper";

import { useStyles } from "../../styles/CanvasCss";
import ShowCanvas from "./ShowCanvas";
import AddCanvas from "../AddCanvas";

function Canvases(props) {
  const classes = useStyles();
  const { canvas, setCanvas, getAllCanvas } = props;

  return (
    <div>
      <AddCanvas setCanvas={setCanvas} parentId={0} />
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="simple table" id="sortTable">
          <TableHead>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Board Id
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Board Name
            </TableCell>
            <TableCell></TableCell>
          </TableHead>
          {canvas.length > 0 &&
            canvas.map((canvas) => (
              <TableRow id="tr">
                <ShowCanvas canvas={canvas} 
                getAllCanvas={getAllCanvas}
                 />
              </TableRow>
            ))}
        </Table>
      </TableContainer>
    </div>
  );
}

export default Canvases;
