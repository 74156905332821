import React from "react";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  InputLabel,
  DialogTitle,
  Input,
  CircularProgress,
} from "@material-ui/core";


import PostAddIcon from "@material-ui/icons/PostAdd";
import { gridColumnsSelector } from "@material-ui/data-grid";

function AddCanvas(props) {
  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  const { setCanvas, parentId } = props;
  const [newCanvas, setNewCanvas] = React.useState(false);
  const [person, setPersons] = React.useState([]);
  const [newCanvasData, setNewCanvasData] = React.useState({});
  const [users, setUsers] = React.useState({});
  const [isPrivate, setPrivate] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [AllUsers, setAllUsers] = React.useState([]);
  const [Assigned, setAssigned] = React.useState([]);
  const [projectname, set_projectname] = React.useState("");


  const email = localStorage.getItem("email");
  React.useEffect(() => {

    if (localStorage.getItem("admin")) {
      getUsers();
      getAllUsers();
    }
  }, [])

  // const getUserData = () => {
  //   fetch(baseUrl + "User?Email=" + email)
  //     .then((response) =>
  //       response
  //         .json()
  //         .then(
  //           (data) => (
  //             console.log("User", data),

  //             data.Admin === "TRUE" && localStorage.setItem("admin", true),
  //             data.Canvas.length > 0
  //               ? (props.setCanvasData(data.Canvas),
  //                 localStorage.setItem("user", JSON.stringify(data)))
  //               : ""
  //           )
  //         )
  //     )
  //     .catch((err) => {
  //       /*setMode("offline");
  //           let collection = JSON.parse(localStorage.getItem("user"));
  //           console.log(collection.Canvas);
  //           setCanvasData(collection.Canvas);
  //           getData(collection.Canvas[0].CanvasId);*/
  //     });
  // };

  // console.log("PId", parentId);

  //console.log("Canvas", newCanvasData);
  //console.log("Person", person);

  const postDataAsCard = () => {

    const data = {
      CanvasId: parentId,
      Summary: newCanvasData.CanvasName,
      UserId: localStorage.getItem("userId"),
      Status: "To-Do",
      Type: "Board",
      IsPrivate: isPrivate
    };
    const apiURL = baseUrl;
    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    const options = {
      method: "POST",
      headers: newHeaders,
      body: JSON.stringify(data),
    };

    console.log("Data Options", options);

    fetch(apiURL, options)
      .then((res) => res.json())
      .then((result) => {
        console.log("FormData Res", result);

        props.refreshCanvas();
        //window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  //Handle New Canvas Change
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setNewCanvasData({ ...newCanvasData, [name]: value });
    console.log(newCanvasData);
  };
  const handleAChange = (event) => {
    setPersons(event.target.value);
  };

  //Get Users for loading
  const getUsers = () => {

    fetch(baseUrl + "/user/list")
      .then((response) =>
        response.json().then((data) => {
          console.log(`Display Users Data:- ${data}`);
          setUsers(data);
        })
      )
      .catch((err) => console.log(err));
  };

  //To get All the users
  const getAllUsers = () => {
    console.log("Ready to get ");
    const mainUrl = process.env.REACT_APP_HEROKU_URL;
    fetch(mainUrl + "/user/list")
      .then((response) =>
        response.json().then((data) => {
          console.log(data);
          console.log("Called Users Now");
          data.sort((a, b) => (a.usrName > b.usrName) ? 1 : ((b.usrName > a.usrName) ? -1 : 0))
          console.log(data);
          setAllUsers(data)
        })
      )
      .catch((err) => console.log(err));
  };

  //handle change for assigne for private board
  const handleAssignedUserChange = (event) => {
    let adEmail = window.localStorage.getItem("email");
    console.log(adEmail);
    const value = event.target.value
    var res;
    AllUsers.map((x) => {

      if (x.usrName === value) {
        res = x.usrEmailID;
      }
    })
    //setAssigned(res);
    Assigned.push(res);
    Assigned.push(adEmail);
    console.log(Assigned);
  }



  // Add New Canvas auto
  const addNewCanvas = () => {
    newCanvasData.crdType = "Board";
    newCanvasData.crdParentID = parentId.toString();
    newCanvasData.Type = "0";
    newCanvasData.crdAssignedUser = localStorage.getItem('email');
    newCanvasData.isPrivateBoard = isPrivate
    newCanvasData.Assigned = Assigned
    newCanvasData.prjId = projectname


    if (parentId != 0) {
      newCanvasData.crdStatus = "To-Do"

    }




    console.log("This is data to be sent", newCanvasData);
    submitNewCanvas(newCanvasData);
  };

  // Post new Canvas Request

  const submitNewCanvas = (data) => {

    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    // newHeaders.append("Accept", "application/json")
    // newHeaders.append('Access-Control-Allow-Origin', 'http://localhost:3000'); 
    // newHeaders.append('Access-Control-Allow-Credentials', 'true');
    setLoading(true);


    const options = {

      method: "POST",
      headers: newHeaders,
      body: JSON.stringify(data),

    }
    // var uniqueId;
    // console.log("In Submit");
    // console.log("Data", data);
    // setLoading(true);
    // const mainUrl = baseUrl + "Canvas";
    // console.log(JSON.stringify(data));
    // const newHeaders = new Headers();
    // newHeaders.append("Content-Type", "application/json");
    // const options = {
    //   method: "POST",
    //   headers: newHeaders,
    //   body: JSON.stringify(data),
    // };
    // fetch(mainUrl, options)
    //   .then((res) => res.json())
    //   .then((result) => {
    //     console.log("The way it comes result:-",result);
    //     fetch(baseUrl + "Admin/allCanvas")
    //       .then((response) =>
    //         response
    //           .json()
    //           .then(
    //             (data) => (
    //               (uniqueId = data[data.length - 1].CanvasId),
    //               console.log(data),
    //               console.log("U00", uniqueId),
    //               parentId !== 0
    //                 ? postDataAsCard(uniqueId)
    //                 : window.location.reload(),
    //               setCanvas(data)
    //             )
    //           )
    //       )
    //       .catch((err) => console.log(err));
    //     setNewCanvasData({});
    //     getUserData();
    //     setNewCanvas(false);
    //     //props.refreshCanvas();
    //     setPersons([]);
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     setNewCanvas(false);
    //     setNewCanvasData({});
    //     setPersons([]);
    //     setLoading(false);
    //   });
    // console.log(data);

    fetch(baseUrl + "/add", options).then(
      (res) => res.json()

    ).then((data) => {
      console.log(data);

      window.location.reload();
    }).catch(
      (err) => console.log(err)
    )
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const isEnabled = () => {
    if (newCanvasData.crdName !== "") {
      if (person.length > 0) {
        return true;
      }
    }
    return false;
  };

  var flag = isEnabled();



  return (
    <div>
      <div>
        <Dialog
          onClose={() => {
            setNewCanvas(false);
          }}
          open={newCanvas}
        >
          <DialogTitle>
            <h3>Add New Board</h3>
            <input type="checkbox" onChange={() => setPrivate(!isPrivate)} /> Private Board
            {
              isPrivate ?
                <Select
                  style={{ width: "100%" }}
                  name="crdAssignedUser"
                  /* defaultValue={getcurrentuserName}
                  onChange={handleAssignedUserChange} */
                  onChange={handleAssignedUserChange}
                >
                  {(
                    users.map((usr) => (
                      <MenuItem value={usr.usrName}>{usr.usrName}</MenuItem>
                    ))
                  )}
                </Select> : console.log("Else")
            }
            {/*   <Checkbox  />  */}
          </DialogTitle>

          <DialogContent>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={handleChange}
              value={newCanvasData.CanvasName}
              id="standard-text-input"
              label="Board Name"
              type="text"
              name="crdName"
              autoComplete="current-text"
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={(e) => set_projectname(e.target.value)}
              value={projectname}
              id="standard-text-input"
              label="Project Name"
              type="text"
              name="Project Name"
              autoComplete="current-text"
            />
            {/* <div style={{ margin: 10 }}>
              <InputLabel>Select Users</InputLabel>
              <Select
                name="AddUsersToCanvas"
                style={{ width: "100%" }}
                value={person}
                multiple
                input={<Input />}
                MenuProps={MenuProps}
                onChange={handleAChange}
              >
                {users.length > 0 ? (
                  users.map((usr) => (
                    <MenuItem value={usr.Email}>{usr.Name}</MenuItem>
                  ))
                ) : (
                  <MenuItem value={"0"}>N/A</MenuItem>
                )}
              </Select>
            </div> */}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={addNewCanvas}
            // disabled={flag === false}
            >

              {loading === true ? (
                <CircularProgress color="secondary" />
              ) : (
                <span>Add New</span>
              )}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setNewCanvas(false);
                setNewCanvasData({});
                setPersons([]);
              }}
            >
              cancel
            </Button>
          </DialogActions>
        </Dialog>
        <div className="nCanvas">
          <IconButton
            onClick={() => {
              setNewCanvas(true);
            }}
          >
            <Tooltip title="Add New Board" placement="top">
              <PostAddIcon
                fontSize="large"
                color="primary"
                style={{ margin: 5 }}
              />
            </Tooltip>
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default AddCanvas;
