import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SuccessSnackbar(props) {
  const { horizontal, vertical } = props;
  const [hState] = React.useState(horizontal);
  const [vState] = React.useState(vertical);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    props.setopenSuccessAlert(false);
    //setopenErrorAlert(false);
  };
  return (
    <div>
      <Snackbar
        style={{ marginTop: horizontal === "top" ? 70 : 0 }}
        open={props.openSuccessAlert}
        autoHideDuration={3000}
        onClose={handleAlertClose}
        anchorOrigin={{
          vertical: props.horizontal ? hState : "bottom",
          horizontal: props.vertical ? vState : "center",
        }}
        key={horizontal + vertical}
      >
        <Alert onClose={handleAlertClose} severity="success">
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

function ErrorSnackbar(props) {
  const { horizontal, vertical } = props;
  const [hState] = React.useState(horizontal);
  const [vState] = React.useState(vertical);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.setopenErrorAlert(false);
  };
  return (
    <div>
      <Snackbar
        open={props.openErrorAlert}
        autoHideDuration={3000}
        onClose={handleAlertClose}
        anchorOrigin={{
          vertical: props.horizontal ? hState : "bottom",
          horizontal: props.vertical ? vState : "center",
        }}
        key={horizontal + vertical}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {props.message ? props.message : "Something Went Wrong!"}
        </Alert>
      </Snackbar>
    </div>
  );
}

export { SuccessSnackbar, ErrorSnackbar };
