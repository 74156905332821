import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./view.css";
const View = () => {
  const location = useLocation();
  const { data } = location.state;
  console.log(data);
  let componentRef = useRef();
  return (
    <>
    <Link style={{paddingLeft:"10rem"}} to='/account'><ArrowBackIcon /> </Link>
      <div className="page-content container" ref={(el) => (componentRef = el)}>
        <div className="container px-0">
          <div className="row mt-4">
            <div className="col-12 col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div className="text-center text-150">
                    <span className="text-default-d3">{data.company_name}</span>
                  </div>
                </div>
              </div>

              <hr className="row brc-default-l1 mx-n1 mb-4" />

              <div className="row">
                <div className="col-sm-6">
                  <div>
                    <span className="text-sm text-grey-m2 align-middle">
                      To:
                    </span>
                    <span className="text-600 text-110 text-blue align-middle">
                      {/* Alex Doe */}
                      {data.desc}
                    </span>
                  </div>
                  <div className="text-grey-m2">
                    {/* <div className="my-1">Street, City</div>
                  <div className="my-1">State, Country</div> */}
                    <div className="my-1">
                      {/* <i className="fa fa-phone fa-flip-horizontal text-secondary"></i>{" "} */}
                      <b className="text-600">{data.company_contact}</b>
                    </div>
                  </div>
                </div>

                <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                  <hr className="d-sm-none" />
                  <div className="text-grey-m2">
                    <div className="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                      {data.type === 0 ? "Invoice" : "Quotation"}
                    </div>

                    <div className="my-2">
                      <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                      <span className="text-600 text-90">ID:</span> {data.id}
                    </div>

                    <div className="my-2">
                      <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                      <span className="text-600 text-90">Issue Date:</span>{" "}
                      {data.currDate}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="row text-600 text-white bgc-default-tp1 py-25">
                  <div className="d-none d-sm-block col-1">Sr#</div>
                  <div className="col-9 col-sm-3">Description</div>
                  <div className="d-none d-sm-block col-sm-2">Unit Price</div>
                  <div className="d-none d-sm-block col-4 col-sm-2">Qty</div>
                  <div className="d-none d-sm-block col-4 col-sm-2">Tax%</div>
                  <div className="col-9 col-sm-1">Total</div>
                  {/* <div className="d-none d-sm-block col-4 col-sm-2">Total</div> */}
                </div>

                <div className="text-95 text-secondary-d3">
                  {data.item.map((row, idx) => (
                    <div className="row mb-2 mb-sm-0 py-25">
                      <div className="d-none d-sm-block col-1">{idx + 1}</div>
                      <div className="col-9 col-sm-3">{row.itemDesc}</div>
                      <div className="d-none d-sm-block col-2">
                        {row.unitPrice}
                      </div>
                      <div className="d-none d-sm-block col-2 text-95">
                        {row.qty}
                      </div>
                      <div className="col-2 text-secondary-d2">{row.tax}</div>
                      <div className="col-9 col-sm-1">{row.total}</div>
                    </div>
                  ))}
                </div>

                <div className="row border-b-2 brc-default-l2"></div>

                <div className="row mt-3">
                  <div className="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                    Terms or Remarks<br></br>
                    {data.terms_remarks}
                  </div>

                  <div className="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                    <div className="row my-2">
                      <div className="col-7 text-right">SubTotal</div>
                      <div className="col-5">
                        <span className="text-120 text-secondary-d1">
                          {parseFloat(data.subTotal).toFixed(2)}
                        </span>
                      </div>
                    </div>

                    <div className="row my-2">
                      <div className="col-7 text-right">Discount(%)</div>
                      <div className="col-5">
                        <span className="text-110 text-secondary-d1">
                          {data.discount}%
                        </span>
                      </div>
                    </div>

                    <div className="row my-2 align-items-center bgc-primary-l3 p-2">
                      <div className="col-7 text-right">Total Amount</div>
                      <div className="col-5">
                        <span className="text-150 text-success-d3 opacity-2">
                          {parseFloat(data.total).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div>
                  <span className="text-secondary-d1 text-105">
                    Authorised signature
                  </span>
                  {/* <a
                  href=""
                  className="btn btn-info btn-bold px-4 float-right mt-3 mt-lg-0"
                >
                  Print
                </a> */}
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        variant="contained"
                        color="primary"
                        className="btn btn-info btn-bold px-4 float-right mt-3 mt-lg-0"
                      >
                        Print
                      </Button>
                    )}
                    content={() => componentRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
