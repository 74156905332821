import { useState, useEffect } from "react";
import { ResponsiveBar } from '@nivo/bar'

const CycleTimeGraph = (props) => {
    const baseUrl = process.env.REACT_APP_HEROKU_URL;
    const [dataofchart, setdataofchart] = useState([]);

    let usremail = window.localStorage.getItem("email");
    let projectid = window.localStorage.getItem("PROJECT_ID");

    const getdataofchart = () => {
        // http://127.0.0.1:8000/getcycletimedata?boardname=All&email=jagtapdevkinandan50@gmail.com&prjId=21
        fetch(baseUrl + "/getcycletimedata?boardname=" + props.board + "&email=" + usremail + "&prjId=" + projectid)
            .then((response) =>
                response.json().then((data) => {
                    setdataofchart(data);
                })
            )
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        getdataofchart();
    }, [props.board]);

    return (
        <>
            {props.board == "" ? (
                <></>
            ) : (
                <>
                    <ResponsiveBar
                        data={dataofchart}
                        keys={[
                            'cycletimedays',
                            'leadtimedays'
                        ]}
                        indexBy="boardname"
                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                        padding={0.3}
                        // groupMode="grouped"
                        layout="horizontal"
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={{ scheme: 'nivo' }}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: {
                                    id: 'fries'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'sandwich'
                                },
                                id: 'lines'
                            }
                        ]}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    1.6
                                ]
                            ]
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Number of Days',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Board',
                            legendPosition: 'middle',
                            legendOffset: -150
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    1.6
                                ]
                            ]
                        }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                        role="application"
                        ariaLabel="Nivo bar chart demo"
                        barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in country: " + e.indexValue }}
                    />
                </>
            )
            }
        </>
    )
}
export default CycleTimeGraph