import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Typography from '@material-ui/core/Typography';


import { useStyles } from "../../styles/CanvasCss";
import ShowUsers from "./ShowUsers";
import AddUser from "./AddUser";
import AddAdmin from "./AddAdmin";


function TableNew(props) {
  const { users, setUsers, canvas, getAllUsers } = props;

  const [openForm, setOpenForm] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  console.log("canvass",canvas)
  return (

    <div>
      <tr>

        <TableCell align="center">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {props.name}
        </TableCell>
        <TableCell></TableCell>
      </tr>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0,height: "auto", maxHeight: 560, width: 1250 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Typography variant="h6" gutterBottom component="div">

              <IconButton
                className={classes.icons}
                onClick={() => {
                  setOpenForm(true);
                }}
              >
                <Tooltip title={"Add"+props.name} placement="top">
                  <AddCircleOutlineIcon fontSize="large" color="primary" />
                </Tooltip>
              </IconButton>
              {props.name === "Users"?(<AddUser
                openForm={openForm}
                setOpenForm={setOpenForm}
                canvas={canvas}
                setUsers={setUsers}
              />):(<AddAdmin
                openForm={openForm}
                setOpenForm={setOpenForm}
                canvas={canvas}
                setUsers={setUsers}
                users={users}
              />)}
              

            </Typography>

            <Table name="SuperAdmin" style={{ height: "auto", maxHeight: 560, width: 1250 }} />
            <TableContainer component={Paper} className={classes.tableContainer} style={{ height: "auto", maxHeight: 560, width: 1250 }}>
              <Table stickyHeader aria-label="simple table" id="sortTable">
                <TableHead>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Name
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Email
                  </TableCell>
                  {/* <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Boards
                  </TableCell> */}
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableHead>
                {props.name === "SuperAdmin" &&
                  users.map((user) =>
                    user.usrRole === "SuperAdmin" ?
                      (
                        <TableRow id="tr">
                          <ShowUsers
                            user={user}
                            canvas={canvas}
                            getAllUsers={getAllUsers}
                            setUsers={setUsers}
                          />
                        </TableRow>
                      ) : (""))}
                {props.name === "Admin" &&
                  users.map((user) =>
                    user.usrRole === "Admin" ?
                      (
                        <TableRow id="tr">
                          <ShowUsers
                            user={user}
                            canvas={canvas}
                            getAllUsers={getAllUsers}
                            setUsers={setUsers}
                          />
                        </TableRow>
                      ) : (""))}
                {props.name === "Users" &&
                  users.map((user) =>
                    user.usrRole === "usrRead" || user.usrRole === "usrReadWrite"?
                      (
                        <TableRow id="tr">
                          <ShowUsers
                            user={user}
                            canvas={canvas}
                            getAllUsers={getAllUsers}
                            setUsers={setUsers}
                          />
                        </TableRow>
                      ) : (""))}




              </Table>
            </TableContainer>
          </Box>
        </Collapse>
      </TableCell>
    </div>


  )
};




export default TableNew;
