import React from "react";
import { useDrag } from "react-dnd";
import { Link, useParams } from "react-router-dom";
import { v1 as uuid } from "uuid";
import smallcard from "../../assets/images/smallcard2.png";
import smallcard2 from "../../assets/images/smallcard3.png";
import defaulttheme from "../../assets/images/defaulttheme.png";
import overdue from "../../assets/images/overdue.png";
import QrGenerator from "./QRcode/QrGenerator";

import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";

//MUI stuffs
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ArrowBackRounded, Share, ShareSharp } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/Create";
import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import NativeSelect from "@material-ui/core/NativeSelect";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import { RWebShare } from "react-web-share";
import Grow from "@material-ui/core/Grow";
//Components
import UpdateCard from "./UpdateCard";
import Epic from "../../assets/images/Epic.png";
import Story from "../../assets/images/Story.png";
import Task from "../../assets/images/Task.png";
import Defect from "../../assets/images/Defect.png";
import Board from "../../assets/images/Board.png";
import { ItemTypes } from "../../utils/Items";

import { SuccessSnackbar, ErrorSnackbar } from "./Snackbar";

import { useStyles } from "../styles/CardCss";

const setting_id = localStorage.getItem("setting_id");

const CanvasCard = (props) => {
  const typedata = JSON.parse(localStorage.getItem("static")).Type;
  // Setting up some states to be used

  React.useEffect(() => {
    console.log("canvas card");
    console.log(props);
    console.log(subscribed);
  });

  const { url } = useParams();
  const status = JSON.parse(localStorage.getItem("static")).Status;
  const [cards, setCards] = React.useState({}); // State for each incoming card
  const [loading, setLoading] = React.useState(false); // State for loading
  const [open, setOpen] = React.useState(false); // State for enlargening of card
  const { card, col, getData, board, CanvasId } = props; // Getting props from Canvas.js
  const [openForm, setOpenForm] = React.useState(false); // State for opening/closing add card form
  const [subscribed, setsubscribed] = React.useState(
    card.crdAssignedUser === localStorage.getItem("email") ||
      card.crdReporter === localStorage.getItem("email")
  );
  const [update, setUpdate] = React.useState(false);
  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  const [openSuccessAlert, setopenSuccessAlert] = React.useState(false);
  const [opensubSuccessAlert, setopensubSuccessAlert] = React.useState(false);
  const [openunsubSuccessAlert, setopenunsubSuccessAlert] =
    React.useState(false);

  const [openSuccessshareAlert, setopenSuccessshareAlert] =
    React.useState(false);
  const [openSuccessDelAlert, setopenSuccessDelAlert] = React.useState(false);
  const [openErrorAlert, setopenErrorAlert] = React.useState(false);

  const overdueSetting = localStorage.getItem("overdueSetting");

  const { canvasData } = props;
  var date = new Date(card.crdDueDate);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Function taken from React DND documentation to perform operations while dragging card
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: "card",
      col: col,
      Card: card,
      // LaneId: col.LaneId,
      LaneId: col.Status,
      CanvasId: CanvasId,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  // ToolTip to display detailed information of cards on hover

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  // State for data to be send for updating bakend
  const [data, setData] = React.useState({
    crdAssignedUser: card.crdAssignedUser,
    crdStatus: col.Status,
    crdName: card.crdName,
    crdDescription: card.crdDescription,
    crdDueDate: card.crdDueDate,
    crdBusinessValue: card.crdBusinessValue,
    crdEmergency: card.crdEmergency,
    crdPriority: card.crdPriority,
    crdSprintName: card.crdSprintName,
    crdType: card.crdType,
    crdOverdue: card.crdOverdue,
    crdAcceptanceCriteria: card.crdAcceptanceCriteria,
    Withdraw: 1,
  });

  const classes = useStyles();

  // Function to open enlarged cards
  const handleCardOpen = (card) => {
    setOpen(true);
    setCards(card);
  };

  // Function to close enlarged cards
  const handleCardClose = () => {
    setOpen(false);
  };

  const apiURL = baseUrl;

  //sendmail

  const sendmail = () => {
    var data = {
      Subject: "Delete Notification",
      From: "",
      To: "",
      CC: [
        ...card.crdUpdateSubscriptions,
        card.crdReporter,
        card.crdAssignedUser,
      ],
      Content: `Deleted card ${card.DynamicID}`,
    };

    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    const options = {
      method: "POST",
      headers: newHeaders,
      body: JSON.stringify(data),
    };

    fetch(baseUrl + "/sendmail", options).then((res) => {
      res.json().then((data) => {
        console.log(data);
        console.log("Mail sent after deleting the card");
      });
    });
  };

  //subscribe
  const subscribe = () => {
    fetch(
      baseUrl +
        "/subscribe?email=" +
        localStorage.getItem("email") +
        "&&id=" +
        card.DynamicID
    ).then((res) => {
      console.log(res);
      res.json().then((data) => {
        console.log(data);
        console.log("does it work");
        setsubscribed(true);
        setopensubSuccessAlert(true);
      });
    });
  };

  //unsubscribe
  const unsubscribe = () => {
    fetch(
      baseUrl +
        "/unsubscribe?email=" +
        localStorage.getItem("email") +
        "&&id=" +
        card.DynamicID
    ).then((res) => {
      res.json().then((data) => {
        console.log(data);
        setsubscribed(false);
        setopenunsubSuccessAlert(true);
      });
    });
  };

  // Function to post a PUT url for soft deletion of cards

  const delCard = () => {
    setLoading(true);
    const newData = data;

    newData.crdStatus = "Withdrawn";
    setData(newData);
    console.log("NewData", data);
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    fetch(apiURL + "/update?Id=" + card.DynamicID, requestOptions)
      .then((response) =>
        response.ok
          ? response.json().then((data) => {
              console.log(data);
              handleCardClose();
              sendmail(card);
              setLoading(false);
              getData(CanvasId);
              setopenSuccessDelAlert(true);
            })
          : (setLoading(false), setopenErrorAlert(true))
      )
      .catch((err) => console.log(err));
  };

  // Function to post a PUT url for updation of lanes of card

  const handleStatusChange = (event) => {
    console.log("Val", event.target.value);
    const newData = data;
    if (event.target.value == "Ready") {
      var tempDate = new Date();
      var date =
        tempDate.getFullYear() +
        "-" +
        (tempDate.getMonth() + 1) +
        "-" +
        tempDate.getDate() +
        " " +
        tempDate.getHours() +
        ":" +
        tempDate.getMinutes() +
        ":" +
        tempDate.getSeconds() +
        ".000000";
      newData.Status_readydate = date;
    }
    if (event.target.value == "Done") {
      var tempDate = new Date();
      var date =
        tempDate.getFullYear() +
        "-" +
        (tempDate.getMonth() + 1) +
        "-" +
        tempDate.getDate() +
        " " +
        tempDate.getHours() +
        ":" +
        tempDate.getMinutes() +
        ":" +
        tempDate.getSeconds() +
        ".000000";
      newData.Status_donedate = date;
    }
    newData.crdStatus = event.target.value;

    newData.Withdraw = 0;
    newData.crdParentId = CanvasId;
    newData.crdOverdue = 0;

    newData.crdName = card.crdName;
    newData.crdDescription = card.crdDescription;
    newData.crdDueDate = card.crdDueDate;
    newData.crdBusinessValue = card.crdBusinessValue;
    newData.crdEmergency = card.crdEmergency;
    newData.crdPriority = card.crdPriority;
    newData.crdType = card.crdType;
    newData.crdAcceptanceCriteria = card.crdAcceptanceCriteria;

    setData(newData);
    console.log("Newdata", data);

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    console.log(requestOptions);
    fetch(apiURL + "/update?Id=" + card.DynamicID, requestOptions)
      .then((response) =>
        response.ok
          ? response.json().then((data) => {
              setopenSuccessAlert(true);
              getData(CanvasId);
            })
          : setopenErrorAlert(true)
      )
      .catch((err) => console.log(err));
  };

  const editThis = () => {
    setOpenForm(true);
    setUpdate(true);
  };

  var opacity = isDragging ? 0.5 : 1;

  const LANE_CONFIG = {
    L0: ["Ready", "In-Progress", "Under Review"],
    L1: ["To-Do", "In-Progress", "Under Review"],
    L2: ["Ready", "Under Review", "Done"],
    L3: ["Ready", "In-Progress", "Done"],
    L4: [],
  };

  return (
    <Grow in={true} timeout={1200}>
      <div>
        {props.check ? (
          <div
            className={
              card.crdType === "Board" ? classes.boardCard : classes.card
            }
            ref={drag}
            style={{
              // backgroundColor:"black",
              opacity: opacity,
              backgroundImage:
                card.crdOverdue && overdueSetting === "1"
                  ? `url(${overdue})`
                  : setting_id === "1"
                  ? `url(${smallcard})`
                  : setting_id === "2"
                  ? `url(${smallcard2})`
                  : `url(${defaulttheme})`,
              border: card.crdEmergency ? "5px solid red" : "",
              height: card.crdEmergency ? "120px" : "",
            }}
          >
            <IconButton
              className={classes.expandIcon}
              onClick={() => handleCardOpen(card)}
            >
              <AspectRatioIcon color="primary" />
            </IconButton>
            <Typography style={{ position: "absolute", bottom: 15, right: 15 }}>
              {card.DynamicID}
            </Typography>
            <div style={{ paddingRight: "5vw", paddingTop: "1.2vh" }}>
              <QrGenerator
                loc={`${window.location.origin}/qr/${card.DynamicID}`}
              />
            </div>
          </div>
        ) : (
          <div
            className={
              card.crdType === "Board" ? classes.boardCard : classes.card
            }
            ref={drag}
            style={{
              // backgroundColor:"black",
              opacity: opacity,
              backgroundImage:
                card.crdOverdue && overdueSetting === "1"
                  ? `url(${overdue})`
                  : setting_id === "1"
                  ? `url(${smallcard})`
                  : setting_id === "2"
                  ? `url(${smallcard2})`
                  : `url(${defaulttheme})`,
              border: card.crdEmergency ? "5px solid red" : "",
              height: card.crdEmergency ? "120px" : "",
            }}
          >
            {card.crdType === "Board" ? (
              <>
                <Avatar className={classes.type}>
                  <LightTooltip
                    title={<p style={{ fontSize: 15 }}> {card.crdType} </p>}
                    placement="top-start"
                  >
                    <img
                      src={Board}
                      style={{ height: "30px", width: "30px" }}
                    />
                  </LightTooltip>
                </Avatar>
                {/*<NativeSelect
                id="demo-customized-select-native"
                value={col.Status}
                style={{
                  position: "absolute",
                  top: 10,
                  right: 40,
                  width: "100px",
                }}
                onChange={handleStatusChange}
              >
                
                {board.map((col, index) => (
                  <option key={index} value={col.Status}>
                    {col.Status}
                  </option>
                ))}
                </NativeSelect>*/}
                <Typography>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    className={classes.smallCardSummary}
                    to={{
                      pathname: `/board/${card.DynamicID}`,
                      key: uuid(),
                      state: { canvases: canvasData },
                    }}
                  >
                    {card.crdName}
                  </Link>
                </Typography>
              </>
            ) : (
              <div>
                <div className="imageLogo">
                  {/* <Avatar className={classes.type}>
                  {card.crdType === typedata[0] ? (
                    <LightTooltip
                      title={<p style={{ fontSize: 15 }}> {card.crdType} </p>}
                      placement="top-start"
                    >
                      <img
                        src={Epic}
                        style={{ height: "50px", width: "50px" }}
                      />
                    </LightTooltip>
                  ) : card.crdType === typedata[1] ? (
                    <LightTooltip
                      title={<p style={{ fontSize: 15 }}> {card.crdType} </p>}
                      placement="top-start"
                    >
                      <img
                        src={Story}
                        style={{ height: "40px", width: "40px" }}
                      />
                    </LightTooltip>
                  ) : card.crdType === typedata[2] ? (
                    <LightTooltip
                      title={<p style={{ fontSize: 15 }}> {card.crdType} </p>}
                      placement="top-start"
                    >
                      <img
                        src={Task}
                        style={{ height: "50px", width: "50px" }}
                      />
                    </LightTooltip>
                  ) : card.crdType === typedata[3] ? (
                    <LightTooltip
                      title={<p style={{ fontSize: 15 }}> {card.crdType} </p>}
                      placement="top-start"
                    >
                      <img
                        src={Defect}
                        style={{ height: "50px", width: "50px" }}
                      />
                    </LightTooltip>
                  ) : (
                    ""
                  )}
                </Avatar> */}
                </div>
                <Typography
                  style={{ position: "absolute", bottom: 15, right: 15 }}
                >
                  {card.DynamicID}
                </Typography>
                <LightTooltip
                  title={<p style={{ fontSize: 15 }}> {card.crdName} </p>}
                  placement="top-start"
                >
                  <Typography
                    variant="caption"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCardOpen(card)}
                  >
                    <p className={classes.smallCardSummary}>
                      {card.crdName && card.crdName.substring(0, 6)}
                    </p>
                  </Typography>
                </LightTooltip>
                <IconButton
                  className={classes.expandIcon}
                  onClick={() => handleCardOpen(card)}
                >
                  <AspectRatioIcon color="primary" />
                </IconButton>
                {card.crdAssignedUser !== "" ? (
                  <Avatar className={classes.assignToFront}>
                    <div
                      style={{
                        fontSize: 15,
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {card.crdAssignedUser &&
                        card.crdAssignedUser.substring(0, 2).toUpperCase()}
                    </div>
                  </Avatar>
                ) : (
                  ""
                )}
              </div>
            )}
            <NativeSelect
              id="demo-customized-select-native"
              value={col.Status}
              style={{
                position: "absolute",
                top: 10,
                right: 40,
                width: "100px",
              }}
              onChange={handleStatusChange}
            >
              <option value={col.Status} disabled>
                {col.Status}
              </option>

              {status
                .find((item) => item.name == col.Status)
                .transitions.map((x) => (
                  <option value={x}>{x}</option>
                ))}

              {/* {board.map(
              (cols, index) =>
                col.Status === cols.Status && (
                  <option key={index} value={cols.Status} disabled>
                    {cols.Status}
                  </option>
                )
            )}
            {col.Status === "To-Do" &&
              board.map(
                (cols, index) =>
                  
                  LANE_CONFIG.L0.includes(cols.Status) && (
                    <option key={index} value={cols.Status}>
                      {cols.Status}
                    </option>
                  )
              )}
            {col.Status === "Ready" &&
              board.map(
                (cols, index) =>
                  LANE_CONFIG.L1.includes(cols.Status) && (
                    <option key={index} value={cols.Status}>
                      {cols.Status}
                    </option>
                  )
              )}
            {col.Status === "In-Progress" &&
              board.map(
                (cols, index) =>
                  LANE_CONFIG.L2.includes(cols.Status) && (
                    <option key={index} value={cols.Status}>
                      {cols.Status}
                    </option>
                  )
              )}
            {col.Status === "Under Review" &&
              board.map(
                (cols, index) =>
                  LANE_CONFIG.L3.includes(cols.Status) && (
                    <option key={index} value={cols.Status}>
                      {cols.Status}
                    </option>
                  )
              )}
            {col.Status === "Done" &&
              board.map(
                (cols, index) =>
                  LANE_CONFIG.L4.includes(cols.Status) && (
                    <option key={index} value={cols.Status}>
                      {cols.Status}
                    </option>
                  )
              )} */}
            </NativeSelect>
          </div>
        )}

        {/* {props.check && (
            <QrGenerator
              loc={`${window.location.origin}/qr/${card.DynamicID}`}
            />
          )} */}
        <Dialog
          open={open}
          onClose={handleCardClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          classes={{
            root: classes.root,
            paper: classes.paper,
          }}
        >
          {/* <div>
            <Avatar className={classes.type}>
              {card.crdType === typedata[0] ? (
                <LightTooltip
                  title={<p style={{ fontSize: 15 }}> {card.crdType} </p>}
                  placement="top-start"
                >
                  <img src={Epic} style={{ height: "50px", width: "50px" }} />
                </LightTooltip>
              ) : card.crdType === typedata[1] ? (
                <LightTooltip
                  title={<p style={{ fontSize: 15 }}> {card.crdType} </p>}
                  placement="top-start"
                >
                  <img src={Story} style={{ height: "40px", width: "40px" }} />
                </LightTooltip>
              ) : card.crdType === typedata[2] ? (
                <LightTooltip
                  title={<p style={{ fontSize: 15 }}> {card.crdType} </p>}
                  placement="top-start"
                >
                  <img src={Task} style={{ height: "50px", width: "50px" }} />
                </LightTooltip>
              ) : card.crdType === typedata[3] ? (
                <LightTooltip
                  title={<p style={{ fontSize: 15 }}> {card.crdType} </p>}
                  placement="top-start"

                  
                >
                  <img src={Defect} style={{ height: "50px", width: "50px" }} />
                </LightTooltip>
              ) : card.crdType === "Board" ? (
                <LightTooltip
                  title={<p style={{ fontSize: 15 }}> {card.crdType} </p>}
                  placement="top-start"
                >
                  <img src={""} style={{ height: "50px", width: "50px" }} />
                </LightTooltip>
              ) : (
                ""
              )}
            </Avatar>
          </div> */}

          <IconButton className={classes.editIcon} onClick={editThis}>
            <CreateIcon color="primary" />
          </IconButton>
          <RWebShare
            data={{
              text: "Link to your card",
              // url: url,
              url: `${window.location.href}/:${card.DynamicID}`,
              title: "Share the card",
            }}
            onClick={() => setopenSuccessshareAlert(true)}
          >
            <IconButton className={classes.shareIcon}>
              <Share color="primary" />
            </IconButton>
          </RWebShare>
          {/* <div style={{ position: "absolute", paddingTop: "5vh" }}>
            {props.check && (
              <QrGenerator
                loc={`${window.location.origin}/qr/${card.DynamicID}`}
              />
            )}
          </div> */}

          {/* <Link to={{pathname:`/board/:${props.CanvasId}/:${card.DynamicID}` ,state:{data: `${card.DynamicID}`}}}>
            <IconButton className={classes.shareIcon}>
              <Share  color="primary"/>
            </IconButton>
          </Link> */}

          {subscribed ? (
            card.crdAssignedUser === localStorage.getItem("email") ||
            card.crdReporter === localStorage.getItem("email") ? (
              ""
            ) : (
              <IconButton className={classes.subIcon} onClick={unsubscribe}>
                <LinkOffIcon />
              </IconButton>
            )
          ) : (
            <IconButton className={classes.subIcon} onClick={subscribe}>
              <LinkIcon />
            </IconButton>
          )}

          {/* Calling Update Card Component */}

          <UpdateCard
            openForm={openForm}
            setOpenForm={setOpenForm}
            update={update}
            getData={getData}
            setUpdate={setUpdate}
            loaded={card}
            sts={props.col.Status}
            canvasId={props.CanvasId}
            userId={props.userId}
            refreshCanvas={props.refreshCanvas}
            canvasData={canvasData}
            setOpen={setOpen}
            open={open}
          />

          <Typography className={classes.IssueId}>{cards.DynamicID}</Typography>
          {cards.crdDueDate !== "" ? (
            <Typography className={classes.dueDate}>
              {date.getDate() +
                "-" +
                monthNames[date.getMonth()] +
                "-" +
                date.getFullYear()}
            </Typography>
          ) : (
            ""
          )}
          {loading && <LinearProgress className={classes.loadingProgress} />}

          <LightTooltip
            title={<p style={{ fontSize: 15 }}> {card.crdName} </p>}
            placement="top-start"
          >
            <Typography
              display="block"
              variant="body1"
              align="center"
              className={classes.summary}
            >
              {cards.crdName}
            </Typography>
          </LightTooltip>

          <LightTooltip
            title={<p style={{ fontSize: 15 }}> {card.crdDescription} </p>}
            placement="top-start"
          >
            <Typography
              align="left"
              display="block"
              variant="body2"
              className={classes.description}
            >
              {cards.crdDescription}
            </Typography>
          </LightTooltip>
          {card.crdAssignedUser !== "" ? (
            <Avatar className={classes.assignTo}>
              <div style={{ fontSize: 15, color: "black", fontWeight: "bold" }}>
                {cards.crdAssignedUser &&
                  cards.crdAssignedUser.substring(0, 2).toUpperCase()}
              </div>
            </Avatar>
          ) : (
            ""
          )}
          <Typography align="center" className={classes.businessValue}>
            {cards.crdBusinessValue}
          </Typography>

          <IconButton className={classes.delIcon}>
            <DeleteIcon color="secondary" onClick={() => delCard()} />
          </IconButton>
          <Typography className={classes.arrow}>
            {cards.crdPriority === "High" ? (
              <ArrowUpwardIcon color="primary" fontSize="large" />
            ) : cards.crdPriority === "Medium" ? (
              <ArrowForwardIcon color="primary" fontSize="large" />
            ) : (
              <ArrowDownwardIcon color="primary" fontSize="large" />
            )}
          </Typography>
        </Dialog>
        <SuccessSnackbar
          openSuccessAlert={openSuccessshareAlert}
          setopenSuccessAlert={setopenSuccessshareAlert}
          message="Card Shared Successfully!"
          horizontal="top"
          vertical="center"
        />
        <SuccessSnackbar
          openSuccessAlert={openSuccessAlert}
          setopenSuccessAlert={setopenSuccessAlert}
          message="Card Moved Successfully!"
          horizontal="top"
          vertical="center"
        />
        <SuccessSnackbar
          openSuccessAlert={openSuccessDelAlert}
          setopenSuccessAlert={setopenSuccessDelAlert}
          message="Card Deleted Successfuly!"
          horizontal="top"
          vertical="center"
        />
        <SuccessSnackbar
          openSuccessAlert={opensubSuccessAlert}
          setopenSuccessAlert={setopensubSuccessAlert}
          message="Subscribed to the Card!"
          horizontal="top"
          vertical="center"
        />
        <SuccessSnackbar
          openSuccessAlert={openunsubSuccessAlert}
          setopenSuccessAlert={setopenunsubSuccessAlert}
          message="UnSubscribed to the Card!"
          horizontal="top"
          vertical="center"
        />
        <ErrorSnackbar
          openErrorAlert={openErrorAlert}
          setopenErrorAlert={setopenErrorAlert}
          horizontal="top"
          vertical="center"
        />
      </div>
    </Grow>
  );
};

export default CanvasCard;
