import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { v1 as uuid } from "uuid";
//MUI Stuffs
import Grid from "@material-ui/core/Grid";
import { withStyles, useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grow from "@material-ui/core/Grow";
import Header from "./Header";
import AddCanvas from "./AddCanvas";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import { AppBar, Toolbar } from "@material-ui/core";
import { useStyles } from "../styles/MainBoardCss";
import Board from "../../assets/images/Board.png";
import { LocalGasStation } from "@material-ui/icons";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import omnivuelogo from "../../assets/images/omnivuelogo.jpg";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ViewModuleIcon from "@mui/icons-material/ViewModule";

import ViewListIcon from "@mui/icons-material/ViewList";
import ToggleButton from "@mui/material/ToggleButton";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import Button from "@mui/material/Button";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveIcon from "@mui/icons-material/Remove";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import Modal from "@material-ui/core/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import Alert from "react-bootstrap/Alert";
import Collapse from "react-bootstrap/Collapse";

function Sprint() {
  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  const check_admin = localStorage.getItem("admin");
  const [loading, setLoading] = React.useState(true);
  const [isData, setisData] = React.useState(false);
  const [sprintStatus, setSprintStatus] = React.useState("");
  const email = localStorage.getItem("email");
  const project_id = localStorage.getItem("PROJECT_ID");
  const project_name = localStorage.getItem("PROJECT_NAME");
  const classes = useStyles();

  const [canvasData, setCanvasData] = React.useState([]);


  function handle_close_alert(e) {
    e.preventDefault();
    set_show_alert(false);
    // window.location.reload();
  }

  // Getting user data as soon as component is rendered
  React.useEffect(() => {
    getUserData();
  }, []);

  const theme = useTheme();

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  // Getting User Data
  const getUserData = () => {
    // const email = localStorage.getItem("email");
    const projectid = window.localStorage.getItem("PROJECT_ID");
    // console.log(email);

    fetch(baseUrl + "/allsprintcard?prjId=" + projectid).then((res) => {
      console.log(res);
      res.json().then((data) => {
        console.log(data);
        setCanvasData(data);
        setisData(true);
        setLoading(false);
      });
    });
  };

  const [toggle, setToggle] = useState(false);
  const toggleFunc = React.useCallback(() => setToggle(!toggle));

  // add project using api
  const [add_ss_active, add_setss_active] = useState("");
  const [add_ss_description, add_setss_description] = useState("");
  const [add_ss_end_date, add_setss_end_date] = useState("");
  // const [add_project_ID, add_setproject_ID] = useState("");
  const [add_ss_end_time, add_setss_end_time] = useState("");
  const [add_ss_goals, add_setss_goals] = useState("");
  const [add_ss_id, add_setss_id] = useState("");
  const [add_ss_name, add_setss_name] = useState("");
  const [add_ss_start_date, add_setss_start_date] = useState("");
  const [add_ss_start_time, add_setss_start_time] = useState("");
  const [add_ss_version, add_setss_version] = useState("");
  const [add_prj_id, add_setprj_id] = useState("");

  async function add_handleSubmit(event) {
    event.preventDefault();
    await fetch(baseUrl + `/addsprint`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ssActive: `${add_ss_active}`,
        ssID: `${add_ss_id}`,
        ssName: `${add_ss_name}`,
        prjId: `${project_id}`,
        ssDescription: `${add_ss_description}`,
        ssVer: "1",
        ssStartTime: `${add_ss_start_time}`,
        ssStartDate: `${add_ss_start_date}`,
        ssEndDate: `${add_ss_end_date}`,
        ssEndTime: `${add_ss_end_time}`,
        ssGoals: `${add_ss_goals}`,
      }),
    })
      .then((response) => response.json())
      .then((json) => console.log(json));

    // alert("add data successfully")
    set_show_alert(true);
    set_alert_message("Sprint Added Succesfully");
    set_alert_type("success");
    setTimeout(function () {
      window.location.reload();
    }, 1000);
  }

  // delete project
  function handle_delete(event, user) {
    event.preventDefault();
    // alert(`You Want to delete ${user} Project`);
    fetch(baseUrl + `/deletesprint?Id=${user}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) =>
        // this is the data we get after doing the delete request, do whatever you want with this data
        console.log(data)
      );
    // window.location.reload();
    set_show_alert(true);
    set_alert_message("Delete Project successfully");
    set_alert_type("success");
    setTimeout(function () {
      window.location.reload();
    }, 1000);
  }

  const [show_alert, set_show_alert] = useState(false);
  const [alert_message, set_alert_message] = useState("");
  const [alert_type, set_alert_type] = useState("");

  const updateSprintStatus = (e, canvas) => {
    console.log(canvas);
    console.log(e.target.outerText);
    setSprintStatus();

    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    //console.log(data);
    const options = {
      method: "POST",
      headers: newHeaders,
    };

    console.log("Options", options);
    fetch(
      baseUrl +
      "/sprintStatusUpdate?id=" +
      canvas.ssName +
      "&status=" +
      e.target.outerText,
      options
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  $(document).ready(function () {
    $("#search").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      $("#dataTable #tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
    });
  });

  return (
    <div>
      <Header x="Boards" />
      <AppBar onClick={() => window.scroll(0,0)} position="sticky" className={classes.appbar}>
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}

          <Typography className={classes.title} variant="h6" noWrap>
            SuperSprint
            <div>
              <p style={{ color: "red", fontSize: 12 }}>{project_name}</p>
            </div>
          </Typography>

          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              id="search"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div>
            <img src={omnivuelogo} className={classes.omni} />
          </div>
        </Toolbar>
      </AppBar>

      <div
        style={{
          marginLeft: "2.5rem",
          marginTop: "0.5rem",

          // height: "100rem",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={{ background: "#fff" }}>
            <Toolbar
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "55%",
              }}
            >
              {check_admin ? (
                <>
                  <div>
                    <Button variant="outlined" onClick={toggleFunc}>
                      <IconButton>
                        {toggle ? (
                          <RemoveIcon color="primary" size="large" />
                        ) : (
                          <AddIcon color="primary" size="large" />
                        )}
                      </IconButton>
                    </Button>
                    {/* <button onClick={toggleFunc}>Toggle Collapse</button> */}
                    <Collapse in={toggle}>
                      <div style={{ color: "Black" }}>
                        <form onSubmit={add_handleSubmit}>
                          <div className="form-row">
                            {/* <label>Project Id:
                                                    <input
                                                        type="number"
                                                        value={add_project_ID}
                                                        onChange={(e) => add_setproject_ID(e.target.value)}
                                                        required
                                                    />
                                                </label> */}
                            <div className="form-row">
                              <div className="form-group col-md-3">
                                <label>
                                  Sprint Name:
                                  <input
                                    type="text"
                                    value={add_ss_name}
                                    onChange={(e) =>
                                      add_setss_name(e.target.value)
                                    }
                                    required
                                  />
                                </label>
                              </div>

                              <div className="form-group col-md-3">
                                <label>
                                  Sprint Id:
                                  <input
                                    type="number"
                                    value={add_ss_id}
                                    onChange={(e) =>
                                      add_setss_id(e.target.value)
                                    }
                                    required
                                  />
                                </label>
                              </div>

                              {/* <div className="form-group col-md-3">
                  <label>
                    Sprint Active:
                    <input
                      type="number"
                      value={add_ss_active}
                      onChange={(e) => add_setss_active(e.target.value)}
                      required
                    />
                  </label>
                </div> */}

                              <div className="form-group col-md-3">
                                <label>
                                  Sprint Description:
                                  <input
                                    type="text"
                                    value={add_ss_description}
                                    onChange={(e) =>
                                      add_setss_description(e.target.value)
                                    }
                                    required
                                  />
                                </label>
                              </div>
                            </div>

                            {/* <div className="form-group col-md-3">
                  <label>
                    Sprint StartDate:
                    <input
                      type="date"
                      value={add_ss_start_date}
                      onChange={(e) => add_setss_start_date(e.target.value)}
                      required
                    />
                  </label>
                </div> */}

                            <div className="form-group col-md-3">
                              <label>
                                Sprint EndDate:
                                <input
                                  type="date"
                                  value={add_ss_end_date}
                                  onChange={(e) =>
                                    add_setss_end_date(e.target.value)
                                  }
                                  required
                                />
                              </label>
                            </div>

                            <div className="form-group col-md-3">
                              <label>
                                Sprint EndTime:
                                <input
                                  type="time"
                                  value={add_ss_end_time}
                                  onChange={(e) =>
                                    add_setss_end_time(e.target.value)
                                  }
                                  required
                                />
                              </label>
                            </div>

                            <div className="form-group col-md-3">
                              <label>
                                Sprint StartTime:
                                <input
                                  type="time"
                                  value={add_ss_start_time}
                                  onChange={(e) =>
                                    add_setss_start_time(e.target.value)
                                  }
                                  required
                                />
                              </label>
                            </div>

                            <div className="form-group col-md-1">
                              <label>
                                Sprint Goals:
                                <input
                                  type="text"
                                  value={add_ss_goals}
                                  onChange={(e) =>
                                    add_setss_goals(e.target.value)
                                  }
                                  required
                                />
                              </label>
                            </div>

                            {/* <div className="form-group col-md-3">
                  <label>
                    Project Id:
                    <input
                      type="number"
                      value={add_prj_id}
                      onChange={(e) => add_setprj_id(e.target.value)}
                      required
                    />
                  </label>
                </div> */}

                            {/* <div className="form-group col-md-1">
                  <label>
                    Sprint Version:
                    <input
                      type="number"
                      value="add_ss_version"
                      onChange={(e) => add_setss_version(e.target.value)}
                    />
                  </label>
                </div> */}
                          </div>

                          <input
                            className="form-group col-md-2"
                            type="submit"
                          />
                        </form>
                      </div>
                    </Collapse>
                  </div>
                </>
              ) : (
                <h4 style={{ color: "red" }}>Available Sprints</h4>
              )}
            </Toolbar>
          </AppBar>
        </Box>
      </div>

      {show_alert ? (
        <Alert variant={alert_type} style={{ width: "100%" }}>
          <a
            onClick={handle_close_alert}
            class="close"
            data-dismiss="alert"
            aria-label="close"
          >
            &times;
          </a>
          <p>{alert_message}</p>
        </Alert>
      ) : (
        <></>
      )}

      {loading ? (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            marginLeft: "-50px",
            marginTop: "-50px",
            padding: theme.spacing(1, 9),
          }}
        >
          <CircularProgress size={100} />
          <h5 style={{ fontSize: 20 }}> Loading..... </h5>
        </div>
      ) : (
        <>
          {console.log(isData)}
          {!isData ? (
            <div>
              <h2
                style={{ position: "absolute", top: "45%", left: "35%" }}
              >
                {" "}
                No Sprint Found! Create one.....
                <br />
                <AddCanvas parentId={0} />
              </h2>{" "}
            </div>
          ) : (
            <>
              <div style={{paddingLeft:65}}>
                <Grid
                  id="dataTable"
                  container
                  className={classes.root}
                  spacing={2}
            
                >
                  {console.log(canvasData)}
                  {canvasData.map((canvas) => (
                    <Grow in={true} timeout={1200}>
                      <Grid  item xs id="tr">
                        <div
                          className={classes.boardCard1}
                        >
                          {/* </IconButton> */}
                          {check_admin ? (
                            <>
                              <IconButton aria-label="Delete">
                                <i
                                  onClick={(event) => {
                                    handle_delete(event, canvas.ssName);
                                  }}
                                >
                                  <DeleteIcon />
                                </i>
                              </IconButton>
                            </>
                          ) : (
                            <></>
                          )}
                          <Typography
                            // position= "absolute"
                            // fontweight = "400"
                            align="center"
                            variant="h6"
                            style={{
                              marginTop: "15%",
                              fontWeight: 'bold',
                              fontSize:'25px',
                              lineHeight:'36px',
                              fontStyle:'normal',
                              fontFamily:'Microsoft Sans Serif',
                            }}
                          >
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              to={{
                                pathname: `/board/${canvas.ssName}`,
                                state: {
                                  canvases: canvasData,
                                },
                                key: uuid(),
                              }}
                            >
                              {canvas.ssName} <div>{canvas.ssGoals}</div>
                              <div>{canvas.ssDescription}</div>
                            </Link>
                            {/* <div>{canvas.ssStartDate}</div> */}
                            
                            <div style={{marginLeft: 120,marginTop: 40,fontSize: 20}}>{canvas.ssEndDate}</div>
                            {/* <Select style={{ position: "relative" ,width:"100px"}}>
                                  <MenuItem value="Active" onClick={(event)=>{updateSprintStatus(event,canvas);}}>Active </MenuItem>  
                                  <MenuItem value="Cancel"  onClick={(event)=>{updateSprintStatus(event,canvas);}}>Cancel </MenuItem>   
                                  <MenuItem value="Complete" onClick={(event)=>{updateSprintStatus(event,canvas);}}>Complete </MenuItem>  
                                  <MenuItem value="Complete" onClick={(event)=>{updateSprintStatus(event,canvas);}}>Draft 
                                  </MenuItem>        
                                  </Select> */}
                          </Typography>
                        </div>
                      </Grid>
                    </Grow>
                  ))}
                </Grid>
              </div>
            </>
          )}
        </>
      )}
    </div>
    
  );
}

export default Sprint;
