
import { useEffect } from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import {createTheme} from "@material-ui/core/styles"
// import Canvas from "../src/components/view/Canvas";
 import Home from "./components/views/Home";
 import MyResponsivePie from "./components/views/DashBoard";
 import MainBoard from "./components/views/MainBoard";
 import List from "./components/views/List";
 import Admin from "./components/views/Admin"
import Sprint from "./components/views/Sprint";
import cli from "./components/views/CLI/Cli";
import select_project from "./components/views/select_project";
import QRFile from "./components/views/QRcode/QRFile";



import themeObject from "./utils/theme";
import { LoginRoute,PrivateRoute,AdminRoute } from "./components/views/Routes";
import Canvas from "./components/views/Canvas";
import firebase from "firebase/app";
import "@firebase/messaging";
// import { getAnalytics } from "firebase/analytics";
// const analytics = getAnalytics(app);
import { DndProvider } from "react-dnd";
import { Route } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";


import "firebase/performance";
import OverdueCard from "./components/views/OverdueCard";
import ShareCard from "./components/views/ShareCard";
import Account from "./components/views/Accounts/Account";
import Invoice_Quotation from "./components/views/Accounts/Invoice_Quotation";
import View from "./components/views/Accounts/View";
// import QrGenerator from "./components/views/QRcode/QrGenerator";

const theme = createTheme(themeObject);

function App() {
  // for pwa notification
  useEffect(() => {
    const msg = firebase.messaging();
    msg
      .requestPermission()
      .then(() => {
        return msg.getToken();
      })
      .then((data) => {
        console.warn("token", data);
      });

    //for storing configurable data
    const baseUrl = process.env.REACT_APP_HEROKU_URL;
    fetch(baseUrl + "/static").then((res)=>{
      res.json().then(
        (data)=>{
          console.log("static data :- ",data);

          localStorage.setItem("static",JSON.stringify(data))
          console.log("Static data for adding:-", JSON.parse(localStorage.getItem("static")));

            

        }
      )
    })
  });

  const firebaseConfig = {
    // apiKey: "AIzaSyBPR3VoSvJd2wphHybfUH0HM-rOMMXl1Vs",
    // authDomain: "dslomni.firebaseapp.com",
    // projectId: "dslomni",
    // storageBucket: "dslomni.appspot.com",
    // messagingSenderId: "595528007040",
    // appId: "1:595528007040:web:7a5e8e5effad14d41c5565",
    // measurementId: "G-WMCJNLDWSS"
    apiKey: "AIzaSyB9JVjkrl9BtpogjbR31d3rvX3ChUClnAc",
    authDomain: "fire-omne.firebaseapp.com",
    projectId: "fire-omne",
    storageBucket: "fire-omne.appspot.com",
    messagingSenderId: "69570663529",
    appId: "1:69570663529:web:352afdd40963961105fef8",
    measurementId: "G-YBTVHPFDM2"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  const opts = {
    enableMouseEvents: true,
    delayTouchStart: 5000,
  };

  //const perf = firebase.performance();
  return (
    //Placing whole application into Material Ui Theme Provider
    <MuiThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend} options={opts}>
        <div>
          <Router>
            <Switch>
              {/* <Route excat path="/test" component={QrGenerator} /> */}
              <Route exact path="/qr/:card" component={QRFile} />
              <LoginRoute exact path="/" component={Home} />
               <PrivateRoute exact path="/list" component={List} />
               <AdminRoute exact path="/admin" component={Admin} />  
              <PrivateRoute exact path="/board" component={MainBoard} />
              <PrivateRoute exact path="/board/:canvasId" component={Canvas} /> 
              <PrivateRoute exact path="/dashboard" component={MyResponsivePie} /> 
              <PrivateRoute exact path="/sprint" component={Sprint} /> 
              
              <PrivateRoute exact path="/cli" component={cli} /> 
              <PrivateRoute exact path="/sprint/:sprintId" component={Canvas} /> 
              <PrivateRoute exact path="/select_project" component={select_project} />
              <PrivateRoute exact path="/account" component={Account} />
              <PrivateRoute exact path="/create" component={Invoice_Quotation} />
              <PrivateRoute exact path="/view" component={View} />




              <PrivateRoute exact path="/board/:canvasId/:card" component={ShareCard} />


              <PrivateRoute exact path="/overduecard" component={OverdueCard} />
            </Switch>
          </Router>
        </div>
      </DndProvider>
    </MuiThemeProvider>
  );
}

export default App;


