import React, { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";

const Settings = () => {
  const setting_id = localStorage.getItem("setting_id");
  const [setting, setSetting] = useState("");
  useEffect(() => {
    localStorage.setItem("setting_id", setting);
  }, [setting]);
  console.log(setting);

  const onChangeHandler = (e) => {
    setSetting(e.target.value);
    window.location.reload();
  };

  return (
    <>
      <div style={{ paddingLeft: "50px" }}>
        <div>
          <label>
            Theme 1
            <input
              name="same"
              type="radio"
              size="string"
              id="1"
              value="1"
              checked={setting_id === "1" ? true : false}
              onChange={onChangeHandler}
              style={{ marginLeft: "10px" }}
            />
          </label>
        </div>
        <div>
          <label>
            Theme 2
            <input
              name="same"
              type="radio"
              size="string"
              id="2"
              value="2"
              checked={setting_id === "2" ? true : false}
              onChange={onChangeHandler}
              style={{ marginLeft: "10px" }}
            />
          </label>
        </div>
        <div>
          <label>
            Theme 3
            <input
              name="same"
              type="radio"
              size="string"
              id="3"
              value="3"
              checked={setting_id === "3" ? true : false}
              onChange={onChangeHandler}
              style={{ marginLeft: "10px" }}
            />
          </label>
        </div>
      </div>
    </>
  );
};

export default Settings;
