import { Button, TextField, TextareaAutosize } from "@material-ui/core";
import React from "react";
import { FormControl } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { useStyles } from "../styles/sidebarCss";
import { Box, Chip } from "@material-ui/core";
import Stack from '@mui/material/Stack';
import transitions from "@material-ui/core/styles/transitions";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Proj() {
  const baseUrl = process.env.REACT_APP_HEROKU_URL;



  const [senddata, setsenddata] = React.useState(
    {
      BoardCardName: "",
      Priority: "",
    }
  )

  const [type, settype] = React.useState([{
    name: "",
    customform: [],
  },])


  const [lanes, setlanes] = React.useState([])

  const [lanename, setlanename] = React.useState(
    ""
  )

  const handlelanenamechange = (e) => {

    setlanename(e.target.value)

  }

  const savelanename = () => {

    var arr = [];
    lanename.split(",").map((x) => {
      arr.push({
        name: x,
        transitions: [],
      })
    })

    setlanes(arr)


  }







  const classes = useStyles();
  // Getting user data as soon as component is rendered
  React.useEffect(() => {


  }, []);



  const savetypes = (e) => {

    e.preventDefault();
    console.log(type);

    const apiURL = process.env.REACT_APP_HEROKU_URL;
    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    const options = {
      method: "POST",
      headers: newHeaders,
      body: JSON.stringify(type),
    };
    fetch(apiURL + "/admin/addtypes", options)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
        },
        (error) => {

          console.log(error);

        }
      );







  }

  const savedata = (e) => {

    e.preventDefault();
    console.log(senddata);

    senddata.Priority = senddata.Priority.split(",")




    const apiURL = process.env.REACT_APP_HEROKU_URL;
    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    const options = {
      method: "POST",
      headers: newHeaders,
      body: JSON.stringify(senddata),
    };
    fetch(apiURL + "/admin/addprops", options)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
        },
        (error) => {

          console.log(error);

        }
      );




  }

  const addFields = () => {

    console.log("Hello in addFields");
    let newfield = {
      name: "",
      customform: [],
    }
    settype([...type, newfield])

    console.log(type);
  }

  const addminiFields = (index) => {
    console.log("Adding mini fields");
    console.log(index);
    console.log(type);
    const res = type.map((x) => {
      if (x.name == index) {

        x['customform'].push({
          cname: "",
          label: "",
          values: "",

        })
      }

      return x;

    })

    console.log(res);
    settype(res);

    console.log(type);


  }

  const handleFormChange = (index, event) => {
    let data = [...type];
    data[index][event.target.name] = event.target.value;
    settype(data);
  }

  const handleminiFormChange = (index, index2, event) => {
    let data = [...type];
    data[index]['customform'][index2][event.target.name] = event.target.value;
    settype(data);
  }

  const handlesenddata = (event) => {

    setsenddata({ ...senddata, [event.target.name]: event.target.value })


  }

  const removetype = (index) => {

    let data = [...type];
    data.splice(index, 1);
    console.log(data);
    settype(data);


  }
  const removecomponent = (index, index2) => {
    let data = [...type];
    console.log(

    );


    console.log(data);
    settype(data);


  }

  const handletransitionsChange = (event, index) => {
    console.log(index);
    const {
      target: { value }
    } = event;
    let data = [...lanes]

    data[index]["transitions"] = value;
    setlanes(data)
    console.log(lanes);



  }

  const saveconfig = (e) => {

    console.log(lanes);

    e.preventDefault();

    const apiURL = process.env.REACT_APP_HEROKU_URL;
    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    const options = {
      method: "POST",
      headers: newHeaders,
      body: JSON.stringify(lanes),
    };
    fetch(apiURL + "/admin/addlanes", options)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
        },
        (error) => {

          console.log(error);

        }
      );


  }






  return (
    <>
      <div style={{marginRight: 1110}}>

        {/* <Dialog open={open} onClose={handleToClose}>
                      <DialogTitle>{"Import Csv"}</DialogTitle>
                      <DialogContent>
                        <TextField onChange={(e)=>settype(e.target.value)}>
                            Name the Task
                        </TextField>
                        <Link to="https://ovautocd.web.app/">
                                <Button>
                                    Create custom form
                                </Button>
                        </Link>
                        
                        <TextField onChange={(e)=>setlink(e.target.value)}>
                            Paste the link
                        </TextField>
                      </DialogContent>
                      <DialogActions>

                        <Button onClick={savetypes}>
                            Add
                        </Button>
                        <Button onClick={handleToClose} 
                                color="primary" autoFocus>
                          Close
                        </Button>
                      </DialogActions>
            </Dialog> */}

        <form onSubmit={savedata}>
          <h2>Project Settings</h2>

          <TextField label="Project Name" name="BoardCardName" onChange={(e) => handlesenddata(e)}>

          </TextField>



          <TextField label="Add Priorities(CSV)" name="Priority" onChange={(e) => handlesenddata(e)}>

          </TextField>
          <Box m={3} pt={1}>
            <Button color="primary" variant="contained" size="large" onClick={savedata}>
              ADD
            </Button>
          </Box>




        </form>

        <form>

          <h4>Add Lanes</h4>


          <TextareaAutosize onChange={handlelanenamechange} minRows={3}
            style={{ width: 400 }} placeholder="Name the Lanes in Comma Seperated Values">

          </TextareaAutosize>
          <Box m={3} pt={1}>
            <Button color="primary" variant="contained" size="large" onClick={savelanename} >
              Save Lane Names
            </Button>
          </Box>


          <h4>Lane Configuration</h4>
          {
            lanes.length > 0 ? lanes.map(
              (x, index) => {
                console.log(index);
                return (
                  <div key={index} style={{ display: "flex" }}>
                    <input value={x.name}>
                    </input>
                    <FormControl style={{ width: "500px", height: "100px", marginLeft: "20px" }} >
                      <InputLabel >Select Transition to</InputLabel>
                      <Select

                        multiple
                        value={x.transitions}
                        onChange={event => handletransitionsChange(event, index)}
                        MenuProps={MenuProps}

                      >
                        {lanes.map((x) => (
                          <MenuItem

                            value={x.name}

                          >
                            {x.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )
              }

            ) : ""
          }

          <Box m={1} pt={1}>
            <Button color="primary" variant="contained" size="large" onClick={saveconfig}>
              Save Configuration
            </Button>
          </Box>



        </form>
        <form onSubmit={savetypes}>
          {type.map((input, index) => {
            return (

              <div key={index}>

                <input
                  name='name'
                  placeholder='Name of the Type'
                  value={input.name}
                  onChange={event => handleFormChange(index, event)}
                />

                <Button color="primary" variant="outlined" onClick={
                  (index) => addminiFields(input.name)}>
                  Add a Component
                </Button>



                {
                  input.customform.map(
                    (inp, index2) => {
                      return (

                        <div key={index}>
                          <Select

                            name='cname'

                            value={inp.cname}
                            label="Component"
                            onChange={event => handleminiFormChange(index, index2, event)}
                          >
                            <MenuItem value={"TextField"}>TextField</MenuItem>
                            <MenuItem value={"TextArea"}>TextArea</MenuItem>
                            <MenuItem value={"Dropdown"}>DropDown</MenuItem>
                            <MenuItem value={"Checkbox"}>CheckBox</MenuItem>
                            <MenuItem value={"Date"}>Date</MenuItem>

                          </Select>
                          {inp.cname != "Dropdown" ?
                            (<input
                              name='label'
                              placeholder='Name of the Type'
                              value={inp.label}
                              onChange={event => handleminiFormChange(index, index2, event)}
                            />) :

                            (<div>
                              <input
                                name='label'
                                placeholder='Description of the component'
                                value={inp.label}
                                onChange={event => handleminiFormChange(index, index2, event)}
                              />

                              <input
                                name='values'
                                placeholder='Comma Seperated values of dropdown'
                                value={inp.values}
                                onChange={event => handleminiFormChange(index, index2, event)}
                              />
                            </div>
                            )
                          }
                          {/* <button onClick={(index,index2)=>removecomponent()}>Remove Component</button> */}






                        </div>








                      )


                    }
                  )
                }

                <Button color="secondary" variant="outlined" onClick={(index) => removetype()}>Remove the type</Button>



              </div>

            )
          })}


        </form>
        <Box pt={2} sx={{ mx: "auto", width: 249 }}>
          <Stack direction="row" spacing={2}>
            <Button color="primary" variant="outlined" onClick={addFields}>
              Add  Type
            </Button>
            <Button color="primary" variant="contained" onClick={savetypes}>Submit</Button>
          </Stack>
        </Box>







      </div>

    </>
  );
}

export default Proj;

