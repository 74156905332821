import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";

const RuleBaseSettings = () => {
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  const [overdueEnabled, setOverdueEnanbled] = useState("0");
  const [dateEnabled, setDateEnabled] = useState("0");

  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);

  const overdueSetting = localStorage.getItem("overdueSetting");
  const dateSetting = localStorage.getItem("dateSetting");

  localStorage.setItem("startDate", startDate);
  localStorage.setItem("endDate", endDate);
  console.log(
    localStorage.getItem("startDate") +
      " between " +
      localStorage.getItem("endDate")
  );

  // useEffect(() => {
  //   localStorage.setItem("overdueSetting", overdueEnabled);
  // });
  localStorage.setItem("overdueSetting", overdueEnabled);
  localStorage.setItem("dateSetting", dateEnabled);

  const onChangeHandlerOverdue = (e) => {
    // setOverdueEnambled(e.target.value);
    overdueSetting === 1 ? setOverdueEnanbled(0) : setOverdueEnanbled(1);
    window.location.reload();
  };

  const onChangeHandlerDate = (e) => {
    dateSetting === 1 ? setDateEnabled(0) : setDateEnabled(1);
    window.location.reload();
  };
  return (
    <div>
      <div>
        <label>
          <p>
            {" "}
            <b>1</b> Overdue Card Rule:
            <input
              name="same"
              type="checkbox"
              size="string"
              id="1"
              value="1"
              checked={overdueSetting === "1" ? true : false}
              onChange={onChangeHandlerOverdue}
              style={{ marginLeft: "10px" }}
            />
          </p>
        </label>
      </div>
      <div>
        <p>
          <b>2</b> Project Start Date
        </p>
        <label style={{ marginLeft: "6vw" }}>
          Start Date:
          <TextField
            type="date"
            // value={add_Project_start_Date}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
            required
            style={{ marginLeft: "10px" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </label>
        <b style={{ paddingLeft: "5vw" }}>Between</b>
        <label style={{ marginLeft: "6vw" }}>
          Start Date:
          <TextField
            type="date"
            // value={add_Project_start_Date}
            // onChange={(e) => add_setProject_start_Date(e.target.value)}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
            required
            style={{ marginLeft: "10px" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </label>
      </div>
      <div style={{ marginLeft: "6vw" }}>
        Apply Date changes
        <input
          name="same"
          type="checkbox"
          size="string"
          id="1"
          value="1"
          checked={dateSetting === "1" ? true : false}
          onChange={onChangeHandlerDate}
          style={{ marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};

export default RuleBaseSettings;
