import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") !== null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const LoginRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") === null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/board",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("admin") !== null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/board",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export { PrivateRoute, LoginRoute, AdminRoute };
