import React from "react";
import $ from "jquery";
import "../styles/list.css";
import Header from "./Header";
import { useStyles } from "../styles/listCss";
import { useTheme } from "@material-ui/core/styles";
//MUI Stuffs
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import omnivuelogo from "../../assets/images/omnivuelogo.jpg";
import { AppBar, Toolbar, Typography, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSearch } from "@fortawesome/free-solid-svg-icons";

import ShowList from "./ShowList";
import OverdueCard from "./OverdueCard";

function List() {
  // // const userId = localStorage.getItem("userId");
  const email = localStorage.getItem("email");
  const id = localStorage.getItem("PROJECT_ID");
  const project_name = localStorage.getItem("PROJECT_NAME");
  const [canvasData, setCanvasData] = React.useState([]);
  const [activeCanvasId, setActiveCanvasId] = React.useState(0);
  const [board, setBoard] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [isCanvas, setIsCanvas] = React.useState(false);
  const [users, setUsers] = React.useState({});
  const [exports, setExport] = React.useState({ data: [] });
  const classes = useStyles();
  const overdueSetting = localStorage.getItem("overdueSetting");
  let usremail = window.localStorage.getItem("email");
    let projectid = window.localStorage.getItem("PROJECT_ID");

  // const getUsers = () => {
  //   const mainUrl = process.env.REACT_APP_HEROKU_URL;
  //   fetch(mainUrl + "allUser")
  //     .then((response) =>
  //       response.json().then((data) => {
  //         setUsers(data);
  //       })
  //     )
  //     .catch((err) => console.log(err));
  // };

  const getUserData = () => {
    fetch(baseUrl + `/getallboard?id=${email}&prjId=${id}`).then((res) => {
      console.log(res);
      res.json().then((data) => {
        console.log(data);
        setCanvasData(data);
        getData(data[0].DynamicID);
        setActiveCanvasId(data[0].DynamicID);
      });
    });
  };

  React.useEffect(() => {
    getUserData();
  }, []);

  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  const theme = useTheme();
  //Get User Data
  // const getUserData = () => {
  //   fetch(baseUrl + "User?Email=" + email)
  //     .then((response) => {
  //       response.json().then((data) => {
  //         console.log("Data", data);
  //         if (data.Admin === "TRUE") {
  //           //console.log(data.Admin);
  //           setIsCanvas(true);
  //           fetch(baseUrl + "Admin/allCanvas")
  //             .then((response) =>
  //               response.json().then((data) => {
  //                 getData(data[0].CanvasId);
  //                 setCanvasData(data);
  //                 setActiveCanvasId(data[0].CanvasId);
  //               })
  //             )
  //             .catch((err) => console.log(err));
  //         } else {
  //           setCanvasData(data.Canvas);
  //           if (data.Canvas && data.Canvas.length > 0) {
  //             setIsCanvas(true);
  //             getData(data.Canvas[0].CanvasId);

  //             //setActiveCanvasId(data.Canvas[0].CanvasId);
  //           } else setLoading(false);
  //         }
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       let collection = JSON.parse(localStorage.getItem("user"));
  //       collection.Canvas.map((collection, index) => {
  //         getData(collection.CanvasId);
  //       });
  //     });
  // };

  //Get Canvas Data
  const getData = (canvasId) => {
    fetch(baseUrl + "/getallchild?crdParentID=" + canvasId + "&email=" + usremail + "&prjId=" + projectid)
      .then((response) =>
        response.json().then((card) => {
          localStorage.setItem(`list-${canvasId}`, JSON.stringify(card));

          // card?.map((c) => (c.CanvasId = canvasId));
          //console.log("card", card);

          setBoard(card);
          setLoading(false);
          setIsCanvas(true);
        })
      )
      .catch((err) => {
        console.log(err);
        let collection = JSON.parse(localStorage.getItem(`list-${canvasId}`));
        setBoard(collection);
        setLoading(false);
      });
  };

  const getDataFromSelect = (event) => {
    //console.log("Change", event.target.value);
    event && setActiveCanvasId(event.target.value);
    localStorage.setItem("activeCanvas", event.target.value);
    fetch(baseUrl + "/getallchild?crdParentID=" + event.target.value + "&email=" + usremail + "&prjId=" + projectid)
      .then((response) =>
        response.json().then((data) => {
          setBoard(data);
          localStorage.setItem(
            `canvas-${event.target.value}`,
            JSON.stringify(data)
          );
          //console.log(data);
        })
      )
      .catch((err) => {
        console.log(err);
        let collection = localStorage.getItem(`canvas-${event.target.value}`);
        setBoard(JSON.parse(collection));
      })
      .finally(() => setLoading(false));
  };

  // console.log("Board", board);

  //for seraching in list
  $(document).ready(function () {
    $("#search").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      $("#dataTable #tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
    });
  });
  //for sorting
  function sortTable(n) {
    var table,
      rows,
      switching,
      i,
      x,
      y,
      shouldSwitch,
      dir,
      count = 0;
    table = document.getElementById("dataTable");
    switching = true;
    dir = "asc";
    while (switching) {
      switching = false;
      rows = table.rows;
      for (i = 0; i < rows.length - 1; i++) {
        shouldSwitch = false;

        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        if (dir === "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        } else if (dir === "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        count++;
      } else {
        if (count === 0 && dir === "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }
  const columns = [
    {
      label: "IssueId",
      field: "IssueId",
    },
    {
      label: "Status",
      field: "Status",
    },
    {
      label: "Summary",
      field: "Summary",
      width: 80,
    },
    {
      label: "Description",
      field: "Description",
    },
    {
      label: "Acceptance Criteria",
      field: "AcceptanceCriteria",
    },
    {
      label: "DueDate",
      field: "DueDate",
    },
    {
      label: "Priority",
      field: "Priority",
    },
    {
      label: "AssignTo",
      field: "AssignTo",
    },
    {
      label: "Comments",
      field: "Comments",
    },
    {
      label: "Type",
      field: "Type",
    },
    {
      label: "Effort Estimation",
      field: "Effort Estimation",
    },
    {
      label: "",
      field: "",
    },
  ];

  const getDataForExport = (canvasId) => {
    fetch(baseUrl + "/getallchild?crdParentID=" + canvasId + "&email=" + usremail + "&prjId=" + projectid)
      .then((response) =>
        response.json().then((card) => {
          console.log("card", card);
          localStorage.setItem(`list-${canvasId}`, JSON.stringify(card));
          // card.map((c) => (c.CanvasId = canvasId));
          //console.log("card", card);

          setExport((prevState) => ({
            data: prevState.data.concat(card),
          }));
          setLoading(false);
        })
      )
      .catch((err) => {
        console.log(err);
        let collection = JSON.parse(localStorage.getItem(`list-${canvasId}`));
        setExport((prevState) => ({
          data: prevState.data.concat(collection),
        }));
        setLoading(false);
      });
  };

  async function getCanvasDataForExport() {
    const baseUrl = process.env.REACT_APP_HEROKU_URL;
    console.log("getCanvasDataForExport");
    await fetch(baseUrl + "User?Email=" + email)
      .then((response) => {
        response.json().then((data) => {
          if (data.Admin === "TRUE") {
            setIsCanvas(true);
            fetch(baseUrl + "Admin/allCanvas")
              .then((response) =>
                response.json().then((data) => {
                  data.map((canvas, index) => {
                    getDataForExport(canvas.CanvasId, index);
                  });
                })
              )
              .catch((err) => console.log(err));
          } else {
            if (data.Canvas && data.Canvas.length > 0) {
              setIsCanvas(true);
              data.Canvas.map((data, index) => {
                getDataForExport(data.CanvasId);
              });
            } else setLoading(false);
          }
        });
      })
      .catch((err) => {
        console.log(err);
        let collection = JSON.parse(localStorage.getItem("user"));
        collection.Canvas.map((collection, index) => {
          getDataForExport(collection.CanvasId);
        });
      });
  }
  // console.log("export", exports);

  const Export1 = (value) => {
    const baseUrl = process.env.REACT_APP_HEROKU_URL;

    try {
      fetch(baseUrl + "/getallchild?crdParentID=" + activeCanvasId + "&email=" + usremail + "&prjId=" + projectid)
        .then((response) =>
          response.text().then((data) => {
            console.log(`Export func:-${data}`);
            data = JSON.parse(data);
            let CanvasObject = [];
            for (let y in data) {
              CanvasObject.push(data[y]);
            }
            CanvasObject = [Object.keys(CanvasObject[0])].concat(CanvasObject);
            CanvasObject = CanvasObject.map((canrow) => {
              return Object.values(canrow)
                .map((canvalue) => {
                  return typeof canvalue === "string"
                    ? JSON.stringify(canvalue)
                    : canvalue;
                })
                .toString();
            }).join("\n");

            let CanvasObject1 = CanvasObject.replace(/,/g, "\t");
            CanvasObject1 = CanvasObject1.replace(/"/g, "");

            // const jsonToTxt = require("json-to-txt");
            // const dataInString = jsonToTxt({ data: data });

            const element = document.createElement("a");
            switch (value) {
              case "Text":
                //.txt code
                // const file_txt = new Blob([dataInString], {
                //   type: "plain/text",
                // });
                // element.href = URL.createObjectURL(file_txt);
                // element.download = String(activeCanvasId) + ".txt";
                // document.body.appendChild(element);
                // element.click();
                break;

              case "Json":
                // .json code
                // const file_json = new Blob([dataInString], {
                //   type: "application/json",
                // });
                // element.href = URL.createObjectURL(file_json);
                // element.download = String(activeCanvasId) + ".json";
                // document.body.appendChild(element);
                // element.click();
                break;

              case "Csv":
                //.csv code
                const file_csv = new Blob([CanvasObject], { type: "text/csv" });
                element.href = URL.createObjectURL(file_csv);
                element.download = String(activeCanvasId) + ".csv";
                document.body.appendChild(element);
                element.click();
                break;

              // case "Png":
              //   html2canvas(document.querySelector("#root"), {
              //     allowTaint: true,
              //     useCORS: true,
              //     foreignObjectRendering: true,
              //   }).then((currentcanvas) => {
              //     element.href = currentcanvas
              //       .toDataURL("image/png")
              //       .replace("image/png", "image/octet-stream");
              //     element.download = String(activeCanvasId) + ".png";
              //     element.click();
              //   });
              //   break;

              // case "Jpeg":
              //   html2canvas(document.querySelector("#root"), {
              //     allowTaint: true,
              //     useCORS: true,
              //     foreignObjectRendering: true,
              //   }).then((currentcanvas) => {
              //     element.href = currentcanvas
              //       .toDataURL("image/jpeg")
              //       .replace("image/jpeg", "image/octet-stream");
              //     element.download = String(activeCanvasId) + ".jpeg";
              //     element.click();
              //   });
              //   break;

              // case "Pdf":
              //   html2canvas(document.querySelector("#root"), {
              //     allowTaint: true,
              //     useCORS: true,
              //     foreignObjectRendering: true,
              //   }).then((currentcanvas) => {
              //     var image = currentcanvas
              //       .toDataURL("image/png")
              //       .replace("image/png", "image/octet-stream");
              //     var doc = new jsPDF("landscape");
              //     var width = doc.internal.pageSize.getWidth();
              //     var height = doc.internal.pageSize.getHeight();

              //     doc.addImage(image, "PNG", 0, 0, width, height);
              //     doc.save(String(activeCanvasId) + ".pdf");
              //   });
              //   break;

              case "Xml":
                function toXML(obj) {
                  var xml = "";
                  for (var prop in obj) {
                    xml += obj[prop] instanceof Array ? "" : "<" + prop + ">";
                    if (obj[prop] instanceof Array) {
                      for (var array in obj[prop]) {
                        xml += "<" + prop + ">";
                        xml += toXML(new Object(obj[prop][array]));
                        xml += "</" + prop + ">\n";
                      }
                    } else if (typeof obj[prop] == "object") {
                      xml += toXML(new Object(obj[prop]));
                    } else {
                      xml += obj[prop];
                    }
                    xml +=
                      obj[prop] instanceof Array ? "" : "</" + prop + ">\n";
                  }
                  var xml = xml.replace(/<\/?[0-9]{1,}>/g, "");
                  return xml;
                }
                var output = toXML(data);
                const file_xml = new Blob([output], { type: "text/xml" });
                element.href = URL.createObjectURL(file_xml);
                element.download = String(activeCanvasId) + ".xml";
                element.click();
                break;
            }
          })
        )
        .catch((err) => console.log("From error", err));
    } catch {
      console.log("catch");
    }
  };

  const getExport = (value) => {
    console.log("getExport");
    try {
      let cardD = [];
      let cardData = [];
      exports.data.map((a) => {
        if (a.response === "the Canvas is empty") {
        } else {
          cardData.push(a);
        }
      });
      cardData.map((b) => {
        b.Cards.map((c) => {
          cardD.push(c);
        });
      });
      // for Text formate......
      let data = cardD;
      const jsonToTxt = require("json-to-txt");
      const dataInString = jsonToTxt({ data: data });

      //For CSV formate....
      let CanvasObject = [];
      for (let y in data) {
        CanvasObject.push(data[y]);
      }
      CanvasObject = [Object.keys(CanvasObject[0])].concat(CanvasObject);
      CanvasObject = CanvasObject.map((canrow) => {
        return Object.values(canrow)
          .map((canvalue) => {
            return typeof canvalue === "string"
              ? JSON.stringify(canvalue)
              : canvalue;
          })
          .toString();
      }).join("\n");

      let CanvasObject1 = CanvasObject.replace(/,/g, "\t");
      CanvasObject1 = CanvasObject1.replace(/"/g, "");

      // const dataInString = JSON.stringify(board.data);
      const element = document.createElement("a");
      switch (value) {
        // case "Text":
        //   //.txt code
        //   const file_txt = new Blob([dataInString], {
        //     type: "plain/text",
        //   });
        //   element.href = URL.createObjectURL(file_txt);
        //   element.download = "List.txt";
        //   document.body.appendChild(element);
        //   element.click();
        //   break;

        // case "Json":
        //   // .json code
        //   const file_json = new Blob([dataInString], {
        //     type: "application/json",
        //   });
        //   element.href = URL.createObjectURL(file_json);
        //   element.download = "List.json";
        //   document.body.appendChild(element);
        //   element.click();
        //   break;

        case "Csv":
          //.csv code
          const file_csv = new Blob([CanvasObject], { type: "text/csv" });
          element.href = URL.createObjectURL(file_csv);
          element.download = "List.csv";
          document.body.appendChild(element);
          element.click();
          break;

        case "Xml":
          function toXML(obj) {
            var xml = "";
            for (var prop in obj) {
              xml += obj[prop] instanceof Array ? "" : "<" + prop + ">";
              if (obj[prop] instanceof Array) {
                for (var array in obj[prop]) {
                  xml += "<" + prop + ">";
                  xml += toXML(new Object(obj[prop][array]));
                  xml += "</" + prop + ">\n";
                }
              } else if (typeof obj[prop] == "object") {
                xml += toXML(new Object(obj[prop]));
              } else {
                xml += obj[prop];
              }
              xml += obj[prop] instanceof Array ? "" : "</" + prop + ">\n";
            }
            var xml = xml.replace(/<\/?[0-9]{1,}>/g, "");
            return xml;
          }
          var output = toXML(data);
          const file_xml = new Blob([output], { type: "text/xml" });
          element.href = URL.createObjectURL(file_xml);
          element.download = "List.xml";
          element.click();
          break;
      }
    } catch {
      console.log("catch");
    }
  };

  const mainExport = (value) => {
    getCanvasDataForExport();
    setTimeout(function () {
      getExport(value);
    }, 60000);
    // getExport(value);
  };

  const [value, setValue] = React.useState("");
  function handleChangeExp(event) {
    // setValue(() => event.target.value);
    // mainExport(event.target.value);
    setValue(() => event.target.value);
    Export1(event.target.value);
  }

  return (
    <>
      <Header x="List" />
      <AppBar position="static" className={classes.appbar}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            List
            <div>
              <p style={{ color: "red", fontSize: 12 }}>{project_name}</p>
            </div>
          </Typography>
          {isCanvas && (
            <FormControl
              margin="dense"
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel>Export</InputLabel>
              <Select
                onChange={handleChangeExp}
                value={value}
                style={{ margin: "10px" }}
              >
                <MenuItem disabled>Select</MenuItem>
                {/* <MenuItem value={"Text"}>TEXT</MenuItem>
                <MenuItem value={"Json"}>JSON</MenuItem> */}
                <MenuItem value={"Csv"}>CSV</MenuItem>
                <MenuItem value={"Xml"}>XML</MenuItem>
              </Select>
            </FormControl>
          )}
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              id="search"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div>
            <img src={omnivuelogo} className={classes.omni} />
          </div>
        </Toolbar>
      </AppBar>
      {loading ? (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            marginLeft: "-50px",
            marginTop: "-50px",
          }}
        >
          <CircularProgress size={100} />
          <h5 style={{ fontSize: 20 }}> Loading..... </h5>
        </div>
      ) : (
        <>
          {isCanvas && (
            <FormControl
              margin="dense"
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                native
                id="demo-customized-select-native"
                className={classes.select}
                onChange={getDataFromSelect}
                style={{ margin: 5 }}
              >
                {canvasData &&
                  canvasData.length > 0 &&
                  canvasData.map((canvas, index) => (
                    <option key={index} value={canvas.DynamicID}>
                      {canvas.crdName}
                    </option>
                  ))}
              </Select>
            </FormControl>
          )}

          {isCanvas ? (
            <TableContainer
              style={{
                width: "100%",
                padding: theme.spacing(0, 8),
                marginTop: "20px",
                height: "auto",
                maxHeight: "480px",
              }}
              component={Paper}
              className={classes.tableContainer}
            >
              <Table stickyHeader aria-label="simple table" id="sortTable">
                {/* Showing Status at table header */}
                <TableHead>
                  <TableRow>
                    {columns.map((col, index) => (
                      <TableCell
                        style={{ backgroundColor: "#e5f0ef" }}
                        key={index}
                        align="center"
                        classes={{ root: classes.tableCell }}
                      >
                        {col.field}
                        {col.field !== "" && (
                          <FontAwesomeIcon
                            onClick={() => sortTable(index)}
                            icon={faSort}
                            transform="shrink-3 right-6"
                          />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {board && board.length > 0 ? (
                  <TableBody
                    id="dataTable"
                    style={{ backgroundColor: " #f7fcfb " }}
                  >
                    {board.map((card, index) => (
                      // col.Cards &&
                      // col.Cards.map((card, index) => (
                      //   <TableRow id="tr">
                      //     <ShowList
                      //       col={col}
                      //       card={card}
                      //       board={board}
                      //       canvasId={activeCanvasId}
                      //       users={users}
                      //       getData={getData}
                      //     />
                      //   </TableRow>
                      // ))
                      <TableRow id="tr">
                        <ShowList
                          card={card}
                          board={board}
                          canvasId={activeCanvasId}
                          users={users}

                          getData={getData}
                        />
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <h3 style={{ margin: "auto" }}>No Card Found </h3>
                )}
              </Table>
            </TableContainer>
          ) : (
            <h2
              style={{
                color: "black",
                position: "absolute",
                top: "45%",
                left: "45%",
              }}
            >
              No Card Found{" "}
            </h2>
          )}
          {overdueSetting === "0" ? "" : (<OverdueCard />)}
        </>
      )}
    </>
  );
}

export default List;
