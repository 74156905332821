import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// import Canvas from "Canvas";

import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },  
};

function AddUser(props) {
  const { openForm, setOpenForm, canvas, setUsers } = props;
  const [data, setData] = React.useState({ usrName: "", usrEmailID: "",usrRole:"",cli:0 });
  const [selectCanvas, setSelectCanvas] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [openSuccessAlert, setopenSuccessAlert] = React.useState(false);
  const [openErrorAlert, setopenErrorAlert] = React.useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setopenSuccessAlert(false);
    setopenErrorAlert(false);
  };

  const baseUrl = process.env.REACT_APP_HEROKU_URL;

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData({ ...data, [name]: value });
  };

  const handleSelectChange = (event) => {
    setSelectCanvas(event.target.value);
  };

  const handleClose = () => {
    setOpenForm(false);
    setData({ usrName: "", usrEmailID: "" ,usrRole:""});
    setSelectCanvas([]);
  };

  const isEnabled = () => {
    var obj = data;

    for (var key in obj) {
      console.log("Value", key, obj[key]);
      if (obj[key] === "" && (key === "usrName" || key === "usrEmailID" || key=== "usrRole")) return false;
    }
    return true;
  };

  var flag = isEnabled();
  console.log("flag", flag);

  const mergeInfo = () => {
    
    setData(data);
    //console.log(data);
    addNewUser(data);
  };

  const getAllUsers = () => {
    fetch(baseUrl + "/user/list")
      .then((response) =>
        response.json().then((data) => {
          //console.log("User", data);
          setUsers(data);
        })
      )
      .catch((err) => console.log(err));
  };

  const addNewUser = (data) => {
    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    const options = {
      method: "POST",
      headers: newHeaders,
      body: JSON.stringify(data),
    };

    console.log("Options", options);
    console.log(data);
    fetch(baseUrl + "/user/add", options)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        getAllUsers();
        setopenSuccessAlert(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setopenErrorAlert(true);
      })
      .finally(() => {
        handleClose();
      });
    console.log(data);
  };
// console.log("heyy",data)
  return (
    <div>
      <Dialog
        onClose={() => {
          handleClose();
        }}
        open={openForm}
      >
        <DialogTitle>
          <h3>Add New User</h3>
        </DialogTitle>
        <DialogContent>
          <TextField
            id="Names"
            name="usrName"
            required
            fullWidth
            value={data.usrName}
            onChange={handleChange}
            label="Names"
          />
          <TextField
            id="Email"
            name="usrEmailID"
            required
            fullWidth
            value={data.usrEmailID}
            onChange={handleChange}
            label="Email"
          />
          <TextField
            id="Role"
            name="usrRole"
            required
            fullWidth
            value={data.usrRole}
            onChange={handleChange}
            label="User Role(usrRead/usrReadWrite)"
          />
          {/* <div>
            <FormControl>
              <InputLabel id="demo-mutiple-name-label">Board</InputLabel>
              <Select
                style={{ minWidth: 170, width: "auto" }}
                multiple
                fullWidth
                name={canvas.crdName}
                value={selectCanvas}
                onChange={handleSelectChange}
                input={<Input />}
                MenuProps={MenuProps}
              >
                {canvas.length > 0 &&
                  canvas.map((canvas) => (
                    <MenuItem value={canvas.DynamicID}>
                      {canvas.crdName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div> */}
        </DialogContent>

        <DialogActions style={{ marginTop: 20, marginRight: 10 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={mergeInfo}
            disabled={flag === false}
          >
            {loading === true ? (
              <CircularProgress color="secondary" />
            ) : (
              <span>Add New</span>
            )}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSuccessAlert}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success">
          Successfull...
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorAlert}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="success">
          Something Went Wrong!.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AddUser;
