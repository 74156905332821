import React from "react";

import TableCell from "@material-ui/core/TableCell";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import SaveIcon from "@material-ui/icons/Save";
import CreateIcon from "@material-ui/icons/Create";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import { SuccessSnackbar, ErrorSnackbar } from "../Snackbar";

import { useStyles } from "../../styles/CanvasCss";

function ShowCanvas(props) {
  
  const { canvas, getAllCanvas, setCanvas } = props;
  const [data, setData] = React.useState({ data: {} });
  const [disable, setDisable] = React.useState(true);

  const [openEditSuccessAlert, setopenEditSuccessAlert] = React.useState(false);
  const [openErrorAlert, setopenErrorAlert] = React.useState(false);

  const [openDeleteSuccessAlert, setopenDeleteSuccessAlert] =
    React.useState(false);

  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  const classes = useStyles();

  //console.log(data);

  const handleChange = (event) => {
    setData((prevState) => {
      let data = Object.assign({}, prevState.data);
      data[event.target.name] = event.target.value;
      return { data };
    });
  };

  const updateInfo = () => {
    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    console.log(data);
    const options = {
      method: "PUT",
      headers: newHeaders,
      body: JSON.stringify(data.data),
    };

    console.log("Options", options);
    fetch(baseUrl + "/update?Id="+data.data.DynamicID, options)
      .then((res) =>res.json()).then((data)=> {
        console.log(data);
        setopenEditSuccessAlert(true);
        getAllCanvas();
        setDisable(true);
      })
      .catch((error) => {
        console.log(error);
        setopenErrorAlert(true);
      });
  };

  const editContent = () => {
    setDisable(!disable);
    setData({
      data: {
        DynamicID: canvas.DynamicID,
        crdName: canvas.crdName,
      },
    });
  };

  //console.log("Canvas", canvas.ParentId);
  // Cascade delete added
  const deleteCanvas = () => {


    const apiURL=process.env.REACT_APP_HEROKU_URL
    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");

    

    const options = {
      method: "DELETE",
      headers: newHeaders,
     
    };
    fetch(apiURL + "/delete?Id="+canvas.DynamicID, options)
      .then((res) => res.json())
      .then((result) => {

           setopenDeleteSuccessAlert(true);
            getAllCanvas();
            setDisable(true);
            console.log(result);
      })
      .catch((error) => {
        setopenErrorAlert(true);
        console.log(error);
      });
  };

  return (
    <>
      <TableCell align="center">{canvas.DynamicID}</TableCell>
      <TableCell align="center">
        {disable ? (
          canvas.crdName
        ) : (
          <TextField
            id="canvasname"
            name="crdName"
            value={data.crdName}
            onChange={handleChange}
            defaultValue={data.crdName || canvas.crdName}
          />
        )}
      </TableCell>

      <TableCell align="center">
        <IconButton>
          {disable ? (
            <CreateIcon onClick={editContent} />
          ) : (
            <CloseIcon onClick={editContent} />
          )}
        </IconButton>
        <IconButton>{!disable && <SaveIcon onClick={updateInfo} />}</IconButton>
        <IconButton>
          <DeleteIcon onClick={deleteCanvas} />
        </IconButton>
      </TableCell>
      <SuccessSnackbar
        openSuccessAlert={openEditSuccessAlert}
        setopenSuccessAlert={setopenEditSuccessAlert}
        message="Board Edited Successfully!"
        horizontal="top"
        vertical="center"
      />
      <SuccessSnackbar
        openSuccessAlert={openDeleteSuccessAlert}
        setopenSuccessAlert={setopenDeleteSuccessAlert}
        message="Board Deleted Successfully!"
        horizontal="top"
        vertical="center"
      />
      <ErrorSnackbar
        openErrorAlert={openErrorAlert}
        setopenErrorAlert={setopenErrorAlert}
        horizontal="top"
        vertical="center"
      />
    </>
  );
}

export default ShowCanvas;
