import React, { useState } from "react";

import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import SaveIcon from "@material-ui/icons/Save";
import CreateIcon from "@material-ui/icons/Create";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
/* import Typography from "@material-ui/core/Typography"; */
import DeleteIcon from "@material-ui/icons/Delete";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import Board from "../../../assets/images/Board.png";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonIcon from '@material-ui/icons/Person';
import Button from "@material-ui/core/Button";

import { SuccessSnackbar, ErrorSnackbar } from "../Snackbar";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ShowUsers(props) {
  const { user, setUsers, canvas } = props;

  const [data, setData] = React.useState({ data: {} });
  const [canvasCheck, setcanvasCheck] = React.useState();
  const [disable, setDisable] = React.useState(true);
  const [usercanvas,setusercanvas]=React.useState([])

  const [openEditSuccessAlert, setopenEditSuccessAlert] = React.useState(false);
  const [openErrorAlert, setopenErrorAlert] = React.useState(false);

  const [action,setaction]=React.useState(false);
  const [filterData, setFilterData] = React.useState([]);

  const[Private,setPrivate]=useState([]); //to set list of private boards

  const[email,setEmail]=useState('');

  const[priavteBoard,setPrivateBoard]=useState('');
  const[Body,setBody]=useState({});


  const [openDeleteSuccessAlert, setopenDeleteSuccessAlert] =
    React.useState(false);

  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  const classes = useStyles();

  React.useEffect(() => {
    var userCanvasId = [];
    getboards()
    getPrivate();
    console.log(window.localStorage.getItem("email")); 
    // user.Canvas.map((canvas) => userCanvasId.push(canvas.CanvasId));
    setFilterData(userCanvasId);
    console.log(props);
  }, []);

  const getboards=()=>{

    fetch(baseUrl + "/getallboard?id="+user.usrEmailID)
      .then((response) =>
        response.json().then((data) => {
          console.log("User", data);
          setusercanvas(data);
          setDisable(true);
        })
      )
      .catch((err) => console.log(err));
  }

  const getPrivate=()=>{
    fetch(baseUrl+"/admin/getprivateboard").then((res)=>
      res.json().then((data)=>{
        console.log("Private boards",data)
        setPrivate(data);
        console.log(data)
        console.log(Private)

      })
    ).catch((err)=>console.log(err))
  }
  
  const getAllUsers = () => {
    fetch(baseUrl + "/user/list")
      .then((response) =>
        response.json().then((data) => {
          console.log("User in show users", data);
          setUsers(data);
          setDisable(true);
          
        })
      )
      .catch((err) => console.log(err));
  };

  const handleChange = (event) => {
    setData((prevState) => {
      let data = Object.assign({}, prevState.data);
      data[event.target.name] = event.target.value;
      return { data };
    });
  };
  //console.log("UserData", data);

  const handleCheckChange = (event) => {
    filterData.map((f) => console.log("val1", f));
    if (!filterData.includes(event.target.value)) {
      setFilterData(event.target.value);
    }
  };

  const mergeInfo = () => {
   
    //console.log("Merge", data);
    setData(data);
    updateInfo(data);
  };

  const updateInfo = (data) => {
    const newHeaders = new Headers();
    console.log(data);
    newHeaders.append("Content-Type", "application/json");
    const options = {
      method: "PUT",
      headers: newHeaders,
      body: JSON.stringify(data.data),
    };

    console.log("Options", options);
    fetch(baseUrl + "/user/update?email="+user.usrEmailID, options)
      .then((res) => {
        console.log("Response", res);
        getAllUsers();
        setopenEditSuccessAlert(true);
      })
      .catch((error) => {
        console.log(error);
        setopenErrorAlert(true);
      });
  };
  // user.Admin === "TRUE" ?(
    // {disable?():()}
  const deleteUser = () => {
    setDisable(disable);
    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    const options = {
      method: "DELETE",
      headers: newHeaders,
     
    };
    // {disable?(""):(<CreateIcon onClick={deleteUser} />)}

    console.log("Options", options);
    fetch(baseUrl + "/user/delete?email="+user.usrEmailID, options)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);

        getAllUsers()
        setDisable(true);
        setopenDeleteSuccessAlert(true);


        
        
      })
      .catch((error) => {
        console.log(error);
        setopenErrorAlert(true);
      });
  };

  //console.log("Check", canvasCheck);

  const editContent = () => {
    setDisable(!disable);
    setData({
      data: {
        //Name: user.Name,
        
        usrName:user.usrName,
      },
    });
    setcanvasCheck([]);
  };

  //console.log(userCanvasData);


  const handlePrivateChange=(e,name)=>{
      console.log(e);
      console.log(name.usrEmailID);
      setEmail(name.usrEmailID);
      setPrivateBoard(e.target.value);
      //console.log(name);
  }


  const AddUser=(e)=>{
    e.preventDefault();
    addNewData();
  }

  const addNewData=()=>{
    submitPrivateBoard();
  }


  const submitPrivateBoard = (data) => {
    
  
    const newHeaders=new Headers();   
    newHeaders.append("Content-Type", "application/json");

    const options = {
      method: "POST",
      headers: newHeaders,
    };

    console.log("Data Options", options);
    let adminEmail=window.localStorage.getItem("email")
    fetch(baseUrl+"/admin/adduser?id="+email+"&name="+priavteBoard+"&adEmail="+adminEmail, options)
      .then((res) => res.json())
      .then((result) => {
        console.log("Results", result);
             
        //window.location.reload();
      })
      .catch((err) => console.log(err));
  };



  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  // console.log("hi", user)
  return (
    <>
      <TableCell align="center">{user.usrRole === "Admin" ? (<SupervisorAccountIcon />) : (<PersonIcon />)}<br />

      {disable ? (
          user.usrName
        ) : (
          <TextField
            id="email"
            name="usrName"
            value={data.usrName}
            onChange={handleChange}
            defaultValue={data.usrName || user.usrName}
          />
        )}
        </TableCell>
      <TableCell align="center">
        {user.usrEmailID} 
      </TableCell>
      <TableCell>
      {			
      	<Select
				style={{ width: "100%",marginBottom:"20px" }}
				name="crdAssignedUser"
				/* defaultValue={getcurrentuserName}
				onChange={handleAssignedUserChange} */
        onChange={(e)=>handlePrivateChange(e,user)}
			  >
				{ (
				  Private.map((usr) => (
					<MenuItem value={usr}>{usr}</MenuItem>
				  ))
				) }
			  </Select>
			}
       <Button
            variant="contained"
            color="primary"
            onClick={AddUser}
          > Provide Access 
          </Button>
      </TableCell>
      {/* <TableCell align="center">
        {disable ? (
          usercanvas.map((canvas) => (
            <Typography>{canvas.crdName} </Typography>
          ))
        ) : (
          <FormControl className={classes.formControl}>
             <Select
              style={{ width: 100, marginTop: -2 }}
              multiple
              //name={canvas.CanvasName}
              value={filterData}
              onChange={handleCheckChange}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {canvas.length > 0 &&
                canvas.map((canvas) => (
                  <MenuItem value={canvas.DynamicID}>
                    {canvas.crdName}
                  </MenuItem>
                ))}
            </Select> 
          </FormControl>
        )}
      </TableCell> */}
      <TableCell align="center">
        <IconButton>

          {
           ( 
          disable ? (
            <CreateIcon onClick={editContent} />
          ) : (
            <CloseIcon onClick={editContent} />
          ))}
        </IconButton>
        <IconButton>{!disable && <SaveIcon onClick={mergeInfo} />}</IconButton>
        <IconButton>
        {
            ( 
          disable ? (<DeleteIcon onClick={deleteUser} />):("Delete"))}
        </IconButton>
      </TableCell>
      <SuccessSnackbar
        openSuccessAlert={openEditSuccessAlert}
        setopenSuccessAlert={setopenEditSuccessAlert}
        message="User Edited Successfully!"
        horizontal="top"
        vertical="center"
      />
      <SuccessSnackbar
        openSuccessAlert={openDeleteSuccessAlert}
        setopenSuccessAlert={setopenDeleteSuccessAlert}
        message="User Deleted Successfully!"
        horizontal="top"
        vertical="center"
      />
      <ErrorSnackbar
        openErrorAlert={openErrorAlert}
        setopenErrorAlert={setopenErrorAlert}
        horizontal="top"
        vertical="center"
      />
    </>
  );
}

export default ShowUsers;
