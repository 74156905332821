import logo from "../../../assets/images/logo.png";
import { QRCode } from 'react-qrcode-logo';

const QrGenerator = (props) => {

  return (
    <div>
      <QRCode  value={props.loc} qrStyle='dots' size={70} logoImage={logo} logoWidth={17} />
    </div>
  );
};

export default QrGenerator;
