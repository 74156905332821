import React from "react";
import { useState } from "react";
import { useStyles } from "../styles/DataFormCss";
import "../styles/custom.css";

import { FormControlLabel } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from '@material-ui/core/Switch';
import { SuccessSnackbar, ErrorSnackbar } from "./Snackbar";
import { Email } from "@material-ui/icons";

import { useEffect } from "react";
import { Checkbox, FormGroup } from "@material-ui/core";

const DataForm = (props) => {
  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  const typedata = JSON.parse(localStorage.getItem('static')).Type
  console.log(typedata);
  const { openForm, setOpenForm } = props; //To open the form Dialog
  const classes = useStyles(); //For styles
  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(false); //for loading until data is uploaded
  const [users, setUsers] = React.useState({}); //To get all the users
  const [openSuccessAlert, setopenSuccessAlert] = React.useState(false);
  const [openErrorAlert, setopenErrorAlert] = React.useState(false);
  const { canvasData, getDataFromSelect } = props;
  const [id, setid] = useState(props.canvasId);
  const [sprintname, setsprintname] = useState('');
  const [names, setNames] = useState([]);
  const [req, setReq] = useState('');
  const project_name = localStorage.getItem("PROJECT_NAME");
  const getBoardName = () => {
    const projectid = window.localStorage.getItem("PROJECT_ID");
    fetch(baseUrl + "/allsprints?prjId=" + projectid)
      .then((response) =>
        response.json().then((data) => {
          setNames(data)
          console.log(names)
        })
      )
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getBoardName();
  }, [0])

  function displayData(item) {
    return <MenuItem value={item} onClick={(e) => setReq(e.target.outerText)} >{item} </MenuItem>
  }
  const [customformdata, setcustomformdata] = useState([])

  const [customdata, setcustomdata] = useState({});



  const prioritydata = JSON.parse(localStorage.getItem('static')).Priority


  const [cardFormData, setCardFormData] = useState({
    crdAssignedUser: "",
    crdOverdue:0,

    crdStatus: "To-Do",
    Type: "1",
    /* crdSprintName:"", */
    crdName: "",
    crdDescription: "",
    crdDueDate: "",
    crdBusinessValue: 1,
    crdEmergency: 0,
    crdPriority: "",
    crdReporter: localStorage.getItem('email'),
    crdType: "",
    crdAcceptanceCriteria: "",
    crdWithdraw: 0,
    crdParentID: id,
    crdUpdateSubscriptions: [],
    customdata: {},
  });

  const clearCardFormState = () => {
    setCardFormData({
      crdAssignedUser: "",
      crdParentID: id,
      crdStatus: "To-Do",
      Type: "1",
      crdName: "",
      crdDescription: "",
      crdDueDate: "",
      crdBusinessValue: "",
      crdEmergency: 0,
      crdPriority: "",
      crdOverdue:0,

      crdType: "",
      crdAcceptanceCriteria: "",
      crdWithdraw: 0,
      crdUpdateSubscriptions: [],
      customdata: {},
    });
    setcustomformdata([])
    setcustomdata({});
  };

  const sendmail = (sentdata) => {


    var data = {

      Subject: "Create Notification",
      From: "",
      To: "",
      CC: [sentdata.crdAssignedUser, sentdata.crdReporter],
      Content: `Created the card and assigned to ${sentdata.crdAssignedUser}`,
    }

    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    const options = {
      method: "POST",
      headers: newHeaders,
      body: JSON.stringify(data),
    };

    fetch(baseUrl + "/sendmail", options).then(
      (res) => {




        res.json().then((data) => {
          console.log(data);
          console.log("Mail sent for adding card");


        })


      }
    )



  }

  //Submits new data
  const onFormSubmit = (data) => {
    console.log("FormData Submit", data);
    setLoading(true);
    data.Withdraw = 0;
    const apiURL = process.env.REACT_APP_HEROKU_URL;
    const newHeaders = new Headers();
    newHeaders.append("Content-Type", "application/json");
    const options = {
      method: "POST",
      headers: newHeaders,
      body: JSON.stringify(data),
    };
    fetch(apiURL + "/add", options)
      .then((res) => res.json())
      .then(
        (result) => {
          setResult({
            isAdded: true,
          });
          console.log("FormData Res", result);
          sendmail(data);
          props.getData(props.canvasId);
          handleFormClose();
          clearCardFormState();
          setopenSuccessAlert(true);
        },
        (error) => {
          setResult({ error });
          alert("Please Check all the fields");
          setLoading(false);
          console.log(error);
          setopenErrorAlert(true);
        }
      );
  };

  //Handles submit for new data
  const handleSubmit = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();

    if (cardFormData.crdDueDate === undefined) {
      cardFormData.crdDueDate = today();
    }
    if (cardFormData.crdBusinessValue === undefined) {
      cardFormData.crdBusinessValue = 0;
    }
    if (cardFormData.crdEmergency === undefined) {
      cardFormData.crdEmergency = 0;
    }
    if (cardFormData.crdStatus === undefined) {
      cardFormData.crdStatus = "To-Do";
    }
    if (cardFormData.crdAssignedUser === "") {
      cardFormData.crdAssignedUser = localStorage.getItem("email");
    }
    //cardFormData.crdUpdateSubscriptions.push(cardFormData.crdAssignedUser)
    cardFormData.customdata = customdata;

    /* cardFormData.crdSprintName=sprintname; */

    onFormSubmit(cardFormData);
  };
  const getcurrentuserName = () => {
    var x
    users?.map((usr) => {
      if (usr.usrEmailID === localStorage.getItem('email')) {

        x = usr.usrName;

      }

    }
    )
    return x;
  }

  const handleFormClose = () => {
    setOpenForm(false);
    setLoading(false);
    props = {};
    clearCardFormState();
  };

  //Any new changes in form

  const handleCardFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setCardFormData({ ...cardFormData, [name]: value });
  };

  const handlecustomChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setcustomdata({ ...customdata, [name]: value })
  };


  // const returncomponent=()=>{

  //   const listItems = customformdata.map((data) =>
  //   // Correct! Key should be specified inside the array.
  //   {
  //     if(data.cname === "TextField")
  //     {

  //          return (<TextField  label={data.label}></TextField>)
  //       }
  //       else if(data.cname=="Dropdown")
  //       {

  //        return(<div  >

  //                     <InputLabel
  //                         id="select-priot"
  //                           style={{ marginTop: "10px", width: "100%" }}
  //                        >
  //                          {data.label}
  //                        </InputLabel>
  //                        <Select
  //                          style={{ width: "100%" }}


  //                        >
  //                          {data.values.split(",").length > 0 ? (
  //                                  data.values.split(",").map((usr) => (
  //                                    <MenuItem value={usr}>{usr}</MenuItem>
  //                                  ))
  //                                ) : (
  //                                  <MenuItem value={"0"}>N/A</MenuItem>
  //                          )}

  //      </Select>

  //        </div>)





  //      }
  //      else if(data.cname =="TextArea")
  //      {

  //      return( <TextField   label={data.label} multiline
  //        maxRows={4} ></TextField>)




  //      }
  //      else if(data.cname == "Checkbox")
  //      {

  //        return(<Checkbox  label={data.label} />)
  //      }
  //      else if(data.cname == "Date")
  //      {
  //                       return(    <TextField
  //                id="date"
  //                type="date"

  //                InputLabelProps={{
  //                  shrink: true,
  //                }}
  //                inputProps={{
  //                  min: `${today()}`,
  //                }}
  //              />)


  //      }
  //      else
  //      {
  //        return(<div>

  //        </div>)
  //      }


  //   }

  // )

  // return listItems;


  // }

  const componentreturn = (data) => {

    if (data.cname === "TextField") {

      return (<TextField label={data.label} name={data.label} value={customdata["data.label"]} onChange={handlecustomChange}></TextField>)
    }
    else if (data.cname == "Dropdown") {

      return (<div  >

        <InputLabel
          id="select-priot"
          style={{ marginTop: "10px", width: "100%" }}
        >
          {data.label}
        </InputLabel>
        <Select
          style={{ width: "100%" }}
          name={data.label}
          value={customdata["data.label"]}
          onChange={handlecustomChange}
        >
          {data.values.split(",").length > 0 ? (
            data.values.split(",").map((usr) => (
              <MenuItem value={usr}>{usr}</MenuItem>
            ))
          ) : (
            <MenuItem value={"0"}>N/A</MenuItem>
          )}

        </Select>

      </div>)





    }
    else if (data.cname == "TextArea") {

      return (<TextField label={data.label} multiline
        maxRows={4} name={data.label} value={customdata["data.label"]} onChange={handlecustomChange} ></TextField>)




    }
    else if (data.cname == "Checkbox") {
      return (<FormGroup><FormControlLabel control={<Checkbox name={data.label} value={customdata["data.label"]} onChange={handlecustomChange} />} label={data.label} /></FormGroup>)

    }
    else if (data.cname == "Date") {
      return (<TextField
        id="date"
        type="date"
        name={data.label}
        value={customdata["data.label"]}
        onChange={handlecustomChange}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          min: `${today()}`,
        }}
      />)


    }
    else {
      return (<div>

      </div>)
    }









  }

  const handleCardTypeChange = (event) => {

    const target = event.target;
    const value = target.value;
    const name = target.name;
    setcustomformdata([])
    setcustomdata({})

    typedata.map((x) => {

      if (x.name === value) {
        setcustomformdata(x.customform);
      }
    })
    console.log(customformdata);
    var labels = []
    var res = {}

    customformdata.map((x) => {
      labels.push(x.label);

    })

    console.log(labels);

    labels.map((x) => {
      res[x] = ""

    })
    console.log(res);
    setcustomdata(res);
    console.log(customdata);

    setCardFormData({ ...cardFormData, [name]: value });






  }
  const handleAssignedUserChange = (event) => {

    const value = event.target.value
    var res;
    users.map((x) => {

      if (x.usrName === value) {
        res = x.usrEmailID;
      }
    })
    setCardFormData({ ...cardFormData, [event.target.name]: res });

  }

  const handlesprintname = (e) => {
    setsprintname(e.target.value);
    console.log(sprintname);
    setCardFormData({ ...cardFormData, crdSprintName: e.target.value });
  };
  //Current Date
  const today = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    //Handles Update Function
    return yyyy + "-" + mm + "-" + dd;
  };

  //To get All the users
  const getUsers = () => {
    console.log("Ready to get ");
    const mainUrl = process.env.REACT_APP_HEROKU_URL;
    fetch(mainUrl + "/user/list")
      .then((response) =>
        response.json().then((data) => {
          console.log(data);
          console.log("Called Users Now");
          data.sort((a, b) => (a.usrName > b.usrName) ? 1 : ((b.usrName > a.usrName) ? -1 : 0))
          console.log(data);
          setUsers(data)
        })
      )
      .catch((err) => console.log(err));
  };

  //To set enable disable
  const isEnabled = () => {
    var obj = cardFormData;
    console.log(cardFormData);
    console.log(customdata);
    for (var key in obj) {
      if (
        obj[key] === "" &&
        (key === "crdName" || key === "crdStatus" || key === "crdEmergency")
      )
        return false;
    }
    return true;
  };

  var flag = isEnabled();

  //To run after loading the view
  React.useEffect(() => {

    console.log(typedata);
    getUsers();
    flag = isEnabled();
  }, []);

  const handleChange = (a) => {
    setid(a.target.value);
  };

  return (
    <div>
      <Dialog
        className={classes.dialogStyle}
        maxWidth="lg"
        open={openForm}
        onClose={handleFormClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle>
          <div className={classes.formTitle}>Add New Story</div>

          <div className={classes.datePicker}>
            <span style={{ fontSize: "18px" }}>Due Date :</span> &nbsp;
            <TextField
              id="date"
              type="date"
              name="crdDueDate"
              value={cardFormData.crdDueDate}
              onChange={handleCardFormChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: `${today()}`,
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <React.Fragment>
            <Card className="card-class">
              <Typography component="h2" variant="h5"></Typography>
              <CardContent>
                <form>
                  <div>
                    <div className={classes.status + " sts"}>
                      <InputLabel id="selectop">Status</InputLabel>
                      <Select
                        className={classes.selectOpt}
                        autoFocus
                        name="crdStatus"
                        defaultValue="To-Do"
                        value={cardFormData.crdStatus}
                        onChange={handleCardFormChange}
                      >
                        <MenuItem value={"To-Do"}>To-Do</MenuItem>
                        <MenuItem value={"Ready"}>Ready</MenuItem>
                        <MenuItem value={"In-Progress"}>In-Progress</MenuItem>
                        {/* <MenuItem value={"Under Review"}>Under Review</MenuItem>
                        <MenuItem value={"Done"}>Done</MenuItem> */}
                      </Select>
                    </div>





                    <div className="typ">
                      <InputLabel id="select-priot">Type</InputLabel>
                      <Select
                        className={classes.selectOpt}
                        name="crdType"


                        onChange={handleCardTypeChange}
                      >
                        {typedata.length > 0 ? (
                          typedata.map((tp) => (
                            <MenuItem value={tp.name}>{tp.name}</MenuItem>
                          ))
                        ) : (
                          <MenuItem value={"0"}>N/A</MenuItem>
                        )}
                      </Select>
                    </div>
                    {/* <div>
                      <InputLabel style={{ margin: 9 }}>Board</InputLabel>
                      <Select
                        defaultValue={props.canvasId}
                        id="demo-customized-select-native"
                        className={classes.select}
                        onChange={handleChange}
                        style={{ margin: 5 }}
                      >
                        {canvasData.length > 0 &&
                          canvasData.map((canvas, index) => (
                            <option key={index} value={canvas.CanvasId}>
                              {canvas.CanvasName}
                            </option>
                          ))}
                      </Select>
                    </div> */}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="standard-text-input"
                      label="Summary"
                      type="text"
                      name="crdName"
                      value={cardFormData.crdName}
                      onChange={handleCardFormChange}
                      multiline
                      rows={1}
                      autoComplete="current-text"
                    />
                    <br></br>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="standard-text-input"
                      label="Description"
                      type="text"
                      name="crdDescription"
                      multiline
                      value={cardFormData.crdDescription}
                      onChange={handleCardFormChange}
                      rows={3}
                      autoComplete="current-text"
                    />
                    <br></br>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="standard-text-input"
                      label="Acceptancy Criteria"
                      name="crdAcceptanceCriteria"
                      value={cardFormData.crdAcceptanceCriteria}
                      onChange={handleCardFormChange}
                      type="text"
                      multiline
                      rows={2}
                      autoComplete="current-text"
                    />
                    <br></br>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="standard-text-input"
                      label="Comments"
                      name="crdComment"
                      value={cardFormData.crdComment}
                      onChange={handleCardFormChange}
                      type="text"
                      multiline
                      rows={3}
                      autoComplete="current-text"
                    />
                    <br></br>

                    <div className={classes.formTitle}>
                      <b>Default Section</b>
                    </div>
                    <br></br>
                    <br></br>
                    <div>
                      <div className={classes.emergency}>
                        <InputLabel id="select-priot">Emergency</InputLabel>
                        <Switch

                          onChange={handleCardFormChange}
                          color="primary"
                          value={cardFormData.crdEmergency}
                          name="crdEmergency"
                          id="emergency"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </div>

                      <div className="prt">
                        <InputLabel id="select-priot">Priority</InputLabel>
                        <Select
                          className={classes.selectOpt}
                          name="crdPriority"
                          value={cardFormData.crdPriority}
                          onChange={handleCardFormChange}
                        >

                          {prioritydata.length > 0 ? (
                            prioritydata.map((usr) => (
                              <MenuItem value={usr}>{usr}</MenuItem>
                            ))
                          ) : (
                            <MenuItem value={"0"}>N/A</MenuItem>
                          )}
                        </Select>
                      </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div>
                      <div className={classes.value}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          label="Estimation"
                          type="Number"
                          name="crdBusinessValue"
                          defaultValue={0}
                          value={cardFormData.crdBusinessValue}
                          onChange={handleCardFormChange}
                        />
                      </div>
                      <div className={classes.assignTo}>
                        <InputLabel
                          id="select-priot"
                          style={{ marginTop: "10px", width: "100%" }}
                        >
                          Assigned To
                        </InputLabel>
                        <Select
                          style={{ width: "100%" }}
                          name="crdAssignedUser"
                          defaultValue={getcurrentuserName}
                          onChange={handleAssignedUserChange}
                        >
                          {users.length > 0 ? (
                            users.map((usr) => (
                              <MenuItem value={usr.usrName}>{usr.usrName}</MenuItem>
                            ))
                          ) : (
                            <MenuItem value={"0"}>N/A</MenuItem>
                          )}
                        </Select>
                        &nbsp; <div>
                          <InputLabel id="selectop">Super Sprint Name</InputLabel>
                          <Select style={{ position: "relative", left: "12%", width: "150px" }}
                            onChange={handlesprintname}>
                            {
                              names.map(displayData)
                            }

                          </Select>
                        </div>

                      </div>
                    </div>
                  </div>
                </form>

                <form>
                  <div>



                    {
                      customformdata?.length > 0 ? (customformdata?.map(

                        (data, index) => {

                          {

                            return (<div key={index}>
                              {componentreturn(data)}
                            </div>)

                          }


                        }
                      )) :
                        (<div>

                        </div>)

                      // customformdata.length > 0 && {
                      //   returncomponent
                      // }




                    }
                  </div>

                </form>
              </CardContent>
            </Card>
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="Primary"
            disabled={flag === false}
            onClick={handleSubmit}
          >
            {loading === true ? (
              <CircularProgress color="secondary" />
            ) : (
              <span>Add New</span>
            )}
          </Button>
          &nbsp;
          <Button
            onClick={handleFormClose}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessSnackbar
        openSuccessAlert={openSuccessAlert}
        setopenSuccessAlert={setopenSuccessAlert}
        message="Card Added Successfully!"
        horizontal="top"
        vertical="center"
      />
      <ErrorSnackbar
        openErrorAlert={openErrorAlert}
        setopenErrorAlert={setopenErrorAlert}
        horizontal="top"
        vertical="center"
      />
    </div>
  );
};

export default DataForm;
