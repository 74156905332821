import React, { useEffect, useState } from "react";

const QRFile = () => {
  const [card, setCard] = useState([]);
  const [counter, setCounter] = useState(3);
  const url = window.location.pathname.split("/")[2];
  console.log(url);
  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  useEffect(() => {
    const getData = () => {
      fetch(`${baseUrl}/cardDetails?id=${url}`)
        .then((res) =>
          res.json().then((data) => {
            setCard(data);
          })
        )
        .catch((e) => console.log(e));
    };

    getData();
  }, []);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  if (counter === 0) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseUrl}/cardStatusNext?id=${url}`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  }

  var next = "";
  if (card.crdStatus === "To-Do") {
    next = "Ready";
  } else if (card.crdStatus === "Ready") {
    next = "In Progress";
  } else if (card.crdStatus === "In Progress") {
    next = "Under Review";
  } else if (card.crdStatus === "Under Review") {
    next = "Done";
  } else if (card.crdStatus === "Done") {
    next = "Withdrawn";
  }

  return (
    <div>
      <b>{counter}</b>
      <div style={{ paddingLeft: "10vw", paddingTop: "10vh" }}>
        <h1>QR card update</h1>
        <h3 style={{ paddingTop: "5vh" }}>DONE!!</h3>
        <br></br>
        The card <b>{card.DynamicID}</b> is being updated from{" "}
        <b>{card.crdStatus}</b> to <b>{next}</b>
      </div>
    </div>
  );
};

export default QRFile;
