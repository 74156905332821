import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GetAppIcon from "@material-ui/icons/GetApp";
import omnivuelogo from "../../assets/images/omnivuelogo.jpg";
import Parsing from "../views/Parsing";
import { usePapaParse } from "react-papaparse";
import RefreshIcon from '@mui/icons-material/Refresh';

//MUI Stuffs
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Switch from '@mui/material/Switch';
import AutorenewIcon from "@material-ui/icons/Autorenew";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {
  AppBar,
  Toolbar,
  InputBase,
  Typography,
  Button,
} from "@material-ui/core";
import { jsPDF } from "jspdf";
import SearchIcon from "@material-ui/icons/Search";
import html2canvas from "html2canvas";
import MoreIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { SuccessSnackbar, ErrorSnackbar } from "./Snackbar";
import CanvasTable from "./CanvasTable";
import DataForm from "./DataForm";
import AddCanvas from "./AddCanvas";
import Footer from "./Footer";

import Header from "./Header";
import { useStyles } from "../styles/CanvasCss";
import { useTheme } from "@material-ui/core/styles";

import TabScrollButton from "@material-ui/core/TabScrollButton";
import DataFormB from "./DataformB";

// Creating Context to use markAsDone Function at other components
export const CardContext = React.createContext({
  markAsDone: null,
});

const datatobeused = [
  {
    Type: "1",
    crdParenId: "Prj-Board-11",
    crdName: "dummycard1",
    crdStatus: "To-do",
    crdType: "Card",
  },
  {
    Type: "1",
    crdParenId: "Prj-Board-11",
    crdName: "dummycard3",
    crdStatus: "Ready",
    crdType: "Card",
  },
  {
    Type: "1",
    crdParenId: "Prj-Board-11",
    crdName: "dummycard2",
    crdStatus: "In-Progress",
    crdType: "Card",
  },

  {
    Type: "0",
    crdParenId: "Prj-Board-11",
    crdName: "dummyboard1",
    crdStatus: "Ready",
    crdType: "Board",
  },
];

// Main Function
const Canvas = (props) => {
  const { jsonToCSV } = usePapaParse();
  const typedata = JSON.parse(localStorage.getItem("static")).Type;
  const status = JSON.parse(localStorage.getItem("static")).Status;
  var types;
  var types = typedata.map((x) => x.name);
  // To grab parent Id
  function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  React.useEffect(() => {
    fetch(baseUrl + "/getallboard").then((res) => {
      console.log(res);
      res.json().then((data) => {
        console.log(data);
        setCanvasData(data);
      });
    });
  }, []);

  const classes = useStyles(); // To apply CSS
  const { canvasId } = useParams();
  //const { canvases } = props.location.state;
  const theme = useTheme();
  let usremail = window.localStorage.getItem("email");
  let projectid = window.localStorage.getItem("PROJECT_ID");

  // To Store parentId
  const prevId = usePrevious({ canvasId });

  // Getting userId and email from Browser's Local storage

  const email = localStorage.getItem("email");

  // Setting up some states to be used

  const [canvasData, setCanvasData] = useState({});
  const [board, setBoard] = useState([]);

  const [openForm, setOpenForm] = React.useState(false);
  const [spin, setSpin] = React.useState(0);
  const [activeCanvasId, setActiveCanvasId] = React.useState(canvasId);
  const [loading, setLoading] = React.useState(true);
  const [update, setUpdate] = React.useState(false);
  const [mode, setMode] = React.useState("online");
  const [searchs, setState] = React.useState({ search: "" });

  const [openSuccessAlert, setopenSuccessAlert] = React.useState(false);
  const [openErrorAlert, setopenErrorAlert] = React.useState(false);

  const [openDragAlert, setopenDragAlert] = React.useState(false);

  const baseUrl = process.env.REACT_APP_HEROKU_URL; // Getting API URL from env file
  var lanes = JSON.parse(process.env.REACT_APP_FIXED_LANE); // Getting Lane Confiuration from env file

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [open, setOpen] = React.useState(false);

  const [check, setChecked] = useState(false)
  const handleClickToOpen = () => {
    setOpen(true);
  };

  const handleToClose = () => {
    setOpen(false);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleChecked = e => {
    setChecked(e.target.checked)
  }

  React.useEffect(() => {
    // Reload if board id changes

    if (prevId !== undefined) {
      if (prevId.canvasId !== canvasId) {
        console.log("object");
        window.location.reload();
      }
    }
    //setActiveCanvasId(canvasId);
    getData();
  }, [canvasId]);

  console.log("Board", board);
  const getStructure = (data) => {
    console.log("innit right");

    var newData = [
      // {
      //   Cards:[],
      //   LaneId:"L0",
      //   Status:"To-Do",
      //   TotalRowCount:0,
      // },
      // {
      //   Cards:[],
      //   LaneId:"L1",
      //   Status:"Ready",
      //   TotalRowCount:0,
      // },
      // {
      //   Cards:[],
      //   LaneId:"L2",
      //   Status:"In-Progress",
      //   TotalRowCount:0,
      // },
      // {
      //   Cards:[],
      //   LaneId:"L3",
      //   Status:"Under Review",
      //   TotalRowCount:0,
      // },
      // {
      //   Cards:[],
      //   LaneId:"L4",
      //   Status:"Done",
      //   TotalRowCount:0,
      // }
    ];
    console.log(status);
    status.map((x) => {
      newData.push({
        Cards: [],

        Status: x.name,
        TotalRowCount: 0,
      });
    });

    console.log(newData);

    data.map((x) => {
      console.log(x);

      const index = status.findIndex((object) => {
        return object.name === x.crdStatus;
      });

      console.log("Here are the indices", index);
      console.log("Here is indices data", newData[index]);
      if (index > -1) {
        newData[index].Cards.push(x);
        newData[index].TotalRowCount = newData[index].Cards.length;
      }

      // newData[statusnames.indexOf(x.crdStatus)].TotalRowCount= newData[statusnames.indexOf(x.crdStatus)].Cards.length

      // if(x.crdStatus=="To-Do")
      // {
      //   newData[0].Cards.push(x)

      //   newData[0].TotalRowCount=newData[0].Cards.length

      // }
      // else if(x.crdStatus=="Ready")
      // {
      //   newData[1].Cards.push(x)

      //   newData[1].TotalRowCount=newData[1].Cards.length

      // }
      // else if(x.crdStatus=="In-Progress")
      // {
      //   newData[2].Cards.push(x)

      //   newData[2].TotalRowCount=newData[2].Cards.length

      // }
      // else if(x.crdStatus=="Under Review")
      // {
      //   newData[3].Cards.push(x)

      //   newData[3].TotalRowCount=newData[3].Cards.length

      // }
      // else if(x.crdStatus=="Done")
      // {
      //   newData[4].Cards.push(x)

      //   newData[4].TotalRowCount=newData[4].Cards.length
      // }
    });

    console.log("Here is the new data", newData);

    setBoard(newData);
    setLoading(false);
  };
  // Function to change Lanes upon Drag and Drop
  const LANE_CONFIG = {
    L0: ["L1", "L2", "L3"],
    L1: ["L0", "L2", "L3"],
    L2: ["L1", "L3", "L4"],
    L3: ["L1", "L2", "L4"],
    L4: [],
  };

  const moveCard = (Card, col, CanvasId, targetId) => {
    if (targetId === "L0") Card.Status = lanes["L0"];
    if (targetId === "L1") Card.Status = lanes["L1"];
    if (targetId === "L2") Card.Status = lanes["L2"];
    if (targetId === "L3") Card.Status = lanes["L3"];
    if (targetId === "L4") Card.Status = lanes["L4"];

    Card.CanvasId = CanvasId;
    Card.crdAssignedUser = localStorage.getItem("email");

    // Creating request options to send to backend (PUT operation)
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(Card),
    };
    // Sending data to backend
    fetch(baseUrl, requestOptions)
      .then((response) => {
        response.ok
          ? response.json().then((data) => {
            //setopenSuccessAlert(true);
            setopenSuccessAlert(true);
            getData();
          })
          : setopenErrorAlert(true);
      })
      .catch((err) => console.log(err));
    //.finally(() => setLoading(false));
  };

  const markAsDone = (Card, col, CanvasId, targetId) => {
    if (col.Status === "To-Do") {
      if (LANE_CONFIG.L0.includes(targetId)) {
        moveCard(Card, col, CanvasId, targetId);
      } else setopenDragAlert(true);
    }
    if (col.Status === "Ready") {
      if (LANE_CONFIG.L1.includes(targetId)) {
        moveCard(Card, col, CanvasId, targetId);
      } else setopenDragAlert(true);
    }
    if (col.Status === "In-Progress") {
      if (LANE_CONFIG.L2.includes(targetId)) {
        moveCard(Card, col, CanvasId, targetId);
      } else setopenDragAlert(true);
    }
    if (col.Status === "Under Review") {
      if (LANE_CONFIG.L3.includes(targetId)) {
        moveCard(Card, col, CanvasId, targetId);
      } else setopenDragAlert(true);
    }
    if (col.Status === "Done") {
      if (LANE_CONFIG.L4.includes(targetId)) {
        moveCard(Card, col, CanvasId, targetId);
      } else setopenDragAlert(true);
    }
  };

  // Getting Canvas Data
  const getData = () => {
    setActiveCanvasId(activeCanvasId);
    console.log(email);
    console.log(activeCanvasId);

    activeCanvasId.slice(0, 1) === "s"
      ? fetch(baseUrl + "/sprintcard?crdSprintName=" + activeCanvasId)
        .then((response) =>
          response.json().then((data) => {
            //setBoard(data);
            localStorage.setItem(`canvas-${canvasId}`, JSON.stringify(data));
            getStructure(data);
            console.log("data is", data);
          })
        )
        .catch((err) => {
          console.log(err);
          setMode("offline");
          let collection = localStorage.getItem(`canvas-${canvasId}`);
          setBoard(JSON.parse(collection));
        })
        .finally(() => setLoading(false))
      : fetch(baseUrl + "/getallchild?crdParentID=" + activeCanvasId + "&email=" + usremail + "&prjId=" + projectid)
        .then((response) =>
          response.json().then((data) => {
            //setBoard(data);
            localStorage.setItem(`canvas-${canvasId}`, JSON.stringify(data));
            getStructure(data);
            console.log("data is", data);
          })
        )
        .catch((err) => {
          console.log(err);
          setMode("offline");
          let collection = localStorage.getItem(`canvas-${canvasId}`);
          setBoard(JSON.parse(collection));
        })
        .finally(() => setLoading(false));
  };

  // Gettig Canvas data on select of Dropdown
  const getDataFromSelect = (event) => {
    props.history.push({
      pathname: `/board/${event.target.value}`,
      state: { canvases: canvasData },
    });
    //console.log("Change", event.target.value);
    event && setActiveCanvasId(event.target.value);
    localStorage.setItem("activeCanvas", event.target.value);
    fetch(baseUrl + "/getallchild?crdParentID=" + event.target.value + "&email=" + usremail + "&prjId=" + projectid)
      .then((response) =>
        response.json().then((data) => {
          //setBoard(data);
          getStructure(data);
          localStorage.setItem(
            `canvas-${event.target.value}`,
            JSON.stringify(data)
          );
        })
      )
      .catch((err) => {
        console.log(err);
        setMode("offline");
        let collection = localStorage.getItem(`canvas-${event.target.value}`);
        setBoard(JSON.parse(collection));
      })
      .finally(() => setLoading(false));
  };

  // Function for refreshing canvas
  const refreshCanvas = () => {
    setSpin(1);
    getData(); // fetching latest data

    setTimeout(() => {
      setSpin(false);
    }, 1000);
  };

  const SearchInCanvas = (event) => {
    let keyword = event.target.value;
    setState({ search: keyword });
  };
  const Ddata = [...types, "Board"];
  const [filterData, setFilterData] = React.useState([...types, "Board"]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const Export1 = (value) => {
    const baseUrl = process.env.REACT_APP_HEROKU_URL;

    try {
      fetch(baseUrl + "/getallchild?crdParentID=" + activeCanvasId + "&email=" + usremail + "&prjId=" + projectid)
        .then((response) =>
          response.json().then((data) => {
            console.log(data);

            var newArray = [];

            newArray = data.map(function (item) {
              return {
                Id: item.DynamicID,
                Type: item.Type,
                Withdraw: item.Withdraw,
                "Acceptance Criteria": item.crdAcceptanceCriteria,
                "Assigned User": item.crdAssignedUser,
                "Business Value": item.crdBusinessValue,
                Comment: item.crdComment,
                Description: item.crdDescription,
                DueDate: item.crdDueDate,
                Emergency: item.crdEmergency,
                Name: item.crdName,
                ParentID: item.crdParentID,
                Priority: item.crdPriority,
                Reporter: item.crdReporter,
                Status: item.crdStatus,
                crdType: item.crdType,
                crdOverdue: 0,
                Subscriptions: item.crdUpdateSubscriptions,
                crdWithdraw: item.crdWithdraw,
                sprintName: item.crdSprintName,
              };
            });
            console.log(newArray);
            const results = jsonToCSV(newArray);
            console.log(results);
            // data = JSON.parse(data);
            // let CanvasObject = [];
            // for (let y in data) {
            //   CanvasObject.push(data[y]);
            // }
            // console.log(CanvasObject);
            // CanvasObject = [Object.keys(CanvasObject[0])].concat(CanvasObject);
            // console.log(CanvasObject[0]);
            // CanvasObject[0]=['ID', 'Type', 'Withdraw', 'Acceptance Criteria', 'Assigned User', 'Business Value','Comment', 'Description', 'DueDate', 'Emergency', 'Name', 'ParentID', 'Priority','Reporter','Status', 'crdType', 'Subscriptions', 'crdWithdraw']
            // console.log(CanvasObject);
            // CanvasObject = CanvasObject.map((canrow) => {
            //   return Object.values(canrow)
            //     .map((canvalue) => {
            //       return typeof canvalue === "string"
            //         ? JSON.stringify(canvalue)
            //         : canvalue;
            //     })
            //     .toString();
            // }).join("\n");
            // console.log("To see");
            // console.log(CanvasObject);

            // let CanvasObject1 = CanvasObject.replace(/,/g, "\t");
            // CanvasObject1 = CanvasObject1.replace(/"/g, "");
            // console.log(CanvasObject1);
            // const jsonToTxt = require("json-to-txt");
            // const dataInString = jsonToTxt({ data: data });

            const element = document.createElement("a");
            switch (value) {
              case "Text":
                //.txt code
                // const file_txt = new Blob([dataInString], {
                //   type: "plain/text",
                // });
                // element.href = URL.createObjectURL(file_txt);
                // element.download = String(activeCanvasId) + ".txt";
                // document.body.appendChild(element);
                // element.click();
                break;

              case "Json":
                // .json code
                // const file_json = new Blob([dataInString], {
                //   type: "application/json",
                // });
                // element.href = URL.createObjectURL(file_json);
                // element.download = String(activeCanvasId) + ".json";
                // document.body.appendChild(element);
                // element.click();
                break;

              case "Csv":
                //.csv code
                const file_csv = new Blob([results], { type: "text/csv" });
                element.href = URL.createObjectURL(file_csv);
                element.download = String(activeCanvasId) + ".csv";
                document.body.appendChild(element);
                element.click();
                break;

              case "Png":
                html2canvas(document.querySelector("#root"), {
                  allowTaint: true,
                  useCORS: true,
                  foreignObjectRendering: true,
                }).then((currentcanvas) => {
                  element.href = currentcanvas
                    .toDataURL("image/png")
                    .replace("image/png", "image/octet-stream");
                  element.download = String(activeCanvasId) + ".png";
                  element.click();
                });
                break;

              case "Jpeg":
                html2canvas(document.querySelector("#root"), {
                  allowTaint: true,
                  useCORS: true,
                  foreignObjectRendering: true,
                }).then((currentcanvas) => {
                  element.href = currentcanvas
                    .toDataURL("image/jpeg")
                    .replace("image/jpeg", "image/octet-stream");
                  element.download = String(activeCanvasId) + ".jpeg";
                  element.click();
                });
                break;

              case "Pdf":
                html2canvas(document.querySelector("#root"), {
                  allowTaint: true,
                  useCORS: true,
                  foreignObjectRendering: true,
                }).then((currentcanvas) => {
                  var image = currentcanvas
                    .toDataURL("image/png")
                    .replace("image/png", "image/octet-stream");
                  var doc = new jsPDF("landscape");
                  var width = doc.internal.pageSize.getWidth();
                  var height = doc.internal.pageSize.getHeight();

                  doc.addImage(image, "PNG", 0, 0, width, height);
                  doc.save(String(activeCanvasId) + ".pdf");
                });
                break;

              case "Xml":
                function toXML(obj) {
                  var xml = "";
                  for (var prop in obj) {
                    xml += obj[prop] instanceof Array ? "" : "<" + prop + ">";
                    if (obj[prop] instanceof Array) {
                      for (var array in obj[prop]) {
                        xml += "<" + prop + ">";
                        xml += toXML(new Object(obj[prop][array]));
                        xml += "</" + prop + ">\n";
                      }
                    } else if (typeof obj[prop] == "object") {
                      xml += toXML(new Object(obj[prop]));
                    } else {
                      xml += obj[prop];
                    }
                    xml +=
                      obj[prop] instanceof Array ? "" : "</" + prop + ">\n";
                  }
                  var xml = xml.replace(/<\/?[0-9]{1,}>/g, "");
                  return xml;
                }
                var output = toXML(data);
                const file_xml = new Blob([output], { type: "text/xml" });
                element.href = URL.createObjectURL(file_xml);
                element.download = String(activeCanvasId) + ".xml";
                element.click();
                break;
            }
          })
        )
        .catch((err) => console.log("From error", err));
    } catch {
      console.log("catch");
    }
  };

  const [value, setValue] = React.useState("");
  function handleChangeExp(event) {
    setValue(() => event.target.value);
    Export1(event.target.value);
  }

  //mobile menu
  const mobileMenuId = "primary-search-account-menu-mobile";
  const Scrollleft = () => {
    window.scrollTo({ left: 0, behavior: "smooth" });
  };
  const Scrollright = () => {
    window.scrollBy(100, 0);
  };
  const setzoom = () => {
    document.body.style.zoom = 0.8;
  };
  // zoom function
  const Zoom = () => {
    useEffect(() => {
      const initialValue = document.body.style.zoom;

      // Change zoom level on mount
      document.body.style.zoom = "90%";

      return () => {
        // Restore default value
        document.body.style.zoom = initialValue;
      };
    }, []);
    return <></>;
  };
  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <Header x="Cards" />
      <div>
        {mode === "offline" ? (
          // <FlashMessage duration={2000}>
          //   {/* <strong>Hello to all will hide after 5 seconds!</strong> */}
          //   <h3
          //     style={{
          //       display: "inline-block",
          //       color: "lightGrey",
          //       backgroundColor: "#8c7373",
          //       textAlign: "center",
          //     }}
          //   >
          //     you are in offline mode
          //   </h3>
          // </FlashMessage>
          <div></div>
        ) : null}
      </div>

      {/* app bar for positioning */}

      {/* <div
        style={{
          position: "relative",
          top: "70px",
          marginLeft: "85px",
          backgroundColor: "#fff",
        }}
      >

        <Button variant="outlined" onClick={() => { getData() }}>
          <IconButton >
            <RefreshIcon color="primary" size="large" />
          </IconButton>
        </Button> */}


      {/* <TabScrollButton className="rightscroll " direction="right" orientation="horizontal" onClick={()=>{rightscroll.style.zoom="100%"}}/> */}
      {/* </div> */}

      {/* targetting page zoom to 90% on page load */}
      <Zoom />

      {/* Display Loading screen untill getting data from backend */}
      {loading ? (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            marginLeft: "-50px",
            marginTop: "-50px",
          }}
        >
          <CircularProgress size={100} />
          <h5 style={{ fontSize: 20 }}> Loading..... </h5>
        </div>
      ) : (
        <>
          {/* Canvas Dropdown */}
          <div className="formControl"></div>

          <AppBar position="fixed" className={classes.appbar}>
            <Toolbar>
              <Typography className={classes.title} variant="h6" noWrap>
                Cards
              </Typography>

              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  {/* <SearchIcon /> */}
                </div>
                {/* <InputBase
                  placeholder="Search…"
                  id="search"
                  onChange={SearchInCanvas}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  disabled
                  inputProps={{ "aria-label": "search" }}
                /> */}
              </div>
              <div className={classes.grow}></div>
              <p style={{ color: "black", paddingTop: 20 }}>QR</p>
              <Switch
                checked={check}
                onChange={handleChecked}
                aria-label="qr-status"
              />

              <div>
                {/* <div className={classes.filter}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={Ddata}
                    // fullWidth={true}
                    size="small"
                    filterSelectedOptions={true}
                    limitTags={1}
                    onChange={(event, value) => setFilterData(value)}
                    defaultValue={Ddata}
                    getOptionLabel={(option) => option}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 5 }}
                          checked={selected}
                        />
                        {option}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Filter"
                        placeholder="Type"
                      />
                    )}
                  />
                </div> */}
                <Button variant="outlined" onClick={() => { getData() }}>
                  <IconButton size="small">
                    <RefreshIcon color="primary" size="large" />
                  </IconButton>
                </Button>
              </div>
              <div className={classes.sectionDesktop}>
                <IconButton
                  className={classes.icons}
                  onClick={() => {
                    setOpenForm(true);
                  }}
                >
                  <Tooltip title="Add Card" placement="top">
                    <AddCircleOutlineIcon fontSize="large" color="primary" />
                  </Tooltip>
                </IconButton>

                {/* DataForm to add Canvas */}
                {activeCanvasId.slice(0, 1) === "s" ? (
                  <DataForm
                    openForm={openForm}
                    setOpenForm={setOpenForm}
                    canvasId={activeCanvasId}
                    canvasData={canvasData}
                    update={update}
                    setUpdate={setUpdate}
                    getData={getData}
                    getDataFromSelect={getDataFromSelect}
                    refreshCanvas={refreshCanvas}
                    activeCanvasId={activeCanvasId}
                  />
                ) : (
                  <DataFormB
                    openForm={openForm}
                    setOpenForm={setOpenForm}
                    canvasId={activeCanvasId}
                    canvasData={canvasData}
                    update={update}
                    setUpdate={setUpdate}
                    getData={getData}
                    getDataFromSelect={getDataFromSelect}
                    refreshCanvas={refreshCanvas}
                    activeCanvasId={activeCanvasId}
                  />
                )}
                {/* <IconButton className={classes.icons} color="primary">
                  <Tooltip title="Refresh" placement="top">
                    <AutorenewIcon
                      fontSize="large"
                      className={spin === 1 ? classes.spin : ""}
                      onClick={refreshCanvas}
                      spin={spin}
                    />
                  </Tooltip>
                </IconButton> */}

                {/* <AddCanvas
                 
                  refreshCanvas={refreshCanvas}
                  parentId={prevId.canvasId}
                  setCanvasData={setCanvasData}
                 
                /> */}
                <div className="export">
                  <FormControl
                    margin="dense"
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel style={{ marginTop: "5px", marginLeft: "5px" }}>
                      <GetAppIcon />
                    </InputLabel>
                    <Select
                      onChange={handleChangeExp}
                      value={value}
                      style={{ margin: "10px", width: "70px" }}
                    >
                      <MenuItem disabled>Select</MenuItem>
                      {/* <MenuItem value={"Text"}>TEXT</MenuItem>
                      <MenuItem value={"Json"}>JSON</MenuItem> */}
                      <MenuItem value={"Csv"}>CSV</MenuItem>
                      <MenuItem value={"Png"}>PNG</MenuItem>
                      <MenuItem value={"Jpeg"}>JPEG</MenuItem>
                      <MenuItem value={"Pdf"}>PDF</MenuItem>
                      <MenuItem value={"Xml"}>XML</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {/* <FormControl
                  margin="dense"
                  variant="outlined"
                  className={classes.formControl}
                >
                  <Select
                    native
                    id="demo-customized-select-native"
                    className={classes.select}
                    onChange={getDataFromSelect}
                    style={{ height:"40px",marginTop:"9px",marginLeft:"-5px" }}
                    value={canvasId}
                  >
                    {canvasData.length > 0 &&
                      canvasData.map((canvas, index) => (
                        canvas.crdParentID === "0"&&(
                        <option key={index} value={canvas.DynamicID}>
                          {canvas.crdName}
                        </option>)
                      ))}
                  </Select>
                </FormControl> */}

                <div>
                  <img src={omnivuelogo} className={classes.omni} alt="logo" />
                </div>
              </div>

              <div className={classes.sectionMobile}>
                <IconButton
                  color="primary"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={mobileMenuId}
            keepMounted
            className={classes.mobilemenu}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
          >
            <MenuItem>
              <IconButton
                className={classes.icons}
                onClick={() => {
                  setOpenForm(true);
                }}
              >
                <Tooltip title="Add Card" placement="top">
                  <AddCircleOutlineIcon fontSize="large" color="primary" />
                </Tooltip>
              </IconButton>
            </MenuItem>
            <MenuItem>
              <IconButton className={classes.icons} color="primary">
                <Tooltip title="Refresh" placement="top">
                  <AutorenewIcon
                    fontSize="large"
                    className={spin === 1 ? classes.spin : ""}
                    onClick={refreshCanvas}
                    spin={spin}
                  />
                </Tooltip>
              </IconButton>
            </MenuItem>
            <MenuItem>
              <AddCanvas
                refreshCanvas={refreshCanvas}
                parentId={prevId.canvasId}
                setCanvasData={setCanvasData}
              />
            </MenuItem>
            <MenuItem>
              <FormControl
                margin="dense"
                variant="outlined"
                className={classes.menuControl}
              >
                <Select
                  native
                  id="demo-customized-select-native"
                  className={classes.select}
                  onChange={getDataFromSelect}
                  style={{ marginLeft: "8px" }}
                  value={canvasId}
                >
                  {canvasData.length > 0 &&
                    canvasData.map((canvas, index) => (
                      <option key={index} value={canvas.DynamicID}>
                        {canvas.crdName}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </MenuItem>
            <MenuItem>
              <div className={classes.export}>
                <FormControl
                  margin="dense"
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel>
                    <GetAppIcon />
                  </InputLabel>
                  <Select
                    onChange={handleChangeExp}
                    value={value}
                    style={{ marginLeft: "-5px", width: "90px" }}
                  >
                    <MenuItem>Select</MenuItem>
                    {/* <MenuItem value={"Text"}>TEXT</MenuItem>
                      <MenuItem value={"Json"}>JSON</MenuItem> */}
                    <MenuItem value={"Csv"}>CSV</MenuItem>
                    <MenuItem value={"Png"}>PNG</MenuItem>
                    <MenuItem value={"Jpeg"}>JPEG</MenuItem>
                    <MenuItem value={"Pdf"}>PDF</MenuItem>
                    <MenuItem value={"Xml"}>XML</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </MenuItem>
          </Menu>
          {/* <div>
                     <img src={omnivuelogo} className={classes.omni} />
                </div> */}

          <Dialog open={open} onClose={handleToClose}>
            <DialogTitle>{"Import Csv"}</DialogTitle>
            <DialogContent>
              <Parsing />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleToClose} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Table to show data */}
          {board.length > 0 ? (
            <CardContext.Provider value={{ markAsDone }}>
              <Grid
                container
                wrap="nowrap"
                spacing={2}
                className={classes.cardView}
              >
                {board &&
                  board.map(
                    (col, index) =>
                      col.Status !== "Withdrawn" && (
                        <Grid item xs={2.1} style={{ padding: "1.9%" }}>
                          {/* Calling CanvasTable Components to show Cards of different lanes in different Tables */}
                          <CanvasTable
                            key={index}
                            col={col}
                            // col="To-Do"
                            getData={getData}
                            refreshCanvas={refreshCanvas}
                            board={board}
                            CanvasId={activeCanvasId}
                            searchs={searchs}
                            filterData={filterData}
                            targetId={`L${index}`}
                            canvasData={canvasData}
                            check={check}
                          />
                        </Grid>
                      )
                  )}
              </Grid>
            </CardContext.Provider>
          ) : (
            <h2 style={{ position: "absolute", top: "45%", left: "35%" }}>
              {" "}
              No Card in the Board{" "}
            </h2>
          )}
        </>
      )}

      <Footer />
      <SuccessSnackbar
        openSuccessAlert={openSuccessAlert}
        setopenSuccessAlert={setopenSuccessAlert}
        message="Card Moved Successfully!"
        horizontal="top"
        vertical="center"
      />
      <ErrorSnackbar
        openErrorAlert={openDragAlert}
        setopenErrorAlert={setopenDragAlert}
        message="Not Allowed!"
        horizontal="top"
        vertical="center"
      />
      <ErrorSnackbar
        openErrorAlert={openErrorAlert}
        setopenErrorAlert={setopenErrorAlert}
        horizontal="top"
        vertical="center"
      />
    </div>
  );
};

export default Canvas;
