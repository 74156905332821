import React, { useEffect, useState } from "react";
import { v1 as uuid } from "uuid";
import { Link } from "react-router-dom";
import Header from "../Header";
import { AppBar, Toolbar } from "@material-ui/core";
import { useStyles } from "../../styles/MainBoardCss";
import $ from "jquery";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import omnivuelogo from "../../../assets/images/omnivuelogo.jpg";
import { withStyles, useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
let projectid = window.localStorage.getItem("PROJECT_ID");

const Account = () => {
  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  const classes = useStyles();
  const theme = useTheme();
  const check_admin = localStorage.getItem("admin");
  const project_name = localStorage.getItem("PROJECT_NAME");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const handleDelete = (e, id) => {
    e.preventDefault();
    fetch(`${baseUrl}/deleteAccount?id=${id}`, { method: "DELETE" })
      .then((res) => res.text())
      .then((res) => {
        console.log(res);
      });
    setTimeout(function () {
      window.location.reload();
    }, 1000);
  };
  const getData = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${baseUrl}/accounts?prjid=${projectid}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setLoading(false);
        setData(result);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getData();
  }, []);

  $(document).ready(function () {
    $("#search").on("keyup", function () {
      var value = $(this).val().toLowerCase();
      $("#dataTable #tr").filter(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
      });
    });
  });
  return (
    <div>
      <Header />
      <AppBar
        onClick={() => window.scroll(0, 0)}
        position="sticky"
        className={classes.appbar}
      >
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Accounts
            <div>
              <p style={{ color: "red", fontSize: 12 }}>{project_name}</p>
            </div>
          </Typography>

          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              id="search"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div>
            <img src={omnivuelogo} className={classes.omni} alt="logo" />
          </div>
        </Toolbar>
      </AppBar>
      <div style={{marginLeft:"16vw", marginTop:"0.5rem"}}>
        <Link
          style={{
            textDecoration: "none",
            color: "black",
            paddingRight:"3rem",
            fontWeight:"bolder",
          }}
          to={{
            pathname: `/create`,
            state: {
              option: 0,
            },
            key: uuid(),
          }}
        >
          + Invoice
        </Link>
        <Link
          style={{
            textDecoration: "none",
            fontWeight:"bolder",
            color: "black",
          }}
          to={{
            pathname: `/create`,
            state: {
              option: 1,
            },
            key: uuid(),
          }}
        >
          + Quotation
        </Link>
      </div>
      <div style={{ marginLeft: "2.5rem", marginTop: "0.5rem" }}>
        {loading ? (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              marginLeft: "-50px",
              marginTop: "-50px",
              padding: theme.spacing(1, 9),
            }}
          >
            <CircularProgress size={100} />
            <h5 style={{ fontSize: 20 }}> Loading..... </h5>
          </div>
        ) : (
          <div style={{ paddingLeft: 65 }}>
            <Grid id="dataTable" container className={classes.root} spacing={2}>
              {data.map((canvas) => (
                <Grow in={true} timeout={1200}>
                  <Grid item xs id="tr">
                    <div className={classes.boardCard1}>
                      {check_admin ? (
                        <>
                          <IconButton aria-label="Delete">
                            <i
                              onClick={(event) => {
                                handleDelete(event, canvas.id);
                              }}
                            >
                              <DeleteIcon />
                            </i>
                          </IconButton>
                        </>
                      ) : (
                        <></>
                      )}
                      <Typography
                        // position= "absolute"
                        // fontweight = "400"
                        align="center"
                        variant="h6"
                        style={{
                          marginTop: "15%",
                          fontWeight: "bold",
                          fontSize: "25px",
                          lineHeight: "36px",
                          fontStyle: "normal",
                          fontFamily: "Microsoft Sans Serif",
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "black",
                          }}
                          to={{
                            pathname: `/view`,
                            state: {
                              data: canvas,
                            },
                            key: uuid(),
                          }}
                        >
                          {canvas.id} <div>{canvas.desc}</div>
                          <div>{canvas.company_name}</div>
                        </Link>
                      </Typography>
                    </div>
                  </Grid>
                </Grow>
              ))}
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
};

export default Account;
