import React, { useState } from "react";
import { useEffect } from "react";
import Header from "../Header";
import bcrypt from "bcryptjs";
import Button from '@mui/material/Button';
import usage from "../../../assets/images/CLI.gif";


const salt = bcrypt.genSaltSync(10);


const Cli = () => {
  const baseUrl = process.env.REACT_APP_HEROKU_URL;
  const sec = process.env.bcrypt_sec;
  const [data, setData] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(0);

  const email = localStorage.getItem("email");

  const handleSubmit = () => {
    const hashedPassword = bcrypt.hashSync(password, sec);

    console.log(hashedPassword);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${baseUrl}/user/signup?email=${email}&password=${hashedPassword}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
      
      window.location.reload();
  };

  useEffect(() => {
    const getData = () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(`${baseUrl}/user?email=${email}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setData(result);
          setLoading(1);
        })
        .catch((error) => console.log("error", error));
    };
    getData();
  }, []);

  return (
    <>
      {loading === 1 ? (
        <div>
          <Header />
          <div style={{
            paddingLeft: 100,
          }}>
            {data.cli === 0 ? (
              <div>
                <h1>CLI REGISTRATION</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <p>To start with cli please visit <a href="https://www.npmjs.com/package/omnevu-cli">this npm package</a></p>

                <h5 style={{ paddingRight: 10 }}>Password:</h5>
                <input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
                <div style={{ paddingTop: 10 }}>
                  {/* <button onClick={handleSubmit}>submit</button> */}
                  <Button variant="contained" onClick={handleSubmit}>Submit</Button>
                </div>
              </div>
            ) : (
              <div >
                <h2>Steps To Follow</h2>
                <img style={{width:"75%"}} src={usage} alt="Uses"></img>

              </div>
            )}
          </div>
        </div>
      ) : (
        <p>Loading</p>
      )}
    </>
  );
};

export default Cli;