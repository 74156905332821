import React, { Component } from "react";

import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const FooterContainer = styled.div`
  text-align: center;
  position: relative;
  bottom:0px;
  width: 100% !important;
  background: #ECF1F1;

`;

class Footer extends Component {
  render() {
    return (
      <FooterContainer>
        <div style={{margin:8}}>
          <Typography variant="title">@copyright 2023</Typography>
        </div>
      </FooterContainer>
    );
  }
}

export default Footer; 