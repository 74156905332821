import { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ResponsiveStream } from '@nivo/stream'
import AllWidget from "./subpartOfDashboard/AllWidgets/AllWidgets";
import Streamgraph from "./subpartOfDashboard/streamchart/Streamgraph";
import PieGraph from "./subpartOfDashboard/pie/PieGraph";
import CycleTimeGraph from "./subpartOfDashboard/cycletime/CycleTimeGraph";
import Tableofdata from "./subpartOfDashboard/Table/Tableofdata";

const RealDashboard = (props) => {

    return (
        <>
            <div>
                <div style={{ margin: '1rem' }}>

                    <AllWidget board={props.selectedboard} />
                </div>


                <div style={{ marginLeft: '2rem' }}>
                    <h4> Project Items status Summary </h4>
                </div>
                <div class="dev" style={{ margin: '1rem', height: '25rem', boxShadow: '1px 2px 9px #36454F', borderRadius: '1rem' }}>
                    <div style={{ background: 'linear-gradient(82.59deg, #ff647c 0%, #0084f4 100%)', height: '0.5rem', borderRadius: '1rem' }}></div>

                    <PieGraph board={props.selectedboard}/>
                </div>


                {/* <div style={{ marginLeft: '2rem' }}>
                    <h4> Last 10 records </h4>
                </div>
                <div class="dev" style={{ margin: '1rem', height: '25rem', boxShadow: '1px 2px 9px #36454F', borderRadius: '1rem' }}>
                    <div style={{ background: 'linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%)', height: '0.5rem', borderRadius: '1rem' }}></div>

                    <Tableofdata board={props.selectedboard} />
                </div> */}
            </div>

            <div style={{ marginLeft: '2rem' }}>
                <h4> LeadTime and CycleTime </h4>
            </div>
            <div class="dev" style={{ margin: '1rem', height: '25rem', boxShadow: '1px 2px 9px #36454F', borderRadius: '1rem' }}>
                <div style={{ background: 'linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%)', height: '0.5rem', borderRadius: '1rem' }}></div>

                <CycleTimeGraph board={props.selectedboard} />
            </div>


            {props.selectedboard == 'All Board' ? (
                <>
                    <div style={{ marginLeft: '2rem' }}>
                        <h4> Flow Diagram</h4>
                    </div>
                    <div class="dev" style={{ margin: '1rem', height: '25rem', boxShadow: '1px 2px 9px #36454F', borderRadius: '1rem' }}>
                        <div style={{ background: 'linear-gradient(82.59deg, #ff647c 0%, #0084f4 100%)', height: '0.5rem', borderRadius: '1rem' }}></div>

                        <Streamgraph board={props.selectedboard} />
                    </div>
                </>
            ) : (
                <></>
            )}







        </>
    )
}
export default RealDashboard