import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Typography from '@material-ui/core/Typography';
import TableNew from "./TableNew";


import { useStyles } from "../../styles/CanvasCss";
import ShowUsers from "./ShowUsers";
import AddUser from "./AddUser";


function Users(props) {
  const { users, setUsers, canvas, getAllUsers } = props;
  const [openForm, setOpenForm] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [disable, setDisable] = React.useState(true);
  const classes = useStyles();
  
  /*const check = (canvasName) => {
    let flag = false;
    users.length > 0 &&
      users.map((user) =>
        user.Canvas.map((canvas) =>
          canvasName === canvas.CanvasName ? (flag = true) : (flag = false)
        )
      );
    return flag;
  };*/

  //console.log("Check", check("Sprint1"));

  return (
    <>
{/* <IconButton
                className={classes.icons}
                onClick={() => {
                  setOpenForm(true);
                }}
              >
                <Tooltip title={"Add User"} placement="top">
                  <AddCircleOutlineIcon fontSize="large" color="primary" />
                </Tooltip>
              </IconButton> */}
              {/* <AddUser
                openForm={openForm}
                setOpenForm={setOpenForm}
                canvas={canvas}
                setUsers={setUsers}
              /> */}

      {/* <tr>

        <TableCell align="center">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          SuperAdmin
        </TableCell>
        <TableCell></TableCell>
      </tr>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: 100 }} colSpan={6} style={{ height: "auto", maxHeight: 560, width: 1250 }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Typography variant="h6" gutterBottom component="div"> */}

              {/* <IconButton
                className={classes.icons}
                onClick={() => {
                  setOpenForm(true);
                }}
              >
                <Tooltip title={"Add" + props.name} placement="top">
                  <AddCircleOutlineIcon fontSize="large" color="primary" />
                </Tooltip>
              </IconButton> */}
              {/* {props.name === "Users" ? (<AddUser
                openForm={openForm}
                setOpenForm={setOpenForm}
                canvas={canvas}
                setUsers={setUsers}
              />) : (<AddAdmin
                openForm={openForm}
                setOpenForm={setOpenForm}
                canvas={canvas}
                setUsers={setUsers}
                users={users}
              />)} */}


            {/* </Typography>

            <Table name="SuperAdmin" style={{ height: "auto", maxHeight: 560, width: 1250 }} />
            <TableContainer component={Paper} className={classes.tableContainer} style={{ height: "auto", maxHeight: 560, width: 1250 }}>
              <Table stickyHeader aria-label="simple table" id="sortTable">
                <TableHead>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Name
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Email
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    Boards
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableHead>
                {props.name === "SuperAdmin" &&
                  users.map((user) =>
                    user.Admin === "FALSE" ?
                      (
                        <TableRow id="tr">
                          <ShowUsers
                            user={user}
                            canvas={canvas}
                            getAllUsers={getAllUsers}
                            setUsers={setUsers}
                          />
                        </TableRow>
                      ) : (""))}
                {props.name === "Admin" &&
                  users.map((user) =>
                    user.Admin === "TRUE" ?
                      (
                        <TableRow id="tr">
                          <ShowUsers
                            user={user}
                            canvas={canvas}
                            getAllUsers={getAllUsers}
                            setUsers={setUsers}
                          />
                        </TableRow>
                      ) : (""))}




              </Table>
            </TableContainer>
          </Box>
        </Collapse>
      </TableCell> */}
      {/* <TableNew localStorage.getItem("Admin") ==="FALSE" ?(disable ? (name="SuperAdmin" users={users} canvas={canvas}):(<TableNew name="Admin" users={users} canvas={canvas} />

<TableNew name="Users" users={users} canvas={canvas} />)  */}
      {/* <TableNew name="SuperAdmin" users={users} canvas={canvas} /> */}
      
                  
      {localStorage.admin === "true" ?
                      (<div>

      <TableNew name="Admin" users={users} canvas={canvas} setUsers={setUsers} />

      <TableNew name="Users" users={users} canvas={canvas} setUsers={setUsers} /></div>):
      (<div><TableNew name="SuperAdmin" users={users} canvas={canvas} setUsers={setUsers}/>
      <TableNew name="Admin" users={users} canvas={canvas} setUsers={setUsers} />

      <TableNew name="Users" users={users} canvas={canvas} setUsers={setUsers} /></div>)}


    </>
  );
}

export default Users;
